// src/helpers/apis/validateOrderLines.ts
import { apiPost } from "./apis/apiHelper";

interface ValidateOrderLineResponse {
    isValid: boolean;
    productId: string;
    productNumberError: string;
}

export const validateOrderLines = async (
    itemNumbers: string[]
): Promise<ValidateOrderLineResponse[]> => {
    const response = await apiPost<ValidateOrderLineResponse[], string[]>(
        "/api/product/VerifyProducts",
        itemNumbers
    );
    return response ?? [];
};
