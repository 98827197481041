import { useTranslations } from "@shared/context/useTranslations";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./StockStatus.module.scss";
import { ProductStock } from "@shared/types/CategoryTypes.interface";

interface StockStatusProps {
    stock: ProductStock;
}

export const StockStatus = ({ stock }: StockStatusProps) => {
    const { getTranslation } = useTranslations();
  
    return (
        <div className={S.stockStatusContainer}>
            {/* LOCAL */}
            {stock.local > 0 ? (
            <div className={S.stockStatusWrapper}>
                <span className={cn(S.stockStatusIcon, S.inLocalStockStatus)}></span>
                <span className={S.stockStatusText}>
                    {`${getTranslation("general.on-stock")} (${stock.local > 999 ? "+999" : stock.local})`} 
                </span>
            </div>
            ) : null}

            {/* REMOTE */}
            {stock.remote > 0 ? (
            <div className={S.stockStatusWrapper}>
                <span className={cn(S.stockStatusIcon, S.inRemoteStockStatus)}></span>
                <span className={S.stockStatusText}>
                    {`${getTranslation("general.on-remote-stock")} (${stock.remote > 999 ? "+999" : stock.remote})`} 
                </span>
            </div>
            ) : null}

            {/* NO STOCK */}
            {stock.remote === 0 && stock.local === 0 ? (
            <div className={S.stockStatusWrapper}>
                <span className={cn(S.stockStatusIcon)}></span>
                <span className={S.stockStatusText}>
                    {getTranslation("general.out-of-stock")}
                </span>
            </div>
            ) : null}
        </div>
    );
};
