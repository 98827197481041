import { HeroList as HeroListType } from "../../../types/ContentTypes";
import getImageUrl from "../../../helpers/getImageUrl.helper";
import { HMF_Image } from "../../Atoms/Image/image.component";
import S from "./HeroList.module.scss";
import { Link } from "react-router-dom";

interface HeroListProps {
    heroLists: HeroListType[];
}

export const HeroList = ({ heroLists }: HeroListProps) => {
    return (
        <div className={S.heroList}>
            {heroLists.map((heroList) => {
                const itemsLength = heroList.content?.properties?.heroList?.items?.length || 0;
                return heroList.content?.properties?.heroList?.items?.map((hero) => (
                    <div
                        key={hero?.content?.id}
                        className={`${S.hero} ${S.heroItem} ${itemsLength > 0 ? S[`numberOfHeroImages-${itemsLength}`] : ""}`}
                    >
                        <div className={S.heroImageWrapper}>
                            {hero?.content?.properties?.image &&
                                hero.content.properties.image.map((image) => (
                                    <div key={image.id} className={S.heroImage}>
                                        <HMF_Image
                                            src={getImageUrl(image.url)}
                                            alt={hero.content.properties.headline}
                                            focalPoint={image.focalPoint}
                                            className={S.heroImage}
                                            loading="eager"
                                        />
                                        <div className={S.imageGradient}></div>
                                    </div>
                                ))}
                        </div>
                        <div className={S.heroContent}>
                            <div className={S.text}>
                                <h3 className={S.heroHeadline}>
                                    {hero?.content?.properties?.headline}
                                </h3>
                                {hero?.content?.properties?.text && (
                                    <div
                                        className={S.heroText}
                                        dangerouslySetInnerHTML={{
                                            __html: hero.content.properties.text.markup,
                                        }}
                                    />
                                )}
                            </div>

                            {hero?.content?.properties?.button &&
                                hero.content.properties.button.items.map((button) => {
                                    const link = button.content.properties.link[0];
                                    let href = "#";
                                    if (link?.linkType === "External") {
                                        href = link.url?.startsWith("http")
                                            ? link.url
                                            : `https://${link.url}`;
                                    } else {
                                        href = link?.route?.path || href;
                                    }

                                    const target = link?.target ?? undefined;

                                    return link?.linkType === "External" ? (
                                        <a
                                            role="button"
                                            key={button.content.id}
                                            href={href}
                                            target={target}
                                            className={S.heroButton}
                                            aria-label={`${button.content.properties.label} ${target} `}
                                        >
                                            {button.content.properties.label}
                                        </a>
                                    ) : (
                                        <Link
                                            role="button"
                                            key={button.content.id}
                                            to={href}
                                            target={target}
                                            className={S.heroButton}
                                            aria-label={`${button.content.properties.label} ${target}`}
                                        >
                                            {button.content.properties.label}
                                        </Link>
                                    );
                                })}
                        </div>
                    </div>
                ));
            })}
        </div>
    );
};
