import { useEffect, useState } from "react";
import { fetchUserAccount } from "@shared/helpers/apis/userAccountHelper";
import { User } from "@shared/types/UserAccountTypes.interface";
import Button from "@shared/components/Atoms/Button/Button";
import { Link } from "react-router-dom";
import InputField from "@shared/components/Atoms/InputField/InputField.component";
import { useTranslations } from "@shared/context/useTranslations";
import S from "./UserAccount.module.scss";
import { cn } from "@shared/helpers/classNames.helper";

const UserAccount = () => {
    const { getTranslation } = useTranslations();
    const [userAccount, setUserAccount] = useState<User | null>(null);
    const [hidePostalCode, setHidePostalCode] = useState<boolean>(false);
    const [showState, setShowState] = useState<boolean>(false);

    //Hide postal code for Hong Kong and United Arab Emirates users
    useEffect(() => {
        if (userAccount) {
            if (
                userAccount.invoiceAddress.countryId === "HKG" ||
                userAccount.invoiceAddress.countryId === "ARE"
            ) {
                setHidePostalCode(true);
            }
            if (userAccount.invoiceAddress.stateName) {
                setShowState(true);
            }
        }
    }, [userAccount]);

    useEffect(() => {
        const fetchUserAccountData = async () => {
            const userAccountData = await fetchUserAccount();
            setUserAccount(userAccountData);
        };
        fetchUserAccountData();
    }, []);

    if (!userAccount) {
        return <h1>{getTranslation("userAccount.noData")}</h1>;
    }

    return (
        <div className={S.userAccountWrapper}>
            <div className={S.userAccount}>
                <h1>{getTranslation("userAccount.heading")}</h1>

                <div
                    className={cn(
                        S.inputFields,
                        hidePostalCode && S.hidePostalCode,
                        showState && S.showState
                    )}
                >
                    {userAccount.email && (
                        <InputField
                            className={S.inputField1}
                            disabled
                            value={userAccount?.email}
                            label={getTranslation("userAccount.email")}
                        />
                    )}
                    {userAccount.vatNumber && (
                        <InputField
                            className={S.inputField2}
                            disabled
                            value={userAccount?.vatNumber}
                            label={getTranslation("userAccount.vatNumber")}
                        />
                    )}
                    {userAccount.name && (
                        <InputField
                            className={S.inputField3}
                            disabled
                            value={userAccount?.name}
                            label={getTranslation("userAccount.companyName")}
                        />
                    )}
                    {userAccount.phone && (
                        <InputField
                            className={S.inputField4}
                            disabled
                            value={userAccount?.phone}
                            label={getTranslation("userAccount.phone")}
                        />
                    )}
                    {userAccount.invoiceAddress.streetName && (
                        <InputField
                            className={S.inputField5}
                            disabled
                            value={userAccount?.invoiceAddress.streetName}
                            label={getTranslation("userAccount.streetName")}
                        />
                    )}
                    {!hidePostalCode && (
                        <InputField
                            className={S.inputField6}
                            disabled
                            value={userAccount?.invoiceAddress.postalCode}
                            label={getTranslation("userAccount.postalCode")}
                        />
                    )}
                    {userAccount.invoiceAddress.cityName && (
                        <InputField
                            className={S.inputField7}
                            disabled
                            value={userAccount?.invoiceAddress.cityName}
                            label={getTranslation("userAccount.city")}
                        />
                    )}
                    {showState && (
                        <InputField
                            className={S.inputField10}
                            disabled
                            value={userAccount?.invoiceAddress.stateName}
                            label={getTranslation("userAccount.state")}
                        />
                    )}
                    {userAccount?.invoiceAddress.countryId && (
                        <InputField
                            className={S.inputField9}
                            disabled
                            value={userAccount?.invoiceAddress.countryId}
                            label={getTranslation("userAccount.country")}
                        />
                    )}
                </div>
                <>
                    <i>
                        {getTranslation("userAccount.information")}
                        <a
                            className={S.mail}
                            href={`mailto:${getTranslation("userAccount.contactMail")}`}
                        >
                            {getTranslation("userAccount.contactMail")}
                        </a>
                    </i>
                </>
            </div>
            <div className={S.btns}>
                <div className={S.btn}>
                    <h2>{getTranslation("userAccount.userInfo")}</h2>
                    <Link
                        aria-label={getTranslation("general.ariaLabel.newWindow")}
                        target="blank"
                        to="https://dk.hmfcranes.com/sign-in-1?LoginAction=Recovery"
                    >
                        <Button iconName="Externallink" iconPlacement="left" disabled round>
                            {getTranslation("userAccount.changeUserInfo")}
                        </Button>
                    </Link>
                </div>
                <div className={S.btn}>
                    <h2>{getTranslation("userAccount.changePassword")}</h2>
                    <Link
                        aria-label={getTranslation("general.ariaLabel.newWindow")}
                        target="blank"
                        to="https://dk.hmfcranes.com/sign-in-1?LoginAction=Recovery"
                    >
                        <Button iconName="Externallink" iconPlacement="left" round>
                            {getTranslation("userAccount.changeYourPassword")}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default UserAccount;
