// src/context/SearchContext.tsx

import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { mapToCategoryProductList, searchProducts } from "@shared/helpers/apis/searchHelper";
import { SearchApiRequest, SearchApiResponse } from "@shared/types/SearchTypes.interface";
import { useQuery } from "@tanstack/react-query";
import { CategoryProductList } from "@shared/types/CategoryTypes.interface";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";

interface SearchContextProps {
    isLoading?: boolean;
    error?: Error | null;
    query?: string;
    setQuery: (query: string) => void;
    categoryId?: number;
    setCategoryId: (categoryId: number | undefined) => void;
    productId?: string;
    setProductId: (productId: string | undefined) => void;
    stockStatus: boolean | null;
    setStockStatus: (stockStatus: boolean | null) => void;
    products?: CategoryProductList;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    pageSize: number;
    setPageSize: (size: number) => void;
}

interface SearchProviderProps {
    children: React.ReactNode;
}

const SearchContext = createContext<SearchContextProps>({
    isLoading: false,
    error: null,
    query: undefined,
    setQuery: () => {},
    categoryId: undefined,
    productId: undefined,
    setCategoryId: () => {},
    setProductId: () => {},
    stockStatus: null,
    setStockStatus: () => null,
    products: undefined,
    pageNumber: 1,
    setPageNumber: () => {},
    pageSize: 50,
    setPageSize: () => {},
});

export const SearchProvider = ({ children }: SearchProviderProps) => {
    const { currentCulture } = useCurrentCulture();
    const [searchQuery, setSearchQuery] = useState<string | undefined>("");
    const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
    const [productId, setProductId] = useState<string | undefined>(undefined);
    const [stockStatus, setStockStatus] = useState<boolean | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [products, setProducts] = useState<CategoryProductList | undefined>(undefined);
    const defaultPageSize = (window as any).defaultPagingSize || 50;
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);

    const request = useMemo<SearchApiRequest>(() => {
        return {
            productIdOrName: searchQuery,
            productId: productId ? productId.toString() : "",
            categoryId: categoryId ? categoryId.toString() : "",
            pageNumber,
            pageSize,
            culture: currentCulture,
            inStock: stockStatus,
        };
    }, [categoryId, currentCulture, pageNumber, pageSize, productId, searchQuery, stockStatus]);

    useEffect(() => {
        // Reset pageNumber when filtering occurs
        setPageNumber(1);

        // Clear products on empty request/reset
        if (!searchQuery && !categoryId) {
            setProducts(undefined);
        }
    }, [productId, stockStatus, searchQuery, categoryId]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pageNumber]);

    const {
        data: apiResponse,
        isLoading,
        error,
    } = useQuery<SearchApiResponse>({
        queryKey: ["search", request],
        queryFn: () => searchProducts(request),
        enabled: !!searchQuery || !!categoryId,
    });

    useEffect(() => {
        if (apiResponse && !isLoading) {
            const mappedByFunction = mapToCategoryProductList(apiResponse);
            setProducts(mappedByFunction);
        }
    }, [apiResponse, isLoading]);

    return (
        <SearchContext.Provider
            value={{
                isLoading,
                error,
                query: searchQuery,
                setQuery: setSearchQuery,
                categoryId,
                setCategoryId,
                productId,
                setProductId,
                stockStatus,
                setStockStatus,
                products: products,
                pageNumber,
                setPageNumber,
                pageSize,
                setPageSize,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = () => useContext(SearchContext);
