import { CategoryProduct } from "@shared/types/CategoryTypes.interface";
import { apiPost } from "./apiHelper";
import { ImageSizes } from "@shared/enums/imageSizes.enums";

export interface ProductResponse {
  items: CategoryProduct[];
}

export interface ProductValidationRequest {
  productId: string[];
}
export interface ProductValidationResponse {
  isValid: boolean;
  productId: string;
  productNumberError: string;
}

export interface ProductFilter {
  productId: string;
}

export interface ProductQuery {
  filter: ProductFilter;
}

export const fetchCategoryProduct = async (
  productId: string,
  culture: string,
  pageNumber?: number,
  pageSize?: number
): Promise<ProductResponse> => {
  const response = await apiPost<ProductResponse, ProductQuery>(
    `/api/product/GetProducts?culture=${culture}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      filter: {
        productId: productId,
      },
    }
  );

  if (!response) {
    throw new Error("Failed to fetch product");
  }

  return response;
};

export const validateProductId = async (
  productId: string[]
): Promise<ProductValidationResponse> => {
  const response = await apiPost<ProductValidationResponse, string[]>(
    `/api/product/VerifyProducts`,
    productId
  );

  if (!response) {
    throw new Error("Failed to validate product");
  }

  return response;
};

export const getThumbnailImageUrl = (imageUrl: string) => {
  if (imageUrl) {
      const paramDivider = imageUrl.includes("?") ? "&" : "?";
      return `${imageUrl.replace("format=png", "format=webp")}${paramDivider}size=${ImageSizes.ProductThumbnail}&quality=70`;
  }
  return imageUrl;
}

export const getMainImageUrl = (imageUrl: string) => {
  if (imageUrl) {
      const paramDivider = imageUrl.includes("?") ? "&" : "?";
      return `${imageUrl.replace("format=png", "format=webp")}${paramDivider}size=${ImageSizes.ProductMain}&quality=80`;
  }
  return imageUrl;
}