import apiClient from "./apiClient";
import axios, { AxiosError } from "axios";

const isAxiosError = (error: unknown): error is AxiosError => {
    return axios.isAxiosError(error);
};

export const favoritesApiGet = async <T>(
    url: string,
    options?: { signal?: AbortSignal }
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.get<T>(url, { signal: options?.signal });
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};

export const favoritesApiPost = async <T, R = undefined>(
    url: string,
    body?: R,
    options?: { signal?: AbortSignal }
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.post<T>(url, body, { signal: options?.signal });
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};

export const favoritesApiPatch = async <T>(
    url: string,
    body: unknown
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.patch<T>(url, body);
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};

export const favoritesApiDelete = async <T, R = undefined>(
    url: string,
    body?: R,
    options?: { signal?: AbortSignal }
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.request<T>({
            method: "delete",
            url,
            data: body,
            signal: options?.signal,
        });
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};
