// src/components/NavigationList/NavigationList.component.tsx
import { SiteNavigationItem } from "@shared/types/ContentTypes";
import S from "./NavigationList.module.scss";
import { useState } from "react";
import { Collapsible } from "@shared/components/Molecules/Collapsible/Collapsible.component";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { cn } from "@shared/helpers/classNames.helper";

interface NavigationListProps {
    culture: string;
    items: SiteNavigationItem[];
    navigationLevel?: number;
    openPaths: string[];
    onCloseSidebar: () => void;
}

export const NavigationList: React.FC<NavigationListProps> = ({
    culture,
    items,
    navigationLevel,
    openPaths,
    onCloseSidebar,
}: NavigationListProps) => {
    const [level] = useState<number>(navigationLevel || 1);
    const location = useLocation();

    const isActive = (path: string) => location.pathname.includes(path);

    return (
        <div className={S.navListWrapper}>
            <div className={S.navList} data-level={level}>
                {items &&
                    items.length > 0 &&
                    items.map((item, index) => {
                        const isOpen = openPaths?.includes(item.name);
                        const activeClass = isActive(item.url.urlPath) ? S.active : "";

                        return item?.items && item.items.length > 0 ? (
                            <Collapsible
                                key={index}
                                className={S.navListItem}
                                openClassName={S.activeItem}
                                title={item.name}
                                titleClassName={cn(S[`heading${level}`], activeClass)}
                                contentClassName={S.navListemWrapper}
                                open={isOpen}
                            >
                                <NavigationList
                                    culture={culture}
                                    items={item.items}
                                    navigationLevel={level + 1}
                                    openPaths={openPaths}
                                    onCloseSidebar={onCloseSidebar}
                                />
                            </Collapsible>
                        ) : (
                            <Link
                                onClick={onCloseSidebar}
                                key={index}
                                to={item.url.urlPath}
                                className={cn(S[`heading${level}`], activeClass)}
                            >
                                {item.name}
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};
