import React, { useState } from "react";
import { Container } from "@shared/components/Atoms/Container/Container.component";
import Button from "@shared/components/Atoms/Button/Button";
import { useValidateOrderLines } from "@shared/hooks/useValidateOrderLines";
import { Spinner } from "@shared/components/Atoms/Spinner/Spinner.component";
import { useBasket } from "@shared/context/BasketContext";
import { useToast } from "@shared/context/ToastContext";
import { Link } from "react-router-dom";
import { useTranslations } from "@shared/context/useTranslations";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import S from "./quickOrder.module.scss";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";

interface OrderLine {
    itemNumber: string;
    quantity: number | string;
    isValid: boolean;
    itemNumberError?: string;
    quantityError?: string;
}

const QuickOrder = () => {
    const [orderLines, setOrderLines] = useState<OrderLine[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [errorCount, setErrorCount] = useState(0);
    const [showOrderLines, setShowOrderLines] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { addMultipleToBasket } = useBasket();
    const { showToast } = useToast();
    const { getTranslation } = useTranslations();

    // Path for basket
    const basketPath = getRoutePathByPageType("Basket") || "/basket";

    // Magic inputs
    const ITEM_NUMBER_ERROR = getTranslation("quickOrder.invalidItemNumberFormat");
    const ITEM_NUMBER_QUANTITY = getTranslation("quickOrder.invalidQuantityFormat");
    const ERROR_MESSAGE = getTranslation("quickOrder.correctErrorsBeforeSubmitting");
    const ITEM_NUMBER_NOT_FOUND = getTranslation("quickorder.productnotfound");

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    // Validate by API
    const { mutate: validateOrderLines } = useValidateOrderLines({
        onMutate: () => {
            setIsLoading(true);
        },
        onSuccess: (data) => {
            const newOrderLines = orderLines.map((line) => {
                const apiResult = data.find((item) => item.productId === line.itemNumber);
                if (apiResult) {
                    return {
                        ...line,
                        isValid: apiResult.isValid,
                        itemNumberError: apiResult.isValid
                            ? ""
                            : apiResult.productNumberError === "Product not found"
                              ? ITEM_NUMBER_NOT_FOUND
                              : line.itemNumberError || apiResult.productNumberError,
                    };
                }
                return line;
            });
            setOrderLines(newOrderLines);
            updateErrorCount(newOrderLines);
            setIsChecking(false);
            setIsSubmitDisabled(true);
            setTimeout(() => {
                setIsSubmitDisabled(false);
            }, 1000);
            setShowOrderLines(true); // Move this line here to show the order lines after validation
        },
        onSettled: () => {
            setIsLoading(false); // Reset loading state after mutation
        },
        onError: () => {
            console.error("Failed to validate products");
        },
    });

    const updateErrorCount = (lines: OrderLine[]) => {
        const totalErrors = lines.reduce((acc, line) => {
            return acc + (line.itemNumberError || line.quantityError ? 1 : 0);
        }, 0);
        setErrorCount(totalErrors);
    };

    const handleCheck = () => {
        const validatedOrderLines = orderLines.map((line) => {
            const { isValid, itemNumberError, quantityError } = validateInput(
                line.itemNumber,
                line.quantity.toString()
            );
            return { ...line, isValid, itemNumberError, quantityError };
        });
        setOrderLines(validatedOrderLines);
        updateErrorCount(validatedOrderLines);
        validateOrderLines(validatedOrderLines.map((line) => line.itemNumber));
    };

    const handleCheckFirst = () => {
        const processedLines = processInput(inputValue);
        setOrderLines(processedLines);
        updateErrorCount(processedLines);
        validateOrderLines(processedLines.map((line) => line.itemNumber));
        setShowOrderLines(true); // Show order lines after initial check
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            const newText = inputValue + (inputValue ? "\n" : "") + text;
            setInputValue(newText);
            processInput(newText);
        } catch (err) {
            console.error("Failed to read clipboard contents: ", err);
        }
    };

    const processInput = (input: string): OrderLine[] => {
        //clean input from carriage returns
        const cleanedInput = input.replaceAll("\r", "");
        const lines = cleanedInput.split("\n").filter((line: string) => line.trim() !== "");

        return lines.map((line: string) => {
            // Use a regular expression to split only on the last whitespace in the string
            const parts = line.match(/^(.*\S)\s+(\S+)$/);
            if (parts && parts.length === 3) {
                const itemNumber = parts[1].trim(); // Trim whitespace from itemNumber
                const quantity = parts[2].trim(); // Trim whitespace from quantity
                const { isValid, itemNumberError, quantityError } = validateInput(
                    itemNumber,
                    quantity
                );
                return { itemNumber, quantity, isValid, itemNumberError, quantityError };
            }
            return {
                itemNumber: line,
                quantity: "",
                isValid: false,
                itemNumberError: ITEM_NUMBER_ERROR,
                quantityError: ITEM_NUMBER_QUANTITY,
            };
        });
    };

    const validateInput = (itemNumber: string, quantity: string) => {
        const itemNumberValid = itemNumber.match(/^[a-zA-Z0-9 ]+$/); // Allow spaces in itemNumber
        const quantityValid = quantity.match(/^\d+$/) && parseInt(quantity, 10) > 0;
        const itemNumberError = itemNumberValid ? "" : ITEM_NUMBER_ERROR;
        const quantityError = quantityValid ? "" : ITEM_NUMBER_QUANTITY;
        return { isValid: !!itemNumberValid && !!quantityValid, itemNumberError, quantityError };
    };

    const handleDelete = (index: number) => {
        const newOrderLines = [...orderLines];
        newOrderLines.splice(index, 1);
        setOrderLines(newOrderLines);
        updateErrorCount(newOrderLines);
    };

    const handleOrderLineChange = (
        index: number,
        field: "itemNumber" | "quantity",
        value: string
    ) => {
        const newOrderLines = [...orderLines];
        newOrderLines[index][field] = value;
        setOrderLines(newOrderLines);
        setIsChecking(true);
    };

    const handleSubmit = async () => {
        const invalidItems = orderLines.filter((line) => !line.isValid);
        if (invalidItems.length > 0) {
            setErrorMessage(ERROR_MESSAGE);
        } else {
            setErrorMessage("");
            try {
                await addMultipleToBasket(
                    orderLines.map((line) => ({
                        productId: line.itemNumber,
                        quantity: Number(line.quantity),
                    }))
                );
                const totalQuantity = orderLines.reduce(
                    (acc, line) => acc + Number(line.quantity),
                    0
                );
                showToast(
                    <>
                        {totalQuantity}{" "}
                        {totalQuantity > 1
                            ? `${getTranslation("general.toastMessage.items")}`
                            : `${getTranslation("general.toastMessage.item")}`}{" "}
                        {getTranslation("general.toastMessage.addedTo")}{" "}
                        <Link to={basketPath}>
                            {getTranslation("general.toastMessage.yourBasket")}
                        </Link>
                    </>
                );
                setShowSuccessMessage(true);
                setTimeout(() => {
                    resetForm();
                    setShowSuccessMessage(false);
                }, 3000);
            } catch (error) {
                console.error("Failed to add items to the basket:", error);
            }
        }
    };

    const handleBack = () => {
        setShowOrderLines(false);
    };

    const resetForm = () => {
        setOrderLines([]);
        setInputValue("");
        setErrorCount(0);
        setShowOrderLines(false);
        setIsChecking(false);
        setErrorMessage("");
    };

    const totalQuantity = orderLines.reduce((acc, line) => acc + Number(line.quantity), 0);

    return (
        <Container>
            <div className={S.wrapper}>
                <h1 className={S.title}>{getTranslation("quickOrder.headline")}</h1>
                <div className={S.infoText}>
                    <span className={S.bold}>{getTranslation("QuickOrder.howToDo")}</span>
                    <span>{getTranslation("QuickOrder.howToDoBullet1")}</span>
                    <span>{getTranslation("QuickOrder.howToDoBullet2")}</span>
                    <span>{getTranslation("QuickOrder.howToDoBullet3")}</span>
                </div>
                {showSuccessMessage ? (
                    <div className={S.successMessage}>
                        {`${totalQuantity} ${getTranslation("general.toastMessage.addedToBasket")}`}
                    </div>
                ) : !showOrderLines ? (
                    <>
                        <div className={S.quickOrderTextAreaWrapper}>
                            <label htmlFor="quickOrderTextArea">
                                {getTranslation("QuickOrder.textAreaLabel")}
                            </label>
                            <textarea
                                className={S.textarea}
                                id="quickOrderTextArea"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder={getTranslation("QuickOrder.textAreaPlaceholderText")}
                                rows={10}
                                cols={50}
                            />
                        </div>
                        <div className={S.buttonWrapper}>
                            <Button
                                type="button"
                                size="medium"
                                round
                                onClick={inputValue ? handleCheckFirst : undefined}
                                disabled={!inputValue}
                                tooltipContent={getTranslation(
                                    "QuickOrder.toolTipMustFillTextAreaFirst"
                                )}
                                tooltipId="checkfirst-tooltip"
                            >
                                {getTranslation("QuickOrder.checkItemsBtn")}
                            </Button>
                            <Button
                                type="button"
                                variant="transparent"
                                size="medium"
                                round
                                onClick={handlePaste}
                                iconName={IconNames.Clipboard}
                            >
                                {getTranslation("QuickOrder.insertFromClipboardBtn")}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className={S.orderLines}>
                        {isLoading && (
                            <div className={S.loading}>
                                <div className={S.orderLinesHeader}>
                                    <Spinner className={S.loadingIcon} />
                                </div>
                            </div>
                        )}
                        {orderLines.length > 0 && !isLoading && (
                            <div>
                                <div className={S.orderLinesHeader}>
                                    <span className={S.orderLinesHeaderHeadline}>
                                        {`${orderLines.length} ${getTranslation("quickOrder.linesLoaded")}`}
                                    </span>
                                    <span
                                        className={`${S.orderLinesHeaderInfo} ${
                                            errorCount > 0 ? S.errorText : S.successText
                                        }`}
                                    >
                                        {errorCount > 0
                                            ? `${errorCount} ${getTranslation("quickorder.errorsfound")}`
                                            : getTranslation("quickOrder.noErrorsFound")}
                                    </span>
                                </div>

                                <div className={S.linesHeadline}>
                                    <span>{getTranslation("quickOrder.itemNumber")}</span>
                                    <span>{getTranslation("quickOrder.amount")}</span>
                                </div>
                                {orderLines.map((line, index) => (
                                    <div
                                        key={index}
                                        className={`${S.orderLine} ${
                                            line.isValid ? "" : S.invalid
                                        }`}
                                    >
                                        <div className={S.inputWrapper}>
                                            <input
                                                type="text"
                                                value={line.itemNumber}
                                                onChange={(e) =>
                                                    handleOrderLineChange(
                                                        index,
                                                        "itemNumber",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {line.itemNumberError && (
                                                <div className={S.errorText}>
                                                    {line.itemNumberError}
                                                </div>
                                            )}
                                        </div>

                                        <div className={S.inputWrapper}>
                                            <input
                                                type="text"
                                                value={line.quantity}
                                                onChange={(e) =>
                                                    handleOrderLineChange(
                                                        index,
                                                        "quantity",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {line.quantityError && (
                                                <div className={S.errorText}>
                                                    {line.quantityError}
                                                </div>
                                            )}
                                        </div>

                                        <button
                                            onClick={() => handleDelete(index)}
                                            className={
                                                (line.quantityError || line.itemNumberError) &&
                                                S.deleteIcon
                                            }
                                        >
                                            <SvgIcon iconName={IconNames.Trashcan} />
                                        </button>
                                    </div>
                                ))}
                                <div className={S.buttonWrapper}>
                                    <Button
                                        variant="transparent"
                                        round
                                        size="medium"
                                        onClick={handleBack}
                                    >
                                        {getTranslation("quickOrder.backBtn")}
                                    </Button>
                                    {isChecking ? (
                                        <Button
                                            type="button"
                                            size="medium"
                                            round
                                            onClick={handleCheck}
                                        >
                                            {getTranslation("quickOrder.checkItemsBtn")}
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="basket"
                                                round
                                                size="medium"
                                                className={S.button}
                                                onClick={
                                                    !isSubmitDisabled && errorCount === 0
                                                        ? handleSubmit
                                                        : undefined
                                                }
                                                disabled={isSubmitDisabled || errorCount !== 0} // Disable the submit button
                                                tooltipContent={getTranslation(
                                                    "quickOrder.tooltipCorrectErrors"
                                                )}
                                                tooltipId="submit-tooltip"
                                            >
                                                {getTranslation("quickOrder.addToBasketBtn")}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {errorMessage && <div className={S.errorMessage}>{errorMessage}</div>}
            </div>
        </Container>
    );
};

export default QuickOrder;
