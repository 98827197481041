import { useState, useCallback } from "react";
import { Product } from "@shared/types/ProductTypes.interface";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import Button from "@shared/components/Atoms/Button/Button";
import QuantityPicker from "@shared/components/Molecules/QuantityPicker/QuantityPicker.component";
import { useBasket } from "@shared/context/BasketContext";
import { useToast } from "@shared/context/ToastContext";
import { useTranslations } from "@shared/context/useTranslations";
import { Link } from "react-router-dom";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import S from "./AddToCart.module.scss";
import { cn } from "@shared/helpers/classNames.helper";

interface AddToCartProps {
    product: Product;
    onQuantityChange: (quantity: number) => void;
    className?: string;
}

export const AddToCart = ({ product, onQuantityChange, className }: AddToCartProps) => {
    const { addToBasket } = useBasket();
    const [quantity, setQuantity] = useState(1);
    const { showToast } = useToast();
    const { getTranslation } = useTranslations();

    // Path for basket
    const basketPath = getRoutePathByPageType("Basket") || "/basket";

    const handleAddToCart = useCallback(() => {
        addToBasket({
            productId: product.id.toString(),
            quantity,
            data: { url: product.images[0]?.url },
        });
        showToast(
            <>
                {quantity} {getTranslation("general.piece")} {product.name}{" "}
                {getTranslation("general.toastMessage.addedTo")}{" "}
                <Link to={basketPath}>{getTranslation("general.toastMessage.yourBasket")}</Link>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.id, quantity, product.images, addToBasket]);

    const handleQuantityChange = (newQuantity: number) => {
        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
    };

    return (
        <div className={cn(S.addToCartWrapper, className)}>
            <QuantityPicker quantity={quantity} setQuantity={handleQuantityChange} />
            <Button
                className={S.addToBasketBtn}
                variant="primary"
                onClick={handleAddToCart}
                full={true}
            >
                {getTranslation("general.buyBtn")}

                <SvgIcon iconName={IconNames.Basket} />
            </Button>
        </div>
    );
};

export default AddToCart;
