import { createContext, ReactNode, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isLocalhost } from "../config/config";

interface AuthContextType {
    isLoggedIn: boolean;
    login: () => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!sessionStorage.getItem("loggedIn"));
    const navigate = useNavigate();

    const login = () => {
        sessionStorage.setItem("loggedIn", "true");
        setIsLoggedIn(true);
    };

    const logout = () => {
        sessionStorage.removeItem("loggedIn");
        setIsLoggedIn(false);
        if (isLocalhost) {
            navigate("/login");
        } else window.location.href = "/logout";
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const sessionExists = !!sessionStorage.getItem("loggedIn");
            if (!sessionExists) {
                setIsLoggedIn(false);
                isLocalhost && navigate("/login");
            } else {
                setIsLoggedIn(true);
            }
        };

        window.addEventListener("storage", handleStorageChange);
        handleStorageChange(); // Check initially when the component mounts

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [navigate]);

    useEffect(() => {
        const sessionExists = !!sessionStorage.getItem("loggedIn");
        if (!sessionExists) {
            setIsLoggedIn(false);
            isLocalhost && navigate("/login");
        } else {
            setIsLoggedIn(true);
        }
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
