import { ReceiptPageProps } from "@shared/types/ReceiptType.interface";
import { useTranslations } from "@shared/context/useTranslations";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import S from "./Receipt.module.scss";

export const Receipt = ({ props }: ReceiptPageProps) => {
    const { getTranslation } = useTranslations();

    // @ts-expect-error
    const customer = globalThis?.customer;

    return (
        <div className={S.receipt}>
            <div className={S.titleWrapper}>
                <h1>{getTranslation("receipt.thankYou")}</h1>
                <SvgIcon iconName={IconNames.Checkmark} className={S.titleCheckmark} />
            </div>
            <div className={S.messageWrapper}>
                <span
                    className={S.received}
                >{`${getTranslation("receipt.orderReceivedText")}: ${customer?.customerEmail || ""}`}</span>
                <div
                    className={S.markup}
                    dangerouslySetInnerHTML={{ __html: props?.additionalText?.markup }}
                />
            </div>
        </div>
    );
};
