import React from "react";
import Button from "@shared/components/Atoms/Button/Button";
import { BreadcrumbItem } from "@shared/types/ContentTypes";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { Link } from "react-router-dom";
import S from "./Breadcrumbs.module.scss";
import { useTranslations } from "@shared/context/useTranslations";

interface BreadcrumbsProps {
    breadcrumbs: BreadcrumbItem[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
    const { getTranslation } = useTranslations();
    return (
        <div className={S.breadcrumbs}>
            <div className={S.backBtnColumn}>
                <Button
                    size="small"
                    variant="transparent"
                    className={S.backBtn}
                    onClick={() => history.back()}
                >
                    {getTranslation("button.return")}

                    <SvgIcon iconName={IconNames.Backicon} />
                </Button>
            </div>

            {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                    {index === breadcrumbs.length - 1 ? (
                        <span>{crumb.name}</span>
                    ) : (
                        <Link to={crumb.url.urlPath}>{crumb.name}</Link>
                    )}

                    {index < breadcrumbs.length - 1 && (
                        <div className={S.arrowIcon}>
                            <SvgIcon iconName={IconNames.Smallarrowright} />
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
