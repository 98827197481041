import React, { CSSProperties } from "react";
import { cn } from "../../../helpers/classNames.helper";
import S from "./flag.module.scss";
import { FlagNames } from "../../../enums/flag.enums";

export interface FlagProps {
    name: FlagNames;
    className?: string;
    style?: CSSProperties;
    useSvgDimensions?: boolean;
}

const ReturnIcon = React.lazy(() => import("./ActualFlag.component"));

export const Flag = ({ name, className, style, useSvgDimensions }: FlagProps) => {
    return (
        <div
            className={cn(S.wrapper, useSvgDimensions || S.fullSize, className)}
            style={style}
            role="none"
        >
            <ReturnIcon name={name} />
        </div>
    );
};
