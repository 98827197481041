import { useTranslations } from "@shared/context/useTranslations";
import S from "./StockStatusAttention.module.scss";

export const StockStatusAttention = () => {
    const { getTranslation } = useTranslations();

    return (
        <div className={S.stockStatusBox}>
            <span className={S.stockStatusNote}>
                {getTranslation("basket.out-of-stock-attention.note")}&nbsp;
            </span>
            <span>{getTranslation("basket.out-of-stock-attention.attention-text")}</span>
        </div>
    );
};
