import { ProductStock } from "@shared/types/CategoryTypes.interface";
import { apiGet, apiPost, apiPatch, apiDelete } from "./apiHelper";

export interface BasketItem {
    id: string;
    productId: string;
    productNumber: string;
    quantity: number;
    name: string;
    description: string;
    images: { id: string; url: string; width: number; height: number }[];
    stock: ProductStock;
    data: { url: string; Unit: string };
    url: string;
}

export interface Basket {
    id: string;
    channelId: string;
    customerNumber: string;
    secret: string;
    lines: BasketItem[];
    basketStatus: string;
    createdDate: string;
    updatedDate: string;
    culture: string;
}

export const fetchBasket = async (customerNumber: string, culture: string): Promise<Basket> => {
    const url = `/api/Basket/customer/${customerNumber}?culture=${culture}`;
    const response = await apiGet<Basket>(url);
    return response.data;
};

export const addToBasket = async (
    customerNumber: string,
    product: { productId: string; quantity: number; data: { url: string } }
): Promise<Basket> => {
    const url = `/api/Basket/customers/${customerNumber}/lines`;
    return apiPost<Basket, typeof product>(url, product);
};

export const addMultipleToBasket = async (
    customerNumber: string,
    products: { productId: string; quantity: number }[]
): Promise<Basket> => {
    const url = `/api/Basket/customers/${customerNumber}/lines/multiple`;
    return apiPost<Basket, typeof products>(url, products);
};

// New function to update quantity
export const updateBasketItemQuantity = async (
    customerNumber: string,
    lineId: string,
    basketId: string,
    quantity: number
) => {
    const url = `/api/Basket/customers/${customerNumber}/lines/${lineId}/updateQuantity?basketId=${basketId}`;
    return apiPatch<{ data: BasketItem; statusCode: number }>(url, quantity);
};

// New function to delete an item
export const removeBasketItem = async (customerNumber: string, lineId: string) => {
    const url = `/api/Basket/customers/${customerNumber}/lines/${lineId}`;
    return apiDelete<{ data: Basket; statusCode: number }>(url);
};
