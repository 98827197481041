import React from "react";
import { NavigationList } from "@shared/components/Templates/NavigationList/NavigationList.component";
import { usePage } from "@shared/context/PageContext";

interface NavigationProps {
    openPaths: string[];
    isOpen: boolean;
    onClose: () => void;
}

export const Navigation: React.FC<NavigationProps> = ({ openPaths, onClose }) => {
    const { navigation } = usePage();

    //TODO: Get this from currentCulture object
    const currentCulture = "en-us";

    return navigation?.items ? (
        <NavigationList
            culture={currentCulture}
            items={navigation.items}
            openPaths={openPaths}
            onCloseSidebar={onClose}
        />
    ) : null;
};
