import { fetchOrders } from "@shared/helpers/apis/apiOrder";
import { Fragment, useEffect, useState } from "react";
import { Order } from "@shared/types/OrderTypes.interface";
import S from "./OrderHistory.module.scss";
import { cn } from "@shared/helpers/classNames.helper";
import { useTranslations } from "@shared/context/useTranslations";
import Button from "@shared/components/Atoms/Button/Button";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";
import ImageWithFallback from "@shared/components/Molecules/ImageWithFallback/ImageWithFallback.component";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";


export const OrderHistory = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(0);

    const { getTranslation } = useTranslations();

    useEffect(() => {
        const fetchOrdersData = async () => {
            setOrders(await fetchOrders(selectedYear));
        };
        fetchOrdersData();
    }, [selectedYear]);

    const toggleRow = (orderId: string) => {
        // allow multiple open orders
        // const updated = expanded.includes(orderId) ? expanded.filter(item => item !== orderId) : [...expanded, orderId];

        // allow only one open order
        const updated = expanded.includes(orderId) ? [] : [orderId];
        setExpanded(updated);
    }

    const handleYearChange = (year: number) => {
        setSelectedYear(year);
    }

    const yearOptions: number[] = [0];
    const currentYear = new Date().getFullYear();
    for(let i = 5; i>=0; i--) {
        yearOptions.push(currentYear - i);
    }

    const {currentCulture} = useCurrentCulture();

    return (
        <div className={S.orderHistoryWrapper}>
            <div className={S.orderHistoryHeader}>
                <h1>{getTranslation("orderHistory.title")}</h1>
                <div className={S.selectYear}>
                    <div className={S.select}>
                        <select
                            id="pageSize"
                            value={selectedYear}
                            onChange={(e) => handleYearChange(Number(e.target.value))}
                        >
                            {yearOptions.map((year, index) => (
                                <option value={year} key={index}>{year === 0 ? getTranslation("orderHistory.selectAll") : year}</option>    
                            ))}
                        </select>
                        <div className={S.icon}>
                            <button className={S.selectBtn} tabIndex={3}>
                                <SvgIcon iconName={IconNames.Chevron} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {orders && orders.length ? (
                <div className={S.orderHistoryList}>
                    {orders?.map(order => (
                        <Fragment key={order.id}>
                            <div className={cn(S.orderNumber, expanded.includes(order.id) && S.expanded)}>
                                <label>{getTranslation("orderHistory.orderNumber")}</label>
                                {order.orderNumber}
                            </div>
                            
                            <div className={cn(S.orderDate, expanded.includes(order.id) && S.expanded)}>
                                <label>{getTranslation("orderHistory.deliveryDate")}</label>
                                {new Date(order.deliveryDate).toLocaleDateString()}
                            </div>

                            <div className={cn(S.orderTotal, expanded.includes(order.id) && S.expanded)}>
                                <label>{getTranslation("orderHistory.orderTotal")}</label>
                                {order.orderTotal} {order.currencyCode}
                            </div>
                            
                            <div className={cn(S.orderStatus, expanded.includes(order.id) && S.expanded)}>
                                <label>{getTranslation("orderHistory.orderStatus")}</label>
                                {order.orderStatus}
                            </div>
                            
                            <div className={cn(S.toggle, expanded.includes(order.id) && S.expanded)}>
                                <Button
                                    className={cn(S.toggleButton, expanded.includes(order.id) && S.expanded)}
                                    variant="clear"
                                    onClick={() => toggleRow(order.id)}
                                >
                                    <SvgIcon iconName={IconNames.Chevron} />
                                    <span>
                                        {getTranslation("orderHistory.toggleOrderlines", {
                                            count: order.lines?.length,
                                        })}
                                    </span>
                                </Button>                                   
                            </div>

                            <div className={cn(S.orderLine, expanded.includes(order.id) && S.expanded)}>
                                {order.lines?.map((line, lineIndex) => (
                                    <div key={`${line.lineNumber}_${lineIndex}`} className={S.productLine}>
                                        <div className={S.lineImage}><ImageWithFallback src={line.productImageUrl} showFallback /></div>
                                        <div className={S.lineName}>
                                            <div className={S.lineProductName}>{line.productName[currentCulture]}</div>
                                            <div>{getTranslation("orderHistory.productId")} {line.productNumber}</div>
                                        </div>
                                        <div className={S.lineAmount}>{line.discountedPrice} {order.currencyCode}</div>
                                        <div className={S.lineQuantity}><Button className={S.quantityButton} variant="pill" pillSmall>{line.quantity} {getTranslation("orderHistory.quantityAbbreviation")}</Button></div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                        )
                    )}
                </div>
            ) : (
                <>
                    <EmptyState
                        iconName={IconNames.OrderHistory}
                        heading={getTranslation("orderHistory.emptyStateHeading")}
                        description={getTranslation("orderHistory.emptyStateDescription")}
                    />
                </>
            )}
        </div>
    );
}
