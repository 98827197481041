// src/context/SidebarContext.tsx
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePage } from "./PageContext"; // Assuming you have this context to get the navigation structure

interface SidebarContextProps {
    openPaths: string[];
    setOpenPaths: (paths: string[]) => void;
}

interface SidebarProviderProps {
    children: React.ReactNode;
}

const SidebarContext = createContext<SidebarContextProps>({
    openPaths: [],
    setOpenPaths: () => {},
});

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
    const location = useLocation();
    const { navigation } = usePage();
    const [openPaths, setOpenPaths] = useState<string[]>(() => {
        const storedPaths = sessionStorage.getItem("openPaths");
        return storedPaths ? JSON.parse(storedPaths) : [];
    });

    useEffect(() => {
        sessionStorage.setItem("openPaths", JSON.stringify(openPaths));
    }, [openPaths]);

    const findPathToUrl = (urlPath: string, navigation: any): string[] => {
        const path: string[] = [];

        // Strip out the ID at the end of the URL path if it exists
        const strippedUrlPath = urlPath.replace(/\/[^/]+$/, "");

        const traverse = (items: any[], parentPath: string[] = []): boolean => {
            if (!Array.isArray(items)) {
                return false;
            }

            for (const item of items) {
                const currentPath = [...parentPath, item.name];
                if (item.url && item.url.urlPath === strippedUrlPath) {
                    path.push(...currentPath);
                    return true;
                }
                if (item.items && Array.isArray(item.items) && item.items.length > 0) {
                    if (traverse(item.items, currentPath)) {
                        path.push(item.name); // Include parent in path
                        return true;
                    }
                }
            }
            return false;
        };

        if (navigation && Array.isArray(navigation.items)) {
            traverse(navigation.items);
        } else {
            console.error("Navigation structure is not an array or is missing", navigation);
        }

        return path.reverse(); // Ensure the order is from root to leaf
    };

    useEffect(() => {
        const pathToExpand = findPathToUrl(location.pathname.replace("/", ""), navigation);
        setOpenPaths(pathToExpand);
    }, [location.pathname, navigation]);

    return (
        <SidebarContext.Provider value={{ openPaths, setOpenPaths }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => useContext(SidebarContext);
