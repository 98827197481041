import React from "react";

interface HMF_ImageProps {
    src: string;
    alt?: string;
    width?: number | string;
    height?: number | string;
    focalPoint?: {
        left: number;
        top: number;
    }
    loading?: "eager" | "lazy";
    className?: string;
    onClick?: () => void;
}

export const HMF_Image: React.FC<HMF_ImageProps> = ({
    src,
    alt,
    width,
    height,
    focalPoint,
    loading = "lazy",
    className,
    onClick,
}) => {
    return (
        <img
            src={src}
            alt={alt || "Image"}
            width={width}
            height={height}
            loading={loading}
            className={className}
            onClick={onClick}
            style={{ 
                cursor: onClick ? "pointer" : "auto",
                objectPosition: focalPoint ? `${focalPoint.left * 100}% ${focalPoint.top * 100}%` : '50% 50%'
            }}
        />
    );
};
