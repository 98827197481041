// src/helpers/getImageUrl.ts

import { isLocalhost } from "@shared/config/config";

const getImageUrl = (relativePath: string): string => {
    const appType = import.meta.env.VITE_APP_TYPE;
    let baseUrl = "";

    if (appType === "b2b") {
        if (isLocalhost) {
            baseUrl = import.meta.env.VITE_IMAGE_URL_B2B;
        } else {
            // @ts-expect-error
            baseUrl = globalThis?.contentApiUrl || import.meta.env.VITE_IMAGE_URL_B2B;
        }
    } else if (appType === "b2c") {
        if (isLocalhost) {
            baseUrl = import.meta.env.VITE_IMAGE_URL_B2B; // Assuming this is not a mistake
        } else {
            baseUrl = import.meta.env.VITE_IMAGE_URL_B2C;
        }
    } else {
        throw new Error("Unknown APP_TYPE. Please check your environment variables.");
    }

    return `${baseUrl}${relativePath}`;
};

export default getImageUrl;
