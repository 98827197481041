import { Container } from "../../Atoms/Container/Container.component";
import S from "./ErrorPage.module.scss";
import { useTranslations } from "@shared/context/useTranslations";

export const ErrorPage = () => {
    const { getTranslation } = useTranslations();

    return (
        <Container>
            <div className={S.errorPage}>
                <h1>{getTranslation("general.page-not-found")}</h1>
                <p>{getTranslation("general.page-not-found.text")}</p>
            </div>
        </Container>
    );
};
