// @ts-nocheck
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";
import { Container } from "../../../components/Atoms/Container/Container.component";
import { useBlockDataMapping } from "../../../hooks/useBlockDataMapping";
import FooterColumn from "./components/FooterColumn.component";
import S from "./Footer.module.scss";

export const Footer = () => {
    return (
        <footer className={S.footer}>
            <Container className={S.columnWrapper}>
                <ErrorBoundary>
                    {/* @ts-ignore */}
                    {globalThis?.footer?.footerContent?.items
                        ? globalThis?.footer?.footerContent?.items?.map(
                              (item: any, index: number) => {
                                  const mappedData = useBlockDataMapping(
                                      item.content.contentType,
                                      item.content
                                  );
                                  return <FooterColumn key={index} {...mappedData} />;
                              }
                          )
                        : null}
                </ErrorBoundary>
            </Container>
        </footer>
    );
};
