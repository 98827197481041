import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { animated, useTransition } from "react-spring";
import { cn } from "@shared/helpers/classNames.helper";
import { blurActiveElement } from "@shared/helpers/blurActiveElement";
import ReactModal from "react-modal";
import S from "@shared/components/Molecules/Modal/Modal.module.scss";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import { useToast } from "@shared/context/ToastContext";

export enum ModalSize {
    XS = 360,
    SM = 480,
    MD = 580,
    LG = 720,
    XL = 840,
    XXL = 1000,
}

type ContentType = React.ReactNode | string;

export enum ModalButtonType {
    DELETE = "delete",
    SAVE = "save",
    SUBMIT = "submit",
}

interface ModalButton {
    label: string;
    onClick: () => void;
    type?: ModalButtonType;
    showToaster?: boolean;
    disabled?: boolean;
}

export interface ModalOptions {
    size?: ModalSize;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    onClose?: () => void;
    title?: string;
    description?: string;
    actionButton?: ModalButton;
    cancelButton?: ModalButton;
    toastMessage?: React.ReactNode;
    iconName?: IconNames | string;
}

interface ModalHook {
    showModal: (content: ContentType, options?: ModalOptions) => void;
    closeModal: () => void;
    isModalShowing: boolean;
    scrollToTop: () => void;
    modalState: Record<string, any>;
    setModalState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const ModalContext = createContext<ModalHook>({
    showModal: () => null,
    closeModal: () => null,
    isModalShowing: false,
    scrollToTop: () => null,
    modalState: {},
    setModalState: () => {},
});

type Props = {
    children: React.ReactNode;
};

export const ModalProvider = ({ children }: Props): JSX.Element => {
    const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(true);
    const [shouldCloseOnEsc, setShouldCloseOnEsc] = useState(true);
    const [content, setContent] = useState<ContentType>();
    const [isShowing, setIsShowing] = useState(false);
    const [size, setSize] = useState<ModalSize>(ModalSize.SM);
    const [title, setTitle] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [actionButton, setActionButton] = useState<ModalButton | undefined>();
    const [cancelButton, setCancelButton] = useState<ModalButton | undefined>();
    const [toastMessage, setToastMessage] = useState<React.ReactNode>();
    const [iconName, setIconName] = useState<IconNames | string>("");
    const [showToastOnAction, setShowToastOnAction] = useState<boolean>(false);
    const onCloseCallback = useRef<(() => void) | null>(null);
    const [modalState, setModalState] = useState<Record<string, any>>({}); // Modal-specific state

    const { showToast } = useToast();

    const contentWrapperRef = useRef<HTMLDivElement | null>(null);

    const transition = useTransition(isShowing, {
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
        leave: { opacity: 0, transform: "translate3d(0, 30px, 0)" },
        delay: isShowing ? 100 : 0,
        config: { friction: 24 },
        onDestroyed: () => {
            if (!isShowing) {
                setContent(undefined);
                setTitle(undefined);
                setDescription(undefined);
                setActionButton(undefined);
                setCancelButton(undefined);
                setToastMessage(undefined);
                setShowToastOnAction(false);
                setModalState({}); // Reset modal state
                setTimeout(() => blurActiveElement(), 0);
            }
        },
    });

    const showModal = useCallback((content: ContentType, options?: ModalOptions) => {
        const {
            shouldCloseOnOverlayClick = true,
            shouldCloseOnEsc = true,
            onClose,
            title,
            description,
            actionButton,
            cancelButton,
            toastMessage,
            iconName,
        } = options || {};
        setShouldCloseOnOverlayClick(shouldCloseOnOverlayClick);
        setShouldCloseOnEsc(shouldCloseOnEsc);

        setSize(options?.size || ModalSize.SM);
        if (typeof onClose === "function") {
            onCloseCallback.current = onClose;
        }
        setTitle(title);
        setDescription(description);
        setActionButton(actionButton);
        setCancelButton(cancelButton);
        setToastMessage(toastMessage);
        setIconName(iconName || "");
        setShowToastOnAction(actionButton?.showToaster || false);
        setContent(content);
    }, []);

    const closeModal = useCallback(() => {
        setIsShowing(false);

        if (showToastOnAction && toastMessage) {
            showToast(toastMessage, iconName);
        }

        if (onCloseCallback.current) {
            onCloseCallback.current();
            onCloseCallback.current = null;
        }
    }, [toastMessage, showToast, iconName, showToastOnAction]);

    const scrollToTop = useCallback(() => {
        contentWrapperRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, [contentWrapperRef]);

    const getActionButtonClass = (type?: ModalButtonType) => {
        switch (type) {
            case ModalButtonType.DELETE:
                return S.deleteButton;
            case ModalButtonType.SAVE:
                return S.saveButton;
            case ModalButtonType.SUBMIT:
                return S.submitButton;
            default:
                return S.defaultButton;
        }
    };

    useEffect(() => {
        if (content) {
            setIsShowing(true);
        }
    }, [content]);

    return (
        <ModalContext.Provider
            value={{
                showModal,
                closeModal,
                isModalShowing: typeof content !== "undefined",
                scrollToTop,
                modalState,
                setModalState,
            }}
        >
            {children}

            <ReactModal
                isOpen={typeof content !== "undefined"}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                shouldCloseOnEsc={shouldCloseOnEsc}
                overlayClassName={S.modalContainer}
                className={S.modalContent}
                style={{
                    overlay: {
                        zIndex: 9,
                    },
                    content: {
                        width: `${size}px`,
                    },
                }}
                appElement={document.body}
                parentSelector={() => document.body}
                overlayElement={(props, contentEl) => (
                    <div {...props}>
                        <div className={cn(S.modalOverlay, isShowing && S.modalOverlayShow)} />
                        {contentEl}
                    </div>
                )}
            >
                {transition(({ transform, opacity }, state) =>
                    state ? (
                        <animated.div style={{ transform, opacity }}>
                            <animated.div ref={contentWrapperRef} className={S.modalContentInside}>
                                <div className={S.modalHeader}>
                                    <span className={S.modalTitle}>{title}</span>
                                    {description && (
                                        <span className={S.modalDescription}>{description}</span>
                                    )}
                                    <button onClick={closeModal} className={S.closeButton}>
                                        <SvgIcon iconName={IconNames.Close} />
                                    </button>
                                </div>
                                <div className={S.modalBody}>{content}</div>
                                <div className={S.modalFooter}>
                                    {actionButton && (
                                        <button
                                            onClick={() => {
                                                actionButton.onClick();
                                                closeModal();
                                            }}
                                            className={getActionButtonClass(actionButton.type)}
                                            disabled={actionButton.disabled}
                                        >
                                            {actionButton.label}
                                        </button>
                                    )}
                                    {cancelButton && (
                                        <button
                                            onClick={cancelButton.onClick}
                                            className={S.cancelButton}
                                        >
                                            {cancelButton.label}
                                        </button>
                                    )}
                                </div>
                            </animated.div>
                        </animated.div>
                    ) : null
                )}
            </ReactModal>
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalHook => React.useContext(ModalContext);
