import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import S from "./ImageWithFallback.module.scss";
import Skeleton from "react-loading-skeleton";

interface ImageWithFallbackProps {
    src: string;
    alt?: string;
    className?: string;
    showFallback: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
    src,
    alt,
    className,
    showFallback,
}) => {
    const [hasProductImg, setHasProductImg] = useState(false);

    return (
        <div className={className}>
            {showFallback ? (
                <div className={S.fallbackImgWrapper}>
                    <SvgIcon className={S.fallbackImg} iconName={IconNames.Fallback} />
                </div>
            ) : (
                <>
                    {!hasProductImg && (
                        <div className={S.fallbackImgWrapper}>
                            <Skeleton className={S.skeleton} />
                        </div>
                    )}

                    <img
                        src={src}
                        alt={alt}
                        onLoad={() => setHasProductImg(true)}
                        className={S.image}
                        style={{ display: hasProductImg ? "block" : "none" }}
                    />
                </>
            )}
        </div>
    );
};

export default ImageWithFallback;
