import { useEffect, useState } from "react";
import S from "./QuantityPicker.module.scss";
interface QuantityPickerProps {
    quantity: number;
    setQuantity: (quantity: number) => void;
    allowDecrementToZero?: boolean;
    tabIndex?: number;
}

const QuantityPicker = ({
    quantity = 1,
    setQuantity,
    allowDecrementToZero = false,
    tabIndex,
}: QuantityPickerProps) => {
    const [inputValue, setInputValue] = useState(quantity.toString());

    useEffect(() => {
        setInputValue(quantity.toString());
    }, [quantity]);

    const increment = () => {
        setQuantity(quantity + 1);
    };

    const decrement = () => {
        if (allowDecrementToZero) {
            setQuantity(quantity - 1);
        } else {
            if (quantity > 1) {
                setQuantity(quantity - 1);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleBlur = () => {
        const parsedValue = parseInt(inputValue, 10);
        if (!isNaN(parsedValue) && parsedValue > 0) {
            setQuantity(parsedValue);
        } else {
            setInputValue(quantity.toString());
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    return (
        <div className={S.quantityPicker}>
            <button
                onClick={decrement}
                className={quantity <= 1 ? S.decrementBtnBtnZero : S.decrementBtn}
            >
                <span>-</span>
            </button>
            <input
                autoComplete="off"
                type="number"
                id="quantity"
                name="quantity"
                value={inputValue}
                className={S.quantity}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                tabIndex={tabIndex}
            />

            <button onClick={increment} className={S.incrementBtn}>
                <span>+</span>
            </button>
        </div>
    );
};

export default QuantityPicker;
