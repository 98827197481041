// src/config/queryClient.ts
import { QueryClient, QueryCache, MutationCache } from "@tanstack/react-query";
// Cache query
const queryCache = new QueryCache({
    onError: (error: unknown) => {
        console.error("Query Error: ", error);
        // Optionally show a toast message or some notification
    },
    onSettled: (_data: unknown, error: unknown | null) => {
        if (error) {
            console.error("Query Settled with Error: ", error);
        }
    },
});

const mutationCache = new MutationCache({
    onError: (error: unknown) => {
        console.error("Mutation Error: ", error);
        // Optionally show a toast message or some notification
    },
    onSettled: (_data: unknown, error: unknown | null) => {
        if (error) {
            console.error("Mutation Settled with Error: ", error);
        }
    },
});

export const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3, // Retry failed queries three times
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
            staleTime: 0,
            gcTime: 300000,
        },
        mutations: {
            retry: 2, // Retry failed mutations twice
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
        },
    },
});
