import { IconNames } from "@shared/enums/icons.enums";
import { Icon } from "../Icon/Icon.component";
import React, { useState, KeyboardEvent, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslations } from "@shared/context/useTranslations";
import Button from "@shared/components/Atoms/Button/Button";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./AddItemNumber.module.scss";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { ProductValidationResponse, validateProductId } from "@shared/helpers/apis/productHelper";

export interface AddItemNumberProps {
    onSuccess: (productId: string) => void;
}

const AddItemNumber = ( {onSuccess }: AddItemNumberProps) => {
    const { getTranslation } = useTranslations();
    const [addItemNumberActive, setAddItemNumberActive] = useState(false);
    const [validationState, setValidationState] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [elementVariables, setElementVariables] = useState<React.CSSProperties>({});
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const inputFieldRef = useRef<HTMLInputElement>(null);

    const validateProduct = async (productId: string) => {
        const response: ProductValidationResponse = await validateProductId([productId]);
        const [result] = Object.values(response).map((res:ProductValidationResponse) => res);
        return result.isValid;
    }

    const goToSearch = async () => {
        setValidationState("")
        const isValid = await validateProduct(inputValue);
        
        if (isValid) {
            setValidationState("success");
            onSuccess(inputValue);
            setInputValue("");
        } else {
            setValidationState("error")
        }

        setTimeout(() => {
            setValidationState("");
        }, 5000);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            goToSearch()
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setAddItemNumberActive(false);
            setInputValue("");
        }
    };

    const handleReset = () => {
        setAddItemNumberActive(false);
        setInputValue("");
    };

    document.addEventListener("keydown", (e) => {
        if (addItemNumberActive && e.key === 'Escape') {
            handleReset()
        }        
    })

    useEffect(() => {
        if (addItemNumberActive) {
            document.addEventListener("mousedown", handleClickOutside);
            setTimeout(() => {
                inputFieldRef.current?.focus();
            }, 350);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            setTimeout(() => {
                inputFieldRef.current?.blur();
            }, 500);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [addItemNumberActive]);


    useLayoutEffect(() => {
        setTimeout(() => {
            if (buttonRef.current && inputRef.current) {
                const buttonWidth = Math.round(buttonRef.current.getBoundingClientRect().width);
                const inputWidth = Math.round(inputRef.current.getBoundingClientRect().width);
                const props = { "--button-width": `${buttonWidth}px`, "--input-width": `${inputWidth}px` } as React.CSSProperties;
                setElementVariables(props);       
            }
        }, 350);
    }, []);

    return ( 
        <div
            ref={containerRef}
            className={cn(
                S.addItemWrapper, 
                S[validationState],
                addItemNumberActive && S.active,
            )}
            style={elementVariables}
            tabIndex={-1}
        >
            <div
                ref={inputRef}
                className={S.addItemInputWrapper}
                tabIndex={-1}
            >
                <input
                    type="text"                    
                    className={S.addItemInput}
                    ref={inputFieldRef}
                    value={inputValue}
                    tabIndex={-1}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />

                <Button 
                    className={S.addItemSearchBtn}
                    onClick={goToSearch}
                    tabIndex={-1}
                >
                    <Icon iconName={IconNames.Search} />
                </Button>

                <div 
                    className={S.resetButton}
                    onClick={handleReset}
                >
                    <SvgIcon iconName={IconNames.Close} />
                </div>

                <div className={S.validationMessage}>
                    {validationState === "error" && getTranslation("basket.add-item-number.error-message")}
                    {validationState === "success" && getTranslation("basket.add-item-number.success-message")}
                </div>
            </div>
            <div
                className={S.addItemNumberBtnContainer}
                ref={buttonRef}
                tabIndex={-1}
                >
                <Button
                    variant="clear"
                    size="small"
                    className={S.addItemNumberBtn}
                    iconName={IconNames.Barcode}
                    onClick={() => setAddItemNumberActive(!addItemNumberActive)}
                >
                    <span>{getTranslation("basket.add-item-number")}</span>
                </Button>
            </div>
        </div>
    );
}
 
export default AddItemNumber;