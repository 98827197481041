import { Link, useNavigate } from "react-router-dom";
import { IconNames } from "../../../../../enums/icons.enums";
import { CategoryProduct } from "../../../../../types/CategoryTypes.interface";
import Button from "../../../../Atoms/Button/Button";
import QuantityPicker from "@shared/components/Molecules/QuantityPicker/QuantityPicker.component";
import ImageWithFallback from "../../../../Molecules/ImageWithFallback/ImageWithFallback.component";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { useBasket } from "@shared/context/BasketContext";
import { useToast } from "@shared/context/ToastContext";
import { useTranslations } from "@shared/context/useTranslations";
import { ProductPriceResponse } from "@shared/helpers/apis/fetchProductPrices";
import { formatNumber } from "@shared/helpers/formatPrice";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import { StockStatus } from "@shared/components/Molecules/StockStatus/StockStatus.component";
import { Modal } from "@shared/components/Molecules/Modal/Modal.component";
import { useFavoriteManagement } from "@shared/hooks/useFavoriteManagement";
import { FavoriteList } from "@shared/helpers/favoritesHelper";
import S from "./Item.module.scss";
import { cn } from "@shared/helpers/classNames.helper";
import { useEffect, useState } from "react";
import { getThumbnailImageUrl } from "@shared/helpers/apis/productHelper";

interface ProductList_ItemProps {
    product: CategoryProduct;
    path?: string;
    prices?: ProductPriceResponse;
    quantity: number;
    onQuantityChange: (quantity: number) => void;
}

export const ProductList_Item = ({
    product,
    prices,
    quantity,
    onQuantityChange,
}: ProductList_ItemProps) => {
    const { addToBasket } = useBasket();
    const { showToast } = useToast();
    // @ts-expect-error
    const currentCulture = globalThis?.currentCulture;
    const navigate = useNavigate();
    const [pendingSave, setPendingSave] = useState(false);
    const [listKey, setListKey] = useState(0);
    const {
        isModalOpen,
        setIsModalOpen,
        selectedLists,
        setSelectedLists,
        isProductFavorited,
        handleFavoriteClick,
        handleSaveFavorites,
        getModalTitle,
        getModalDescription,
        favorites,
    } = useFavoriteManagement(product);

    const favoritesPath = getRoutePathByPageType("Favorites") || "/default-favorites-path";
    const basketPath = getRoutePathByPageType("Basket") || "/basket";
    const { getTranslation } = useTranslations();

    const handleAddToBasket = () => {
        addToBasket({
            productId: product.id.toString(),
            quantity,
            data: { url: product.images[0]?.url },
        });
        showToast(
            <>
                {quantity} {getTranslation("general.piece")} {product.name}{" "}
                {getTranslation("general.toastMessage.addedTo")}{" "}
                <Link to={basketPath}>{getTranslation("general.toastMessage.yourBasket")}</Link>
            </>
        );
    };

    useEffect(() => {
        if (pendingSave) {
            handleSaveFavorites(false);
            setTimeout(() => {
                setPendingSave(false);
            }, 50);
        }
        setListKey(listKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingSave, selectedLists]);

    const quantityDiscountTooltipContent = prices?.discountTable?.quantityDiscount
        ? Object.entries(prices?.discountTable?.quantityDiscount).map(
              ([key, value]: [string, any]) => (
                  <div key={key} className={S.discountPlacement}>
                      <span className={S.discountPlacementLeft}>
                          {getTranslation("general.quantity")} {value.quantity}{" "}
                          {getTranslation("general.pricePerPiece")}:{" "}
                      </span>
                      <span className={S.discountPlacementRight}>
                          {formatNumber(value.netPrice, currentCulture, { decimals: 2 })}{" "}
                      </span>
                  </div>
              )
          )
        : null;

    return (
        <div className={S.productItemWrapper}>
            <div className={S.box}>
                <Link className={S.productImgWrapper} to={`/${product?.url?.urlPath}` || ""}>
                    <ImageWithFallback
                        src={getThumbnailImageUrl(product.images[0]?.url)}
                        showFallback={product.images?.length === 0}
                        alt={product.name}
                        className={S.productImg}
                    />
                </Link>
                <div className={S.stockStatus}>
                    <div className={S.stockStatusBg}></div>
                    <StockStatus stock={product?.stock} />
                </div>
                <Link className={S.productInfo} to={`/${product?.url?.urlPath}` || ""}>
                    <span className={S.productNumber}>{product.id}</span>
                    <span className={S.productName}>{product.name}</span>
                    <span className={S.productText}>{product?.shortDescription}</span>
                </Link>
                {prices?.discountedPrice && prices?.discountedPrice > 0 ? (
                    <div className={S.prices}>
                        <div className={S.productPrices}>
                            {prices.discountPerUnit > 0 ? (
                                <div>
                                    {prices?.listPrice && (
                                        <span className={S.oldPrice}>
                                            {formatNumber(prices?.listPrice, currentCulture, {
                                                decimals: 2,
                                            })}{" "}
                                            {prices?.discountTable?.currencyCode.toUpperCase()}
                                        </span>
                                    )}

                                    {prices?.discountPerUnit && (
                                        <span className={S.discount}>
                                            -
                                            {formatNumber(prices?.discountPerUnit, currentCulture, {
                                                decimals: 2,
                                            })}{" "}
                                            {prices?.discountTable?.currencyCode.toUpperCase()}
                                        </span>
                                    )}
                                </div>
                            ) : null}

                            <span
                                className={cn(
                                    S.price,
                                    prices.discountPerUnit === 0 ? S.priceOnly : ""
                                )}
                            >
                                {formatNumber(prices?.discountedPrice, currentCulture, {
                                    decimals: 2,
                                })}{" "}
                                {prices?.discountTable?.currencyCode.toUpperCase()}
                            </span>
                        </div>
                    </div>
                ) : (
                    <span className={S.priceContact}>
                        {getTranslation("general.contactForPrice")}
                    </span>
                )}

                {prices?.discountedPrice && prices?.discountedPrice > 0 ? (
                    <div className={S.btns}>
                        <div className={S.productCounter}>
                            <QuantityPicker quantity={quantity} setQuantity={onQuantityChange} />
                        </div>
                        <div className={S.productBtn}>
                            <Button
                                className={S.addToBasketBtn}
                                variant="primary"
                                onClick={handleAddToBasket}
                                iconName={IconNames.Basketleft}
                            >
                                {getTranslation("button.buy")}
                            </Button>
                        </div>
                    </div>
                ) : null}

                <div className={S.extraOptions}>
                    {prices?.discountTable?.quantityDiscount && (
                        <Button
                            tooltipContent={quantityDiscountTooltipContent}
                            tooltipId="listDiscount-tooltip"
                            showTip
                            tooltipPlace="top"
                            className={S.favIconBtn}
                            iconName={IconNames.Discount}
                        >
                            {" "}
                        </Button>
                    )}

                    <Button className={S.heartBtn} onClick={handleFavoriteClick}>
                        {isProductFavorited ? (
                            <SvgIcon iconName={IconNames.Heartfull} />
                        ) : (
                            <SvgIcon iconName={IconNames.Heart} />
                        )}
                    </Button>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                title={getModalTitle()}
                description={getModalDescription()}
            >
                {favorites && favorites.length > 0 ? (
                    favorites.map((list: FavoriteList) => (
                        <div key={list.id} className={S.favoriteListItem}>
                            <div className={S.favoriteToggleWrapper}>
                                <Button
                                    size="fitIcon"
                                    className={S.favoriteToggle}
                                    onClick={() => {
                                        setSelectedLists((prev: Record<string, boolean>) => ({
                                            ...prev,
                                            [list.id]: !prev[list.id],
                                        }));
                                        setPendingSave(true);
                                    }}
                                >
                                    <SvgIcon
                                        // Force icon re-render via key update due to Safari SVG issue
                                        key={isModalOpen ? `${list.id}${listKey}` : list.id}
                                        wrapperStyle={
                                            S[
                                                pendingSave
                                                    ? selectedLists[list.id]
                                                        ? "adding"
                                                        : "removing"
                                                    : ""
                                            ]
                                        }
                                        iconName={
                                            selectedLists[list.id]
                                                ? IconNames.Heartfull
                                                : IconNames.Heart
                                        }
                                    />
                                </Button>
                            </div>
                            <div className={S.infoBox}>
                                <span className={S.favoriteListName}>{list.name}</span>
                                <span className={S.favoriteListCount}>
                                    {list.items.length}{" "}
                                    {list.items.length > 1
                                        ? getTranslation("Favorites.itemNumbers")
                                        : getTranslation("Favorites.itemNumber")}
                                </span>
                            </div>

                            <Button
                                onClick={() => {
                                    setIsModalOpen(false);
                                    navigate(`${favoritesPath}?listId=${list.id}`);
                                }}
                                className={S.goToListBtn}
                                variant="transparent"
                                size="small"
                                iconName={IconNames.Backicon}
                            >
                                {getTranslation("button.toList")}
                            </Button>
                        </div>
                    ))
                ) : (
                    <div className={S.noFavoritesMessage}>
                        <Button
                            round
                            onClick={() => {
                                setIsModalOpen(false);
                                navigate(`${favoritesPath}`);
                            }}
                            iconName={IconNames.Backicon}
                        >
                            {getTranslation("button.toFavorites")}
                        </Button>
                        <Button round variant="transparent" onClick={() => setIsModalOpen(false)}>
                            {getTranslation("button.cancel")}
                        </Button>
                    </div>
                )}
                <div className={S.modalFooter}>
                    {favorites && favorites.length > 0 && (
                        <Button onClick={handleSaveFavorites}>
                            {getTranslation("button.save")}
                        </Button>
                    )}
                    <Button variant="transparent" onClick={() => setIsModalOpen(false)}>
                        {getTranslation("button.cancel")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ProductList_Item;
