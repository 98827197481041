import React, { useState } from "react";
import { useTranslations } from "@shared/context/useTranslations";
import { animated, useTransition } from "react-spring";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import S from "./ShowMore.module.scss";
import { cn } from "@shared/helpers/classNames.helper";

interface ShowMoreProps {
    buttonTextBefore?: string;
    buttonTextAfter?: string;
    children?: React.ReactNode;
    iconName?: IconNames | string;
    color?: "blue" | string;
}

const ShowMore: React.FC<ShowMoreProps> = ({
    children,
    buttonTextBefore,
    buttonTextAfter,
    iconName = IconNames.Smallarrowright,
    color = "blue",
}) => {
    const { getTranslation } = useTranslations();
    const [showMore, setShowMore] = useState(false);

    const defaultButtonTextBefore = buttonTextBefore || getTranslation("button.showMore");
    const defaultButtonTextAfter = buttonTextAfter || getTranslation("button.showLess");

    const getColorClass = () => {
        switch (color) {
            case "green":
                return "green";
            default:
                return "blue";
        }
    };

    const transitions = useTransition(showMore, {
        from: { maxHeight: 0, opacity: 0 },
        enter: { maxHeight: 800, opacity: 1 },
        leave: { maxHeight: 0, opacity: 0 },
        config: { duration: 300 },
    });

    // Split children into first and remaining
    const childrenArray = React.Children.toArray(children);
    const firstChild = childrenArray[0];
    const remainingChildren = childrenArray.slice(1);

    return (
        <>
            <div>{firstChild}</div>
            {transitions((style, item) =>
                item ? (
                    <>
                        <animated.div style={style}>
                            <div>
                                <div
                                    className={cn(S.showMoreContent, {
                                        [S.hideContent]: !showMore,
                                    })}
                                >
                                    {remainingChildren}
                                </div>
                            </div>
                        </animated.div>
                    </>
                ) : null
            )}
            <button
                className={cn(S.btn, S[getColorClass()], showMore ? S.btnShow : "")}
                onClick={() => setShowMore(!showMore)}
            >
                <SvgIcon iconName={showMore ? IconNames.Smallarrowright : iconName} />
                {showMore ? defaultButtonTextAfter : defaultButtonTextBefore}
            </button>
        </>
    );
};

export default ShowMore;
