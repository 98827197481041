// src/components/Collapsible/Collapsible.component.tsx
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import React, { ReactElement, useEffect, useState } from "react";
import S from "./Collapsibe.module.scss";
import { cn } from "@shared/helpers/classNames.helper";

interface CollapsibleProps extends React.PropsWithChildren {
    className?: string;
    open?: boolean;
    openClassName?: string;
    title: string;
    titleClassName?: string;
    contentClassName?: string;
    children: ReactElement;
    onToggle?: (name: string, isOpen: boolean) => void;
}

export const Collapsible: React.FC<CollapsibleProps> = (props) => {
    const [isOpen, setIsOpen] = useState(props.open || false);

    useEffect(() => {
        setIsOpen(props.open || false);
    }, [props.open]);

    const handleToggle = () => {
        setIsOpen((prev) => {
            if (props.onToggle) {
                props.onToggle(props.title, !prev);
            }
            return !prev;
        });
    };

    return (
        <div
            className={cn(
                props.className && props.className,
                isOpen && S.isOpen,
                props.openClassName && isOpen && props.openClassName
            )}
        >
            <button type="button" className={S.collapsibleTrigger} onClick={handleToggle}>
                <span
                    className={cn(
                        props.titleClassName && props.titleClassName,
                        props.openClassName && isOpen && props.openClassName,
                        isOpen && S.bold // Apply bold class when open
                    )}
                >
                    {props.title}
                </span>
                <SvgIcon iconName="chevron" />
            </button>
            <div
                className={cn(
                    S.collapsibleContentWrapper,
                    props.contentClassName && isOpen && props.contentClassName
                )}
            >
                <div className={S.collapsibleContent}>
                    <div className={S.collapsibleContentInner}>{props.children}</div>
                </div>
            </div>
        </div>
    );
};
