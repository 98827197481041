import React, { createContext, ReactNode, useContext, useState, useMemo, useCallback } from "react";
import { isDevelopment } from "../helpers/isDevelopment";

// Define the shape of the translations object
interface Translations {
    [key: string]: string;
}

// Define the context type
interface TranslationsContextType {
    translations: Translations;
    updateTranslations: (culture: string, translations: Translations) => void;
    getTranslation: (alias: string, options?: Record<string, any>) => string;
    getCulture: () => string;
}

// Create the context with an undefined initial values
const TranslationsContext = createContext<TranslationsContextType | undefined>(undefined);

// Define the provider component
export const TranslationsProvider: React.FC<{
    children: ReactNode;
    initial: { translations: Translations };
}> = ({ children, initial }) => {
    const [translations, setTranslations] = useState<Translations>(initial.translations);

    // Memoize the translations object with lowercase keys
    const lowerCaseTranslations = useMemo(() => {
        let result: Translations = {};

        if (translations) {
            result = Object.keys(translations).reduce((previous, current) => {
                const lowercaseKey = current ? current.toLowerCase() : "";
                previous[lowercaseKey] = translations[current];
                return previous;
            }, {} as Translations);
        }
        return result;
    }, [translations]);

    // Function to update translations and set the culture in localStorage
    const updateTranslations = useCallback((culture: string, translations: Translations) => {
        setTranslations(translations);

        if (culture) {
            localStorage.setItem("culture", culture);
        }
    }, []);

    // Function to get the current culture
    const getCulture = useCallback(() => {
        const firstUrlCulture = window.location.pathname.split("/")[1];

        const fallbackCulture = localStorage.getItem("culture");
        const allowedCultures = ["da-DK", "en-GB", "de-DE"];

        return allowedCultures.includes(firstUrlCulture)
            ? firstUrlCulture
            : fallbackCulture ?? "en-GB";
    }, []);

    // Function to get a translation string by alias
    const getTranslation = useCallback(
        (alias: string, options?: Record<string, any>) => {
            const lowercaseAlias = alias ? alias.toLowerCase() : "";
            const string = lowerCaseTranslations[lowercaseAlias];

            const variables = options ?? {};

            if (typeof string !== "undefined") {
                const returnString = string.replace(/%(\w+)%/g, (match, key) => {
                    return variables[key] ?? match;
                });

                // check for placeholders
                if (variables) {
                    const regex = new RegExp("{{" + Object.keys(variables).join("}}|{{") + "}}", "g");
                    return returnString.replace(regex, (match) => String(variables[match.slice(2, -2)]) || "");                    
                }

                return returnString
            } else if (isDevelopment()) {
                const variablesHelperText =
                    Object.keys(variables).length > 0
                        ? ` | ${Object.keys(variables).join(";")}`
                        : "";
                return `{{${alias}${variablesHelperText}}}`;
            } else {
                return alias; // Return the alias as a fallback if no translation is found
            }
        },
        [lowerCaseTranslations]
    );

    // Provide the context value to children components
    return (
        <TranslationsContext.Provider
            value={{ translations, updateTranslations, getTranslation, getCulture }}
        >
            {children}
        </TranslationsContext.Provider>
    );
};

// Custom hook to use the translations context
export const useTranslations = (): TranslationsContextType => {
    const context = useContext(TranslationsContext);
    if (!context) {
        throw new Error("useTranslations must be used within a TranslationsProvider");
    }
    return context;
};
