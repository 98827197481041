import S from "./Icon.module.scss";
import { IconNames } from "../../../enums/icons.enums";
import { SvgIcon } from "../../Atoms/SvgIcon/SvgIcon";

export interface IconProps {
    text?: string;
    iconName: IconNames;
    className?: string;
}

export const Icon = ({ text, iconName }: IconProps) => {
    return (
        <div className={S.icon}>
            <div className={S.iconWrapper}>
                <SvgIcon iconName={iconName} />
            </div>
            <div className={S.iconText}>
                <span className={S.text}>{text}</span>
            </div>
        </div>
    );
};
