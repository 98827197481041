// src/helpers/apis/fetchProductPrices.ts
import { apiPost } from "./apiHelper";

export interface ProductPriceResponse {
  productId: string;
  currencyCode: string;
  listPrice: number;
  discountPerUnit: number;
  discountedPrice: number;
  appliedPercentDiscount: number;
  discountTable: {
    hasQuantityDiscount: boolean;
    hasCustomerDiscount: boolean;
    currencyCode: string;
    customerDiscount: {
      percent: number;
      netPrice: number;
    };
    quantityDiscount: {
      [key: string]: {
        quantity: number;
        grossPrice: number;
        percent: number;
        discountPerUnit: number;
        netPrice: number;
      };
    };
    listPrice: number;
  };
}

export interface BasketPriceResponse {
  subTotal: number;
  totalDiscount: number;
  vat: number;
  totalPriceWithVat: number;
  totalPriceWithoutVat: number;
  items: {
    productId: string;
    currencyCode: string;
    quantity: number;
    listPrice: number;
    discountPerUnit: number;
    discountedPrice: number;
    totalListPrice: number;
    totalDiscountedPrice: number;
  }[];
}

export const fetchProductPrices = async (
  productIds: string[],
  culture: string
): Promise<Record<string, ProductPriceResponse>> => {
  const response = await apiPost<
    Record<string, ProductPriceResponse>,
    string[]
  >(`/api/price/GetProductPrices?culture=${culture}`, productIds);
  return response ?? {};
};

export const fetchProductPrice = async (
  productId: string,
  quantity: number,
  culture: string
): Promise<ProductPriceResponse> => {
  const response = await apiPost<
    Record<string, ProductPriceResponse>,
    undefined
  >(
    `/api/price/GetProductPrice?culture=${culture}&productId=${productId}&quantity=${quantity}`
  );
  if (!response || !response[productId]) {
    throw new Error("Failed to fetch product price");
  }
  return response[productId];
};

export const fetchBasketPrices = async (
  productQuantities: { productId: string; quantity: number }[],
  culture: string
): Promise<BasketPriceResponse> => {
  const response = await apiPost<
    BasketPriceResponse,
    { productId: string; quantity: number }[]
  >(`/api/price/GetBasketPrices?culture=${culture}`, productQuantities);
  if (!response) {
    throw new Error("Failed to fetch basket prices");
  }
  return response;
};
