import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import ReactModal from "react-modal";
import { animated, useTransition } from "react-spring";
import S from "./Modal.module.scss";
import { IconNames } from "@shared/enums/icons.enums";
import { ReactNode } from "react";

interface ModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    title: string;
    description?: string;
    children: ReactNode;
}

export const Modal = ({ isOpen, onRequestClose, title, description, children }: ModalProps) => {
    const transition = useTransition(isOpen, {
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
        leave: { opacity: 0, transform: "translate3d(0, 30px, 0)" },
        config: { friction: 24 },
    });

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName={S.modalContainer}
            className={S.modalContentBoxWrapper}
            appElement={document.body}
        >
            {transition((style, item) =>
                item ? (
                    <animated.div style={style}>
                        <div className={S.modalContentBox}>
                            <div className={S.modalHeader}>
                                <span className={S.modalTitle}>{title}</span>
                                {description && (
                                    <span className={S.modalDescription}>{description}</span>
                                )}
                                <button onClick={onRequestClose} className={S.closeButton}>
                                    <SvgIcon iconName={IconNames.Close} />
                                </button>
                            </div>
                            <div className={S.modalBody}>{children}</div>
                        </div>
                    </animated.div>
                ) : null
            )}
        </ReactModal>
    );
};
