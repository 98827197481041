import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../../shared/src/config/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ScreenProvider } from "@shared/context/useScreen";
import { AuthProvider } from "@shared/context/AuthContext";
import { PageProvider } from "@shared/context/PageContext";
import { SearchProvider } from "@shared/context/SearchContext";
import { TranslationsProvider } from "../../shared/src/context/useTranslations";
import { BasketProvider } from "@shared/context/BasketContext";
import { SidebarProvider } from "@shared/context/SidebarContext";
import { FavoritesProvider } from "@shared/context/FavoritesContext";
import App from "./App";
import "@shared/styles/global/main.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

const ContextWrapper = ({ children, settings }: any) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ScreenProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <PageProvider settings={settings}>
                            <SidebarProvider>
                                <TranslationsProvider
                                    initial={{
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        translations: globalThis?.translations?.translations,
                                    }}
                                >
                                    <FavoritesProvider>
                                        <SearchProvider>
                                            <BasketProvider>{children}</BasketProvider>
                                        </SearchProvider>
                                    </FavoritesProvider>
                                </TranslationsProvider>
                            </SidebarProvider>
                        </PageProvider>
                    </AuthProvider>
                </BrowserRouter>
            </ScreenProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

const Index = () => {
    const pageContent = window?.pageContent;
    const settings = pageContent?.settings
        ? { ...pageContent.settings, menu: pageContent.menu }
        : undefined;
    const translations = pageContent?.translations?.translations || {};

    return (
        <ContextWrapper settings={settings} translations={translations} page={pageContent?.page}>
            <App />
        </ContextWrapper>
    );
};

root.render(<Index />);
