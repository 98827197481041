export const getRoutePathByPageType = (pageType: string): string | undefined => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Global header object
    const header = globalThis.header;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fePageObj = globalThis.frontendPages || {};

    const findRoute = (pages: any[]) =>
        pages.find((page) => page.properties.pageType === pageType)?.route.path;

    let path: string | undefined = undefined;

    if (header) {
        path = findRoute(header.headerIcons) || findRoute(header.profilePages);
    }

    if (!path) {
        const frontendPages = Object.keys(fePageObj).map((key) => fePageObj[key]);
        path = findRoute(frontendPages);
    }

    return path;
};
