import { ReactNode } from "react";
import { IconNames } from "../../../enums/icons.enums";
import { SvgIcon } from "../../Atoms/SvgIcon/SvgIcon";
import { useBasket } from "@shared/context/BasketContext";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./HeaderIcon.module.scss";

export interface HeaderIconProps {
    text?: string | ReactNode;
    iconName?: IconNames | string;
    subtitle?: string | ReactNode;
}

export const HeaderIcon = ({ text, iconName = "", subtitle }: HeaderIconProps) => {
    const { totalQuantity } = useBasket();
    return (
        <div className={S.headerIcon}>
            {iconName === "basket" && (
                <div className={S.basketAmount}>
                    <span>{totalQuantity}</span>
                </div>
            )}
            {iconName && iconName.length > 0 ? (
                <div className={S.iconWrapper}>
                    <SvgIcon iconName={iconName} wrapperStyle={S[iconName] || ""} />
                </div>
            ) : (
                <div className={S.iconWrapper}>{subtitle}</div>
            )}

            <span className={cn(S.text)}>{text}</span>
        </div>
    );
};
