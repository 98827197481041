// src/pages/Login.tsx
import { useEffect, useState } from "react";
import ModelImg from "../../../assets/login/model_png.png";
import CraneImg from "../../../assets/login/crane_png.png";
import Logo from "../../../assets/hmf-logo.svg";
import { isLocalhost } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../hooks/useLogin";
import Link from "../../Atoms/Button/Link/Link.component";
import Button from "../../Atoms/Button/Button";
import { cn } from "@shared/helpers/classNames.helper";
import { useTranslations } from "@shared/context/useTranslations";
import { HMF_Image } from "@shared/components/Atoms/Image/image.component";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";
import { HeaderDropdown } from "@shared/components/Molecules/HeaderDropdown/HeaderDropdown.component";
import { Flag } from "@shared/components/Molecules/flags/flag.component";
import S from "./Login.module.scss";

export const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loading] = useState(false); // New loading state
    const navigate = useNavigate();
    const loginMutation = useLogin();
    const { getTranslation } = useTranslations();
    const { currentFlag, languageData } = useCurrentCulture();

    const RECOVER_PASSWORD_LINK = "https://dk.hmfcranes.com/sign-in-1?LoginAction=Recovery";

    useEffect(
        () => {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.get("error") === "1") {
                setError(getTranslation("login.error"));
            }

            if (searchParams.get("errorKey")) {
                setError(getTranslation(searchParams.get("errorKey") || "login.error"));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location]
    );

    const handleLogin = (event: { preventDefault: () => void }) => {
        if (isLocalhost) {
            event.preventDefault();
            loginMutation.mutate(
                { userName, password },
                {
                    onSuccess: (response) => {
                        if (response.success) {
                            navigate("/"); // Redirect to the front page
                        } else {
                            setError(getTranslation(response.errorKey ? response.errorKey : "login.error"));
                        }
                    },
                    onError: () => {
                        setError(getTranslation("login.error"));
                    },
                }
            );
        }
    };

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
        if (e.target.value) {
            setUserNameError(false);
            if (password) setError(null);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setPasswordError(false);
            if (userName) setError(null);
        }
    };

    return (
        <div className={S.loginWrapper}>
            <div className={S.craneImg}>
                {/* TODO: Add translation for alt-text */}
                <HMF_Image src={CraneImg} alt="Crane" />
            </div>
            <div className={S.loginBox}>
                <div className={S.login}>
                    <div className={S.headlineBox}>
                        <div className={S.logo}>
                            <HMF_Image src={Logo} alt="HMF logo" />
                        </div>
                        <h2 className={S.headline}>{getTranslation("general.spare-parts")}</h2>
                    </div>
                    <div className={S.infoBox}>
                        <span className={S.helperText}>
                            {getTranslation("login.login-to-continue")}
                        </span>
                        <form action="/authenticate" method="post" onSubmit={handleLogin}>
                            <div className={`${S.loginInput} ${userNameError ? S.errorInput : ""}`}>
                                <label htmlFor="userName">{getTranslation("login.email")}</label>
                                <input
                                    id="userName"
                                    type="email"
                                    name="userName"
                                    value={userName}
                                    onChange={handleUserNameChange}
                                    placeholder={getTranslation("login.email-placeholder")}
                                    aria-label={userName}
                                    tabIndex={1}
                                    required
                                />
                            </div>
                            <br />
                            <div className={`${S.loginInput} ${passwordError ? S.errorInput : ""}`}>
                                <label htmlFor="password">{getTranslation("login.password")}</label>
                                <input
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder={getTranslation("login.password-placeholder")}
                                    aria-label={password}
                                    tabIndex={2}
                                    required
                                />
                            </div>
                            {error && (
                                <div role="alert" className={S.error}>
                                    {error}
                                </div>
                            )}
                            <br />

                            <Button
                                className={cn(S.loginBtn, S.full)}
                                type="submit"
                                variant="primary"
                                tabIndex={3}
                                disabled={loading}
                            >
                                {loading ? (
                                    <span className={S.spinner}></span>
                                ) : (
                                    <span>{getTranslation("login")}</span>
                                )}
                            </Button>
                        </form>
                        <Link
                            className={S.forgetPasswordLink}
                            href={RECOVER_PASSWORD_LINK}
                            target="_blank"
                            tabIndex={4}
                            aria-label={getTranslation("general.ariaLabel.newWindow")}
                        >
                            {getTranslation("login.forgot-password")}
                        </Link>
                    </div>
                </div>

                {languageData && languageData?.length ? (
                    <div className={S.currentLang}>
                        <HeaderDropdown
                            title={currentFlag}
                            items={languageData}
                            subtitle={<Flag name={currentFlag} />}
                        >
                            {languageData.map((lang, index) => (
                                <a
                                    key={index}
                                    className={S.dropdownItem}
                                    aria-label={lang.ariaLabel}
                                    href={lang.link}
                                >
                                    <Flag name={lang.icon} />
                                    {lang.text}
                                </a>
                            ))}
                        </HeaderDropdown>
                    </div>
                ) : null}
            </div>
            <div className={S.modelImg}>
                {/* TODO: Add translation for alt-text */}
                <HMF_Image src={ModelImg} alt="model" />
            </div>
        </div>
    );
};
