import { useState, useEffect } from "react";
import { FlagNames } from "../enums/flag.enums";
import { useTranslations } from "@shared/context/useTranslations";

const useCurrentCulture = () => {
  const [currentCulture, setCurrentCulture] = useState<string>(
    // @ts-expect-error
    globalThis?.currentCulture || "en-US"
  );

  const { getTranslation } = useTranslations();

  useEffect(() => {
    // @ts-expect-error
    setCurrentCulture(globalThis?.currentCulture || "en-US");
  }, []);

  const getFlagName = (culture: string): FlagNames => {
    const simplifiedCulture = culture.split("-")[0];
    switch (simplifiedCulture) {
      case "da":
        return FlagNames.Da;
      case "en":
        return FlagNames.En;
      case "fr":
        return FlagNames.Fr;
      case "de":
        return FlagNames.De;
      case "es":
        return FlagNames.Es;
      default:
        return FlagNames.Uk;
    }
  };

  const currentFlag = getFlagName(currentCulture);

  const getLanguageData = () => {
    // @ts-expect-error
    const cultures = globalThis?.cultures || {};
    return Object.keys(cultures).map((key) => {
      const simplifiedKey = key.split("-")[0];
      const flagName = getFlagName(key);
      const domain = cultures[key].domain || "";
      return {
        icon: flagName,
        text: getTranslation(
          `header.language-picker.${simplifiedKey}`
        ).toUpperCase(),
        link: `https://${domain}`,
        ariaLabel: key,
      };
    });
  };

  const languageData = getLanguageData();

  return { currentCulture, currentFlag, languageData };
};

export default useCurrentCulture;
