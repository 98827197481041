import { useState, useEffect } from "react";
import { useFavorites } from "@shared/context/FavoritesContext";
import { useToast } from "@shared/context/ToastContext";
import { IconNames } from "@shared/enums/icons.enums";
import { useTranslations } from "@shared/context/useTranslations";

export const useFavoriteManagement = (product: any) => {
  const { getTranslation } = useTranslations();
  const { favorites, addProductToFavorites, removeProductFromFavorites } =
    useFavorites();
  const { showToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLists, setSelectedLists] = useState<Record<string, boolean>>(
    {}
  );
  const [isProductFavorited, setIsProductFavorited] = useState(false);

  useEffect(() => {
    if (product) {
      const favorited = Object.values(product.favorites || {}).some(
        (fav: any) => fav.isFavorite
      );
      setIsProductFavorited(favorited);
    }
  }, [product]);

  useEffect(() => {
    const initialSelectedLists =
      favorites?.reduce((acc, list) => {
        acc[list.id] = list.items.some((item) => item.id === product?.id);
        return acc;
      }, {} as Record<string, boolean>) || {};

    setSelectedLists(initialSelectedLists);
  }, [favorites, product]);

  const handleFavoriteClick = () => {
    const initialSelectedLists =
      favorites?.reduce((acc, list) => {
        acc[list.id] = list.items.some((item) => item.id === product?.id);
        return acc;
      }, {} as Record<string, boolean>) || {};
    setSelectedLists(initialSelectedLists);
    setIsModalOpen(true);
  };

  const handleSaveFavorites = async (closeModalOnSave: boolean = true) => {
    if (!product) return; // Check if product is undefined

    const initialSelectedLists =
      favorites?.reduce((acc, list) => {
        acc[list.id] = list.items.some((item) => item.id === product.id);
        return acc;
      }, {} as Record<string, boolean>) || {};

    const listsToAdd = Object.keys(selectedLists).filter(
      (id) => selectedLists[id] && !initialSelectedLists[id]
    );
    const listsToRemove = Object.keys(selectedLists).filter(
      (id) => !selectedLists[id] && initialSelectedLists[id]
    );

    try {
      if (listsToAdd.length > 0) {
        await addProductToFavorites(product.id.toString(), listsToAdd);
      }

      if (listsToRemove.length > 0) {
        await removeProductFromFavorites(product.id.toString(), listsToRemove);
      }

      showToast(
        `${getTranslation("Favorites.toastMessage.updated")}`,
        IconNames.Favorites
      );

      // Update isProductFavorited to true if the product is in any selected list
      setIsProductFavorited(
        Object.values(selectedLists).some((selected) => selected)
      );
    } catch (error) {
      console.error("Failed to update favorite lists:", error);
    } finally {
      if (closeModalOnSave) {
        setIsModalOpen(false);
      }
    }
  };

  const getModalTitle = () => {
    if (!favorites || favorites.length === 0) {
      return `${getTranslation("Favorites.createFirstList")}`;
    }
    return isProductFavorited
      ? `${getTranslation("Favorites.modal.overview", {
          product: product?.id,
        })}`
      : `${getTranslation("Favorites.modal.addToFavorites", {
          product: product?.id,
        })}`;
  };

  const getModalDescription = () => {
    if (!favorites || favorites.length === 0) {
      return `${getTranslation("Favorites.noListsAvalibleCreateOne")}`;
    }
    return isProductFavorited
      ? `${getTranslation("Favorites.modal.addToFavorites.description")}`
      : `${getTranslation("Favorites.modal.addToFavorites.description")}
      `;
  };

  return {
    isModalOpen,
    setIsModalOpen,
    selectedLists,
    setSelectedLists,
    isProductFavorited,
    handleFavoriteClick,
    handleSaveFavorites,
    getModalTitle,
    getModalDescription,
    favorites,
  };
};
