import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Button from "@shared/components/Atoms/Button/Button";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { ModalButtonType, ModalSize, useModal } from "@shared/context/ModalContext";
import { useFavorites } from "@shared/context/FavoritesContext";
import { ProductList_List } from "../ProductList/components/List/List.component";
import { useToast } from "@shared/context/ToastContext";
import { useBasket } from "@shared/context/BasketContext";
import InputField from "@shared/components/Atoms/InputField/InputField.component";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import { useTranslations } from "@shared/context/useTranslations";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./Favorites.module.scss";
import AddItemNumber from "@shared/components/Molecules/AddItemNumber/AddItemNumber.component";

// In Favorites component
export const Favorites = () => {
    const { getTranslation } = useTranslations();
    const { showModal, closeModal } = useModal();
    const {
        favorites,
        addProductToFavorites,
        createFavoritesList,
        deleteFavoritesList,
        renameFavoritesList,
        refetchFavorites,
    } = useFavorites();

    const [selectedListId, setSelectedListId] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [quantities, setQuantities] = useState<Record<string, number>>({});
    const { addMultipleToBasket } = useBasket();
    const { showToast } = useToast();
    // Add a state for disabling the button temporarily
    const [isAddAllButtonDisabled, setIsAddAllButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Get the URL parameter
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const listId = params.get("listId");

        if (favorites && favorites.length > 0) {
            if (listId && favorites.some((list) => list.id === listId)) {
                setSelectedListId(listId);
            } else {
                setSelectedListId(favorites[0].id);
            }
        } else {
            setSelectedListId(null); // No lists available
        }
    }, [location.search, favorites]);

    // Delete favorites list
    const handleConfirmDeleteFavoritesList = async (listId: string, name: string) => {
        await deleteFavoritesList(listId, name);
        refetchFavorites();
        closeModal();
        showToast(
            `${getTranslation("Favorites.toastMessage.wasDeleted", {
                listName: name,
            })}`,
            IconNames.Trashcan
        );
    };

    const handleDeleteFavoritesList = (listId: string, name: string) => {
        showModal(
            <div>
                <p>
                    {getTranslation("Favorites.wantToDelete", {
                        listName: name,
                    })}
                </p>
            </div>,
            {
                size: ModalSize.SM,
                shouldCloseOnOverlayClick: true,
                shouldCloseOnEsc: true,
                title: `${getTranslation("button.delete.heading")}`,
                actionButton: {
                    label: `${getTranslation("button.delete")}`,
                    type: ModalButtonType.DELETE,
                    showToaster: true,
                    onClick: () => handleConfirmDeleteFavoritesList(listId, name),
                },
                cancelButton: {
                    label: `${getTranslation("button.cancel")}`,
                    onClick: closeModal,
                },
            }
        );
    };

    // ADD new favorites list
    const handleCreateFavoritesList = async (name: string) => {
        await createFavoritesList(name);
        refetchFavorites();
        closeModal();
        showToast(
            `${getTranslation("Favorites.toastMessage.success", {
                name: name,
            })}`,
            IconNames.Favorites
        );
    };

    const newFavoriteList = () => {
        let newListName = "";

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            newListName = e.target.value;
        };

        showModal(
            <div>
                <p>{getTranslation("Favorites.newListName")}</p>
                <InputField
                    className={S.inputField}
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getTranslation("Favorites.listName")}
                />
            </div>,
            {
                size: ModalSize.SM,
                shouldCloseOnOverlayClick: true,
                shouldCloseOnEsc: true,
                title: `${getTranslation("Favorites.newFavoriteList")}`,
                actionButton: {
                    label: `${getTranslation("button.confirm")}`,
                    type: ModalButtonType.SAVE,

                    onClick: () => handleCreateFavoritesList(newListName),
                },
                cancelButton: {
                    label: `${getTranslation("button.cancel")}`,
                    onClick: closeModal,
                },
            }
        );
    };

    // Edit favorites name
    const handleRenameFavoritesList = (listId: string, oldListName: string) => {
        let newListName = oldListName;

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            newListName = e.target.value;
            setErrorMessage(null); // Reset error message on input change
        };

        const handleConfirmRename = async () => {
            if (newListName.trim() !== "") {
                await renameFavoritesList(listId, newListName);
                refetchFavorites();
                closeModal();
                showToast(
                    `${getTranslation("Favorites.toastMessage.listNameRenamed", {
                        oldListName: oldListName,
                        newListName: newListName,
                    })}`,
                    IconNames.Pencil
                );
            } else {
                showModal(
                    <div>
                        <p>{getTranslation("Favorites.modal.newName")}</p>
                        <InputField
                            className={S.inputField}
                            type="text"
                            defaultValue={oldListName}
                            onChange={handleInputChange}
                            placeholder="List Name"
                        />
                        <p>{getTranslation("Favorites.modal.notEmpty")}</p>
                    </div>,
                    {
                        size: ModalSize.SM,
                        shouldCloseOnOverlayClick: true,
                        shouldCloseOnEsc: true,
                        title: `${getTranslation("Button.rename.heading")}`,
                        actionButton: {
                            label: `${getTranslation("button.rename")}`,
                            type: ModalButtonType.SAVE,
                            showToaster: true,
                            onClick: handleConfirmRename,
                        },
                        cancelButton: {
                            label: `${getTranslation("button.cancel")}`,
                            onClick: closeModal,
                        },
                    }
                );
            }
        };

        showModal(
            <div>
                <p>{getTranslation("Favorites.newName")}</p>
                <InputField
                    className={S.inputField}
                    type="text"
                    defaultValue={oldListName}
                    onChange={handleInputChange}
                    placeholder={getTranslation("Favorites.listName")}
                />
                {errorMessage && <p className={S.errorMessage}>{errorMessage}</p>}
            </div>,
            {
                size: ModalSize.SM,
                shouldCloseOnOverlayClick: true,
                shouldCloseOnEsc: true,
                title: `${getTranslation("button.rename.heading")}`,
                actionButton: {
                    label: `${getTranslation("Button.rename")}`,
                    type: ModalButtonType.SAVE,
                    showToaster: true,
                    onClick: handleConfirmRename,
                },
                cancelButton: {
                    label: `${getTranslation("Button.cancel")}`,
                    onClick: closeModal,
                },
            }
        );
    };

    const selectedList = favorites?.find((list) => list.id === selectedListId) || favorites?.[0];

    const basketPath = getRoutePathByPageType("Basket") || "/basket";

    // Add all to basket function
    const handleAddAllToBasket = async () => {
        if (!selectedList) return;

        try {
            const itemsToAdd = selectedList.items.map((item) => ({
                productId: item.id.toString(),
                quantity: quantities[item.id], // Use the quantity from the state
            }));
            await addMultipleToBasket(itemsToAdd);
            const totalQuantity = calculateTotalQuantity(); // Calculate total quantity
            showToast(
                <>
                    {totalQuantity}{" "}
                    {totalQuantity > 1
                        ? getTranslation("general.toastMessage.items")
                        : getTranslation("general.toastMessage.item")}{" "}
                    {getTranslation("general.toastMessage.addedTo")}{" "}
                    <Link to={basketPath}>{getTranslation("general.toastMessage.yourBasket")}</Link>
                </>
            );

            {
                getTranslation("Favorites.totalAdded", {
                    totalQuantity: totalQuantity,
                });
            }

            // Disable the button for 3 seconds
            setIsAddAllButtonDisabled(true);
            setTimeout(() => {
                setIsAddAllButtonDisabled(false);
            }, 3000);
        } catch (error) {
            console.error("Failed to add items to basket:", error);
        }
    };

    const handleProductFound = async (productId: string) => {
        if(selectedListId) {
            await addProductToFavorites(productId, [selectedListId]);
            showToast(
                `${getTranslation("Favorites.toastMessage.updated")}`,
                IconNames.Favorites
              );
        }
    }

    // Calculate total quantity function
    const calculateTotalQuantity = () => {
        return (
            selectedList?.items.reduce((total, item) => total + (quantities[item.id] || 0), 0) || 0
        );
    };

    // Ensure selectedList is defined
    useEffect(() => {
        if (selectedList?.items.length === 0) {
            setIsAddAllButtonDisabled(true);
        } else {
            setIsAddAllButtonDisabled(false);
        }
    }, [selectedList]);

    const handleListClick = (listId: string) => {
        setSelectedListId(listId);
        navigate(`?listId=${listId}`);
        toggleDropdown(); //close dropdown after choosing favorite list
    };

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    //Close doopdowm if clicking outside
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className={S.wrapper}>
            <div className={S.favoriteList}>
                <div>
                    <Button
                        iconName={IconNames.Plus}
                        type="submit"
                        variant="primary"
                        iconPlacement="left"
                        className={S.addNewListBtn}
                        onClick={newFavoriteList}
                    >
                        {getTranslation("favorites.newList")}
                    </Button>
                </div>

                <div className={S.favListWrapper} ref={dropdownRef}>
                    {selectedList && (
                        <div
                            role="button"
                            onClick={toggleDropdown}
                            className={`${S.favListWrapperBtn} ${isOpen ? S.favListWrapperShow : ""}`}
                        >
                            <div className={cn(S.favListBoxItem)}>
                                <p>{getTranslation("favorites.selectFavoritList")}</p>
                                <div className={S.selectedList}>
                                    <div className={S.favListBoxItemTitle}>{selectedList.name}</div>
                                    <SvgIcon iconName={IconNames.Smallarrowright} />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={`${S.dropdownContent} ${isOpen ? S.show : ""}`}>
                        <div className={S.favListBox}>
                            {favorites && favorites?.length > 0 ? (
                                favorites?.map((list) => (
                                    <div
                                        key={list.id}
                                        className={cn(S.favListBoxItem, {
                                            [S.favListBoxItemActive]: list.id === selectedListId,
                                        })}
                                        onClick={() => handleListClick(list.id)}
                                    >
                                        <div className={S.favListBoxItemTitle}>{list.name}</div>
                                        <span className={S.favListBoxItemAmount}>
                                            {list.items.length}{" "}
                                            {list.items.length === 1
                                                ? getTranslation("Favorites.itemNumber")
                                                : getTranslation("Favorites.itemNumbers")}
                                        </span>
                                        {list.id === selectedListId && (
                                            <>
                                                <span className={S.activeArrowIcon}>
                                                    <SvgIcon iconName={IconNames.Smallarrowright} />
                                                </span>
                                                <span className={S.activeCheckmarkIcon}>
                                                    <SvgIcon iconName={IconNames.Checkmark} />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <EmptyState heading={getTranslation("Favorites.noListsAvalible")} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {selectedList ? (
                    <>
                        {/* Existing selectedList logic */}
                        <div className={S.favItemsWrapperTitle}>
                            <h1>{selectedList.name}</h1>
                            <Button
                                variant="full-transparent"
                                className={S.titleEditBtn}
                                onClick={() =>
                                    handleRenameFavoritesList(selectedList.id, selectedList.name)
                                }
                            >
                                <SvgIcon iconName={IconNames.Pencil} />
                            </Button>
                            <Button
                                variant="full-transparent"
                                className={S.titleDeleteBtn}
                                onClick={() =>
                                    handleDeleteFavoritesList(selectedList.id, selectedList.name)
                                }
                            >
                                <SvgIcon iconName={IconNames.Trashcan} />
                            </Button>
                        </div>

                        <div className={S.favListItemsWrapper}>
                            <span className={S.favListBoxItemAmountMobile}>
                                {selectedList.items.length}{" "}
                                {selectedList.items.length === 1
                                    ? getTranslation("Favorites.itemNumber")
                                    : getTranslation("Favorites.itemNumbers")}
                            </span>

                            <div className={S.favItemsWrapperMisc}>
                                <Button
                                    variant="clear"
                                    size="small"
                                    className={S.addAllToBasketBtn}
                                    iconName={IconNames.Basket}
                                    type="submit"
                                    iconPlacement="left"
                                    onClick={handleAddAllToBasket}
                                    disabled={
                                        isAddAllButtonDisabled || selectedList?.items.length === 0
                                    }
                                >
                                    <span>{getTranslation("button.AddAll")}</span>
                                </Button>

                                <AddItemNumber onSuccess={handleProductFound} />
                            </div>
                        </div>
                        {selectedList.items.length > 0 ? (
                            <div className={S.favItemsWrapperList}>
                                <ProductList_List
                                    products={{
                                        items: selectedList.items,
                                        totalCount: selectedList.items.length,
                                    }}
                                    path={selectedList.name}
                                    showTotalCount={false}
                                    onGetQuantities={setQuantities}
                                />
                            </div>
                        ) : (
                            <EmptyState
                                heading={getTranslation("general.emptyState.favoriteList.heading", {
                                    text: selectedList.name,
                                })}
                                description={getTranslation(
                                    "general.emptyState.favorites.description"
                                )}
                                iconName={IconNames.Favorites}
                            />
                        )}
                    </>
                ) : favorites && favorites?.length > 0 ? (
                    <EmptyState
                        heading={getTranslation("general.emptyState.favoriteList.heading")}
                        description={getTranslation("general.emptyState.favorites.description")}
                        iconName={IconNames.Favorites}
                    />
                ) : (
                    <EmptyState
                        heading={getTranslation("general.emptyState.favoriteList.noFavoriteList")}
                        description={getTranslation(
                            "general.emptyState.favoriteList.noFavoriteList.description"
                        )}
                        iconName={IconNames.Favorites}
                    />
                )}
            </div>
        </div>
    );
};
