// src/helpers/apis/fetchContent.ts
import { apiGet } from "./apiHelper";

export interface ContentApiResponse {
    id: string;
    name: string;
    type: string;
    culture: string;
    content: Record<string, any>;
}

export const fetchContent = async (
    url: string,
    signal?: AbortSignal
): Promise<{ data: ContentApiResponse; statusCode: number }> => {
    const relativePath = `/api/content/GetContentByUrl?url=${encodeURIComponent(
        url
    )}&preview=false`;
    return await apiGet<ContentApiResponse>(relativePath, { signal });
};
