import React, { ReactElement } from "react";
import { Container } from "@shared/components/Atoms/Container/Container.component";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";
import { Sidebar } from "@shared/components/Molecules/Sidebar/Sidebar.component";
import { usePage } from "@shared/context/PageContext";
import S from './FrontendPage.module.scss';

interface FrontendpageProps extends React.PropsWithChildren {
    name?: string;
    metaTitle?: string;
    metaDescription?: string;
    noIndex?: boolean;
    noFollow?: boolean;
    hideSidebar?: boolean;
    children: ReactElement;
}

export const FrontendPage: React.FC<FrontendpageProps> = ({ 
    name,
    metaTitle,
    metaDescription,
    noIndex,
    noFollow,
    hideSidebar,
    children 
}) => {
    const { isSidebarOpen, setIsSidebarOpen } = usePage();

    return (
        <Container>
            <Helmet>
                <title>{metaTitle || `HMF - ${name}`}</title>
                <meta name="description" content={metaDescription || "Default description"} />
                {noIndex && <meta name="robots" content="noindex" />}
                {noFollow && <meta name="robots" content="nofollow" />}
            </Helmet>
            <ErrorBoundary>
                <div className={S.flexRow}>
                    {!hideSidebar ? (
                        <div className={S.sidebar}>
                            <Sidebar
                                isOpen={isSidebarOpen || false}
                                onClose={() => setIsSidebarOpen(false)}
                            />
                        </div>
                    ) : null}
                    <div className={S.content}>
                        {children}
                    </div>
                </div>
            </ErrorBoundary>
        </Container>
    );
};
