import ReactDOMServer from "react-dom/server";
import React, { ReactNode } from "react";
import { Tooltip } from "react-tooltip";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import S from "./Button.module.scss";

interface ButtonProps {
    type?: "button" | "submit" | "reset";
    onClick?: () => void;
    disabled?: boolean;
    size?: "small" | "medium" | "large" | "fitIcon";
    variant?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "transparent"
        | "full-transparent"
        | "basket"
        | "clear"
        | "pill";
    className?: string;
    ariaLabel?: string;
    children: React.ReactNode;
    iconName?: IconNames | string;
    iconPlacement?: "top" | "bottom" | "left" | "right";
    round?: boolean;
    tooltipContent?: ReactNode; // Tooltip content
    tooltipId?: string; // Tooltip ID
    showTip?: boolean;
    tooltipPlace?: "top" | "bottom" | "left" | "right";
    tabIndex?: number;
    full?: boolean;
    pillSmall?: boolean;
    color?: "white" | "red";
}

const Button: React.FC<ButtonProps> = ({
    type = "button",
    onClick,
    disabled,
    size = "medium",
    variant = "primary",
    className,
    round,
    children,
    tooltipContent,
    tooltipId,
    showTip,
    tooltipPlace = "top",
    ariaLabel,
    tabIndex,
    iconName,
    iconPlacement,
    full,
    pillSmall,
    color = "blue",
}) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) {
            e.preventDefault();
        } else if (onClick) {
            onClick();
        }
    };

    const getSizeClass = () => {
        switch (size) {
            case "small":
                return "btn-small";
            case "large":
                return "btn-large";
            case "fitIcon":
                return "fit-icon";
            default:
                return "btn-medium";
        }
    };

    const getVariantClass = () => {
        switch (variant) {
            case "secondary":
                return "btn-secondary";
            case "tertiary":
                return "btn-tertiary";
            case "transparent":
                return "btn-transparent";
            case "full-transparent":
                return "btn-full-transparent";
            case "basket":
                return "btn-basket";
            case "clear":
                return "btn-clear";
            case "pill":
                return "btn-pill";
            default:
                return "btn-primary";
        }
    };

    const getIconClass = () => {
        switch (iconPlacement) {
            case "top":
                return "icon-top";
            case "bottom":
                return "icon-bottom";
            case "right":
                return "icon-right";
            default:
                return "icon-left";
        }
    };

    const getColorClass = () => {
        switch (color) {
            case "white":
                return "white";
            case "red":
                return "red";
            default:
                return "blue";
        }
    };

    const tooltipContentString = tooltipContent
        ? ReactDOMServer.renderToString(<>{tooltipContent}</>)
        : "";

    return (
        <>
            <button
                type={type}
                onClick={handleClick}
                disabled={disabled}
                className={`${S[getSizeClass()]} ${S[getVariantClass()]} ${S[getIconClass()]} ${S[getColorClass()]} ${className} ${S.button} ${
                    round && S.roundBtn
                } ${disabled && S.disabled} ${full && S.full} 
                ${pillSmall && S.pillSmall}`}
                data-tooltip-id={tooltipId}
                data-tooltip-html={tooltipContentString}
                aria-disabled={disabled}
                aria-label={ariaLabel}
                tabIndex={tabIndex}
            >
                {iconName ? <SvgIcon iconName={iconName} /> : null}
                {children}
            </button>
            {disabled && tooltipId && tooltipContent && (
                <Tooltip id={tooltipId} place={tooltipPlace}>
                    <div dangerouslySetInnerHTML={{ __html: tooltipContentString }} />
                </Tooltip>
            )}
            {showTip && tooltipId && tooltipContent && (
                <Tooltip id={tooltipId} place={tooltipPlace} className={S.tooltip}>
                    <div dangerouslySetInnerHTML={{ __html: tooltipContentString }} />
                </Tooltip>
            )}
        </>
    );
};
export default Button;
