import S from "../Specifications/Specifications.module.scss";
import { useTranslations } from "@shared/context/useTranslations";
import { Product } from "@shared/types/ProductTypes.interface";

export const Specifications = ({ specifications }: Product) => {
    const { getTranslation } = useTranslations();

    return (
        <div className={S.specifications}>
            <h3>{getTranslation("pdp.headings.specifications")}</h3>
            <ul>
                {specifications.map((specification, index) => {
                    if (specification.value === "") return;
                    if (specification.value === "0") return;

                    return (
                        <li key={index}>
                            <p>{specification.label}</p>
                            <p>{specification.value}</p>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
