// src/components/Atoms/Toast/Toast.component.tsx
import React, { ReactNode, useEffect } from "react";
import S from "./Toast.module.scss";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";

interface ToastProps {
    message: ReactNode;
    onClose: () => void;
    iconName?: IconNames | string;
}

const Toast: React.FC<ToastProps> = ({ message, onClose, iconName }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 8000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={S.toast}>
            {iconName ? (
                <SvgIcon iconName={iconName} />
            ) : (
                <SvgIcon iconName={IconNames.Addedtocart} /> //Default icon
            )}
            <span>{message}</span>
        </div>
    );
};

export default Toast;
