// src/hooks/useContent.ts
import { useGenericApi } from "./useGenericApi";
import { fetchContent } from "../helpers/apis/apiGetContent";

interface ContentApiResponse {
    id: string;
    name: string;
    type: string;
    culture: string;
    content: Record<string, any>;
}

export const useContent = (url: string) => {
    const isLoginPage = /^\/login(\?.*)?$/.test(url.toLowerCase());

    return useGenericApi<{ data: ContentApiResponse; statusCode: number }, unknown>(
        ["content", url],
        () => fetchContent(url),
        {
            enabled: !isLoginPage, // Disable query if it's the login page
            select: (response) => {
                return {
                    data: response.data,
                    statusCode: response.statusCode,
                };
            },
        }
    );
};
