// src/components/ModuleList/ModuleList.tsx
import { DynamicBlock } from "../../Blocks/DynamicBlock/DynamicBlock.component"; // Ensure correct import
import { ErrorBoundary } from "../../Organisms/ErrorBoundry/ErrorBoundary";
import S from "./ModuleList.module.scss";

interface ModuleListProps {
    modules: any[];
}

export const ModuleList = ({ modules }: ModuleListProps) => {
    return (
        <div className={S.moduleList}>
            {modules.map((module) => (
                <div className={S.wrapper} key={module.content.id}>
                    <ErrorBoundary>
                        <DynamicBlock content={module.content} settings={module?.settings} />
                    </ErrorBoundary>
                </div>
            ))}
        </div>
    );
};
