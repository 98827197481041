import { createContext, ReactNode, useContext } from "react";
import { useMediaQuery } from "react-responsive";

export enum ScreenSizes {
    XXS = 1,
    XS = 512,
    SM = 768,
    MD = 992,
    LG = 1220,
    XL = 1540,
    XXL = 1840,
}

interface ScreenState {
    isScreenSmall: boolean;
    isScreenMedium: boolean;
    isScreenLarge: boolean;
    isScreenXLarge: boolean;
}

interface Props {
    children: ReactNode;
}

const ScreenContext = createContext<ScreenState>({} as ScreenState);

export const ScreenProvider = ({ children }: Props) => {
    const isScreenSmall = useMediaQuery({ minWidth: 0, maxWidth: 768 });
    const isScreenMedium = useMediaQuery({ minWidth: 769, maxWidth: 992 });
    const isScreenLarge = useMediaQuery({ minWidth: 993, maxWidth: 9999 });
    const isScreenXLarge = useMediaQuery({ minWidth: 1441, maxWidth: 9999 });

    return (
        <ScreenContext.Provider
            value={{ isScreenSmall, isScreenMedium, isScreenLarge, isScreenXLarge }}
        >
            {children}
        </ScreenContext.Provider>
    );
};

export const useScreen = (): ScreenState => useContext(ScreenContext);
