import React from "react";
import { PriceTableDataProps } from "./PriceTableProps";
import S from "../PriceTable/PriceTable.module.scss";
import { useTranslations } from "@shared/context/useTranslations";
import Button from "@shared/components/Atoms/Button/Button";
import { IconNames } from "@shared/enums/icons.enums";

interface PriceTableProps extends PriceTableDataProps {
    currentQuantity: number;
}

export const PriceTable: React.FC<PriceTableProps> = ({ priceData, currentQuantity }) => {
    const { getTranslation } = useTranslations();

    const getActiveRowIndex = () => {
        let activeIndex = -1;
        priceData.forEach((item, index) => {
            if (item.quantity <= currentQuantity) {
                activeIndex = index;
            }
        });
        return activeIndex;
    };

    const activeRowIndex = getActiveRowIndex();

    return (
        <div className={S.tableContainer}>
            <table>
                <thead>
                    <tr>
                        <th className={S.quantityHeader}>
                            {getTranslation("pdp.price-table.quantity")}
                        </th>
                        <th>{getTranslation("pdp.price-table.gross-price")}</th>
                        <th>{`${getTranslation("pdp.price-table.discount-in-percent")}`}</th>
                        <th>{getTranslation("pdp.price-table.discount-per-unit")}</th>
                        <th>{getTranslation("pdp.price-table.net-price")}</th>
                    </tr>
                </thead>
                <tbody>
                    {priceData.map((item, index) => {
                        return (
                            <tr key={index} className={index === activeRowIndex ? S.activeRow : ""}>
                                <td className={S.quantityColumn}>
                                    {`${item.quantity} ${getTranslation("pdp.qty")}`}
                                </td>
                                <td>{item.grossPrice}</td>
                                <td className={S.pill}>
                                    <Button
                                        className={index === activeRowIndex ? S.activePill : ""}
                                        iconName={IconNames.Info}
                                        tooltipContent={
                                            <p>
                                                {getTranslation(
                                                    "pdp.price-table.tooltip.discount",
                                                    {
                                                        customerDiscount: `${priceData[0].discount}%`,
                                                    }
                                                )}
                                                {item.quantity > 1 ? " " : "."}
                                                {item.quantity > 1
                                                    ? `${getTranslation(
                                                          "pdp.price-table.tooltip.totalDiscount",
                                                          {
                                                              quantityDiscount: `${item.percent}%`,
                                                              totalDiscount: `${item.discount}%`,
                                                          }
                                                      )}`
                                                    : ""}
                                            </p>
                                        }
                                        tooltipId="discount-tooltip"
                                        showTip
                                        tooltipPlace="top"
                                        variant="pill"
                                        pillSmall
                                        color="red"
                                    >
                                        <span>{`${item.discount}%`}</span>
                                    </Button>
                                </td>

                                <td className={S.discountPerUnit}>{item.discountPerUnit}</td>
                                <td>{item.netPrice}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
