import apiClient from "./apiClient";
import axios, { AxiosError } from "axios";
// import { isLocalhost } from "../../config/config";

// Define a type guard to check if an error is an AxiosError
const isAxiosError = (error: unknown): error is AxiosError => {
    return axios.isAxiosError(error);
};

// Function to handle redirection if not on localhost
// const handleUnauthorizedError = () => {
//     if (!isLocalhost) {
//         window.location.href = window.location.pathname;
//     }
// };

// Generic GET function
export const apiGet = async <T>(
    url: string,
    options?: { signal?: AbortSignal }
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.get<T>(url, { signal: options?.signal });
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            // Return error status code and empty data
            return { data: {} as T, statusCode: error.response.status };
        }
        throw error;
    }
};

// Generic POST function
export const apiPost = async <T, R = undefined>(
    url: string,
    body?: R,
    options?: { signal?: AbortSignal }
): Promise<T> => {
    try {
        const response = await apiClient.post<T>(url, body, {
            signal: options?.signal,
        });
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};

// Generic PUT function
export const apiPut = async <T>(url: string, body: unknown): Promise<T> => {
    const response = await apiClient.put<T>(url, body);
    return response.data;
};

// Generic DELETE function
// export const apiDelete = async <T>(url: string): Promise<T> => {
//     const response = await apiClient.delete<T>(url);
//     return response.data;
// };

// Generic POST with FormData function
export const apiPostFormData = async <T>(url: string, formData: FormData): Promise<T> => {
    const response = await apiClient.post<T>(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
};

// New function to update quantity
export const apiPatch = async <T>(
    url: string,
    body: unknown
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.patch<T>(url, body);
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            return { data: {} as T, statusCode: error.response.status };
        }
        throw error;
    }
};

// New function to delete an item
export const apiDelete = async <T>(url: string): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.delete<T>(url);
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            return { data: {} as T, statusCode: error.response.status };
        }
        throw error;
    }
};
