import React, { useEffect, useRef, useState, ReactNode } from "react";
import { HeaderIcon } from "../HeaderIcon/HeaderIcon.component";
import S from "../HeaderDropdown/HeaderDropdown.module.scss";
import { FlagNames } from "@shared/enums/flag.enums";

interface HeaderDropdownProps extends React.PropsWithChildren {
    title: string | ReactNode;
    iconName?: string;
    tabIndex?: number;
    children?: ReactNode[];
    onToggle?: (name: string, isOpen: boolean) => void;
    onClose?: () => void;
    subtitle?: string | ReactNode;
    items?: {
        icon: FlagNames;
        text: string;
        link: string;
        ariaLabel: string;
    }[];
}

export const HeaderDropdown = ({
    title,
    iconName,
    children,
    subtitle,
    tabIndex,
    onClose,
}: HeaderDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === "") {
            event.preventDefault();
            toggleDropdown();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleItemClick = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div ref={dropdownRef} className={S.dropdown}>
            <div
                onClick={toggleDropdown}
                onKeyDown={handleKeyDown}
                className={`${S.headerIconWrapper} ${isOpen ? S.headerIconWrapperShow : ""}`}
                tabIndex={tabIndex}
            >
                <HeaderIcon iconName={iconName} text={title} subtitle={subtitle} />
            </div>
            <div
                className={`${S.dropdownContent} ${isOpen ? S.show : ""}`}
                onClick={handleItemClick}
            >
                {children}
            </div>
        </div>
    );
};
