/* eslint-disable no-unused-vars */
export enum IconNames {
  Basket = "Basket",
  Favorites = "Favorites",
  Warning = "Warning",
  Globe = "Globe",
  History = "History",
  Profile = "Profile",
  Quickorder = "Quickorder",
  OrderHistory = "OrderHistory",
  Close = "Close",
  Search = "Search",
  Menu = "Menu",
  Chevron = "Chevron",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Linkedin = "Linkedin",
  Youtube = "Youtube",
  Backicon = "Backicon",
  Smallarrowright = "Smallarrowright",
  Heart = "Heart",
  Heartfull = "Heartfull",
  Basketleft = "Basketleft",
  Discount = "Discount",
  Info = "Info",
  Trashcan = "Trashcan",
  Quickordertable = "Quickordertable",
  Barcode = "Barcode",
  Register = "Register",
  Clipboard = "Clipboard",
  Addedtocart = "Addedtocart",
  Plus = "Plus",
  Pencil = "Pencil",
  Externallink = "Externallink",
  Checkmark = "Checkmark",
  Fallback = "Fallback",
}
