/* eslint-disable react/prop-types */
import { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { usePage } from "../../../context/PageContext";
import { Container } from "../../Atoms/Container/Container.component";
import { HeroList } from "../../Templates/HeroList/HeroList.component";
import { ModuleList } from "../../Templates/ModuleList/ModuleList.component";
import { ErrorPage } from "../ErrorPage/ErrorPage.component";
import { ErrorBoundary } from "../../Organisms/ErrorBoundry/ErrorBoundary";
import { cn } from "../../../helpers/classNames.helper";
import { FrontendPage } from "../FrontendPage/FrontendPage.component";
import { SearchResults } from "@shared/components/Organisms/SearchResults/SearchResults.component";
import { useTranslations } from "@shared/context/useTranslations";
import { CategoryPageProps } from "@shared/types/CategoryTypes.interface";
import { ProductPageProps } from "@shared/types/ProductTypes.interface";
import { ReceiptPageProps } from "@shared/types/ReceiptType.interface";
import ProductList from "../ProductList/ProductList.component";
import ProductItem from "../Productitem/ProductItem.component";
import Basket from "../Basket/Basket.component";
import QuickOrder from "../QuickOrder/QuickOrder.component";
import S from "./DynamicPage.module.scss";
import { Favorites } from "../Favorites/Favorites.component";
import UserAccount from "../UserAccount/UserAccount.component";
import { Checkout } from "../Checkout/Checkout.component";
import { Receipt } from "../Receipt/Receipt.component";
import { OrderHistory } from "../OrderHistory/OrderHistory.component";

export const DynamicPage = () => {
    const { page, isLoading, error, fetchPage, setPage, statusCode } = usePage();
    const location = useLocation();
    const lastCheckedPageRef = useRef<string>("");
    const { getTranslation } = useTranslations();

    // Ensure fetchPage is only called when pathname changes
    const checkPage = useCallback(
        async (pathname: string) => {
            if (lastCheckedPageRef.current !== pathname) {
                lastCheckedPageRef.current = pathname;
                await fetchPage(pathname);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchPage, setPage]
    );

    // Watch for changes in location.pathname
    useEffect(() => {
        checkPage(location.pathname);
    }, [checkPage, location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const renderBody = () => {
        if (error || statusCode === 404) {
            return (
                <Container>
                    <ErrorPage />
                </Container>
            );
        }
        if (!page) {
            return (
                <Container>
                    <div>{getTranslation("general.no-content-found")}</div>
                </Container>
            );
        }

        const { name, props } = page;

        return (
            <FrontendPage {...page}>
                <Fragment>
                    <div className={S.title}>
                        <h2>{name}</h2>
                    </div>
                    <ErrorBoundary>
                        {props?.heroList?.items && <HeroList heroLists={props.heroList.items} />}
                    </ErrorBoundary>

                    <ErrorBoundary>
                        {props?.modules && <ModuleList modules={props.modules.items} />}
                    </ErrorBoundary>
                </Fragment>
            </FrontendPage>
        );
    };

    const getPageComponents = (): Record<string, ReactNode> => {
        return {
            ContentPage: renderBody(),
            ProductPage: (
                <FrontendPage {...page}>
                    <ProductItem {...(page as ProductPageProps)} />
                </FrontendPage>
            ),
            CategoryPage: (
                <FrontendPage {...page}>
                    <ProductList {...(page as CategoryPageProps)} />
                </FrontendPage>
            ),
            Search: (
                <FrontendPage {...page}>
                    <SearchResults />
                </FrontendPage>
            ),

            // TODO: build these pages
            UserAccount: (
                <FrontendPage {...page}>
                    <UserAccount />
                </FrontendPage>
            ),
            UserPricelist: (
                <FrontendPage {...page}>
                    <h1>Price list</h1>
                </FrontendPage>
            ),
            Favorites: (
                <FrontendPage {...page} hideSidebar>
                    <Favorites />
                </FrontendPage>
            ),
            Checkout: (
                <Container>
                    <ErrorBoundary>
                        <Checkout />
                    </ErrorBoundary>
                </Container>
            ),
            Orderhistory: (
            <FrontendPage {...page} hideSidebar>
                <OrderHistory />
            </FrontendPage>
            ),
            Quickorder: (
                <Container>
                    <ErrorBoundary>
                        <QuickOrder />
                    </ErrorBoundary>
                </Container>
            ),
            Receipt: (
                <Container>
                    <ErrorBoundary>
                        <Receipt {...(page as unknown as ReceiptPageProps)} />
                    </ErrorBoundary>
                </Container>
            ),
            Basket: (
                <Container>
                    <ErrorBoundary>
                        <Basket />
                    </ErrorBoundary>
                </Container>
            ),

            "404": <ErrorPage />,
        };
    };

    // TODO: check all titles on frontendPages (basket, quick order)

    const getPageType = () => {
        // Special pages
        if (page?.type === "frontendPage") {
            const pageType = page?.props?.pageType || (statusCode === 404 ? "404" : "ErrorPage");
            return pageType.replace(" ", "");
        }

        // PLP, PDP, Content pages
        return page?.pageType || (statusCode === 404 ? "404" : "ErrorPage");
    };

    const pageComponents = getPageComponents();
    const pageType = getPageType();

    return (
        <Fragment>
            <div className={cn(S.bodyPage, { [S.blur]: isLoading })}>
                {pageComponents[pageType]}
            </div>
        </Fragment>
    );
};

export default DynamicPage;
