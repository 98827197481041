import S from "./Spinner.module.scss";

interface SpinnerProps {
    className?: string;
    loadingHeading?: string;
}

export const Spinner = ({ className, loadingHeading }: SpinnerProps) => {
    return (
        <div className={S.loadingBar}>
            <div className={`${S.spinner} ${className}`}></div>
            <h4 className={S.loadingHeading}>{loadingHeading}</h4>
        </div>
    );
};
