import React, { ErrorInfo, ReactNode } from "react";
import S from "./ErrorBoundary.module.scss";

//TODO: Add translations

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

const isLocalhost = () => {
    return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });
        if (isLocalhost()) {
            console.error("ErrorBoundary caught an error", error, errorInfo);
        }
    }

    render() {
        if (this.state.hasError && isLocalhost()) {
            return (
                <div className={S.errorBox}>
                    <p className={S.errorMessage}>Something went wrong.</p>
                    <div className={S.devErrorInfo}>
                        <p>{this.state.error?.toString()}</p>
                        <pre>{this.state.errorInfo?.componentStack}</pre>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
