import apiClient from "./apiClient";
import axios, { AxiosError } from "axios";

const isAxiosError = (error: unknown): error is AxiosError => {
    return axios.isAxiosError(error);
};

export const basketApiGet = async <T>(
    url: string,
    options?: { signal?: AbortSignal }
): Promise<{ data: T; statusCode: number }> => {
    try {
        const response = await apiClient.get<T>(url, { signal: options?.signal });
        return { data: response.data, statusCode: response.status };
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        }
        throw error;
    }
};
