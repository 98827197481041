// src/components/SearchResults.tsx

import { useSearch } from "@shared/context/SearchContext";
import { ProductList_Filter } from "@shared/components/Pages/ProductList/components/Filter/Filter.component";
import { ProductList_List } from "@shared/components/Pages/ProductList/components/List/List.component";
import Pagination from "@shared/components/Pages/ProductList/components/Pagination/Pagination.component";
import { useTranslations } from "@shared/context/useTranslations";
import { Spinner } from "@shared/components/Atoms/Spinner/Spinner.component";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import { IconNames } from "@shared/enums/icons.enums";
import S from "./SearchResults.module.scss";

export const SearchResults = () => {
    const { isLoading, query, products, pageNumber, setPageNumber, pageSize, setPageSize } =
        useSearch();
    const { getTranslation } = useTranslations();

    const handlePages = (updatePage: number) => {
        setPageNumber(updatePage);
    };

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setPageNumber(1); //reset to page 1 when page size changes
    };

    return (
        <div>
            <h2>{getTranslation("search.heading", { query: query })}</h2>
            {isLoading ? (
                <div className={S.loadingBar}>
                    <Spinner />
                    <h4>{getTranslation("search.loading-heading")}</h4>
                </div>
            ) : (
                <>
                    {products ? (
                        <div className={S.searchSubheading}>
                            {getTranslation("search.subheading")}

                            <p>
                                {getTranslation("search.subheading.count", {
                                    count: products.totalCount,
                                })}
                            </p>
                        </div>
                    ) : null}
                    <div className={S.productListWrapper}>
                        <ProductList_Filter />
                        {products && products.count !== undefined && products.count > 0 ? (
                            <div>
                                <ProductList_List products={products || []} />
                                <Pagination
                                    pageSize={pageSize}
                                    pageNumber={pageNumber}
                                    totalPages={products.totalPages!}
                                    handlePagination={handlePages}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={products.totalCount!}
                                />
                            </div>
                        ) : (
                            <div className={S.emptyState}>
                                <EmptyState
                                    iconName={IconNames.Search}
                                    heading={getTranslation("search.no-results")}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
