import React from "react";

interface LinkProps {
    href: string;
    target?: "_self" | "_blank" | "_parent" | "_top";
    ariaLabel?: string;
    rel?: string;
    className?: string;
    style?: React.CSSProperties;
    tabIndex?: number;
    children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({
    href,
    target = "_self",
    ariaLabel,
    rel,
    className,
    style,
    tabIndex,
    children,
}) => {
    const safeRel = target === "_blank" ? "noopener noreferrer" : rel;

    return (
        <a
            href={href}
            target={target}
            aria-label={ariaLabel}
            rel={safeRel}
            className={className}
            style={style}
            tabIndex={tabIndex}
        >
            {children}
        </a>
    );
};

export default Link;
