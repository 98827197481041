import React from "react";
import { useDynamicSvgImport } from "../../../hooks/useDynamicSvgImport";
import { IconNames } from "@shared/enums/icons.enums";
import { cn } from "@shared/helpers/classNames.helper";

interface IProps {
    iconName: string | IconNames;
    wrapperStyle?: string;
    svgProp?: React.SVGProps<SVGSVGElement>;
    className?: string | undefined;
}

export const SvgIcon = (props: IProps) => {
    const { iconName, wrapperStyle, svgProp, className } = props;
    const { loading, SvgIcon } = useDynamicSvgImport(iconName as string);
    return (
        <>
            {loading && <div className="rounded-full bg-slate-400 animate-pulse h-8 w-8"></div>}
            {SvgIcon && <SvgIcon className={cn(wrapperStyle, className)} {...svgProp} />}
        </>
    );
};
