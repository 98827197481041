import ImageWithFallback from "@shared/components/Molecules/ImageWithFallback/ImageWithFallback.component";
import { useTranslations } from "@shared/context/useTranslations";
import { useEffect, useState } from "react";
import { fetchUserAccount } from "@shared/helpers/apis/userAccountHelper";
import InputField from "@shared/components/Atoms/InputField/InputField.component";
import { DeliveryMode, User } from "@shared/types/UserAccountTypes.interface";
import { BasketSummary, fetchBasketSummary } from "@shared/helpers/checkoutHelper";
import { DeliveryOptions, fetchDeliveryOptions } from "@shared/helpers/apis/deliveryOptionsHelper";
import { formatNumber } from "@shared/helpers/formatPrice";
import Button from "@shared/components/Atoms/Button/Button";
import { animated, useTransition } from "react-spring";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import { useNavigate } from "react-router-dom";
import { sendOrder } from "@shared/helpers/apis/apiOrder";
import { useBasket } from "@shared/context/BasketContext";
import { usePage } from "@shared/context/PageContext";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import { Spinner } from "@shared/components/Atoms/Spinner/Spinner.component";
import { IconNames } from "@shared/enums/icons.enums";
import ShowMore from "@shared/components/Molecules/ShowMore/ShowMore.component";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./Checkout.module.scss";
import { CheckoutContent } from "@shared/types/ContentTypes";
import { getThumbnailImageUrl } from "@shared/helpers/apis/productHelper";

export const Checkout = () => {
    const { getTranslation } = useTranslations();
    const [userAccount, setUserAccount] = useState<User | null>(null);
    const [basketSummary, setBasketSummary] = useState<BasketSummary | null>(null);
    const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions | null>(null);
    const [defaultDeliveryMode, setDefaultDeliveryMode] = useState<DeliveryMode | null>(null);
    const [activeDeliveryMode, setActiveDeliveryMode] = useState<string | null>(null);
    const [checkoutComment, setCheckoutComment] = useState<string>("");
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(true);
    const [basketId, setBasketId] = useState("");
    const [requisitionNumber, setRequisitionNumber] = useState("");
    const [customerReference, setCustomerReference] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [alternativeAddress, setAlternativeAddress] = useState({
        streetName: "",
        postalCode: "",
        cityName: "",
        stateName: "",
        countryId: "",
        name: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { refetchBasket } = useBasket();
    const { page } = usePage();

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [submitError, setSubmitError] = useState<string>("");
    const [deliveryError, setDeliveryError] = useState<string>("");

    const [hidePostalCode, setHidePostalCode] = useState<boolean>(false);
    const [showState, setShowState] = useState<boolean>(false);

    const receiptPath = getRoutePathByPageType("Receipt") || "/receipt";

    const termsConditionsPath =
        (page as CheckoutContent).props?.termsConditions?.route.path || "/termscondition-page";
    const navigate = useNavigate();

    // @ts-expect-error
    const customer = globalThis?.customer;
    const customerNumber = customer?.customerNumber;
    const customerEmail = customer?.customerEmail || "";

    // @ts-expect-error
    const currentCulture = globalThis?.currentCulture;

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                //Fetch user
                const userAccountData = await fetchUserAccount();
                setUserAccount(userAccountData);

                //Fetch all delivery options
                const deliveryData = await fetchDeliveryOptions(currentCulture);
                setDeliveryOptions(deliveryData);

                //Default delivery based on user
                const defaultMode = userAccountData.defaultDeliveryMode;
                setDefaultDeliveryMode(defaultMode);

                if (defaultMode) {
                    const matchingDeliveryCode = Object.entries(deliveryData || {}).find(
                        ([key, value]) => key && value === defaultMode.deliveryCodeDescription
                    )?.[0];
                    if (matchingDeliveryCode) {
                        setActiveDeliveryMode(matchingDeliveryCode);
                    }
                }

                //Fetch basket
                const basketData = await fetchBasketSummary(customerNumber, currentCulture);
                setBasketSummary(basketData);
                setBasketId(basketData.id);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch data", error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Place default delivery at top
    const getDeliveryOptionsList = () => {
        if (!deliveryOptions) return [];

        const orderedOptions = Object.entries(deliveryOptions).sort(([keyA], [keyB]) => {
            if (defaultDeliveryMode?.deliveryCode === keyA) return -1;
            if (defaultDeliveryMode?.deliveryCode === keyB) return 1;
            return 0;
        });
        return orderedOptions;
    };

    const handleDeliveryModeClick = (deliveryCode: string) => {
        setActiveDeliveryMode(deliveryCode);
        setDeliveryError("");

        // Clear the specific field error when the user starts typing
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors["deliveryMode"];

            // If there are no more errors, clear the submitError as well
            if (Object.keys(newErrors).length === 0) {
                setSubmitError("");
            }

            return newErrors;
        });
    };

    useEffect(() => {
        if (userAccount) {
            if (
                userAccount.invoiceAddress.countryId === "HKG" ||
                userAccount.invoiceAddress.countryId === "ARE"
            ) {
                setHidePostalCode(true);
            }
            if (userAccount.invoiceAddress.stateName) {
                setShowState(true);
            }
        }
    }, [userAccount]);

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckoutComment(e.target.value);
    };

    const handleRequisitionNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setRequisitionNumber(value);

        // Clear the specific field error when the user starts typing
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value.trim()) {
                delete newErrors["requisitionNumber"]; // Clear the error if the field is not empty
            }

            // If there are no more errors, clear the submitError as well
            if (Object.keys(newErrors).length === 0) {
                setSubmitError("");
            }

            return newErrors;
        });
    };

    const handleCustomerReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCustomerReference(value);
    };

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermsAccepted(e.target.checked);
    };

    const handleAlternativeAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Make sure to have country set on alternative address because its readonly
        setAlternativeAddress({
            ...alternativeAddress,
            [name]: value,
            ["countryId"]: userAccount?.invoiceAddress.countryId || "",
        });

        // Clear the specific field error when the user starts typing
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];

            // If there are no more errors, clear the submitError as well
            if (Object.keys(newErrors).length === 0) {
                setSubmitError("");
            }

            return newErrors;
        });
    };

    const handleCheckout = async () => {
        if (isSubmitting) return;

        const validationErrors: { [key: string]: string } = {};

        if (!isSameAddressChecked) {
            if (!alternativeAddress.streetName) {
                validationErrors.streetName = getTranslation("checkout.errorStreetName");
            }
            if (!hidePostalCode && !alternativeAddress.postalCode) {
                validationErrors.postalCode = getTranslation("checkout.errorPostalCode");
            }
            if (!alternativeAddress.cityName) {
                validationErrors.cityName = getTranslation("checkout.errorCityName");
            }
            if (!alternativeAddress.name) {
                validationErrors.name = getTranslation("checkout.errorAltCompanyName");
            }
            if (showState && !alternativeAddress.stateName) {
                validationErrors.stateName = getTranslation("checkout.errorStateName");
            }
            // if (!alternativeAddress.countryId) {
            //     validationErrors.countryId = getTranslation("checkout.errorCountryId");
            // }
        }

        if (!requisitionNumber) {
            validationErrors.requisitionNumber = getTranslation("checkout.errorRequisitionNumber");
        }

        if (!activeDeliveryMode) {
            validationErrors.deliveryMode = getTranslation("checkout.errorDeliveryMode");
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setSubmitError(getTranslation("checkout.fixErrors"));
            if (validationErrors.deliveryMode) {
                setDeliveryError(validationErrors.deliveryMode);
            }
            return;
        }

        setErrors({});
        setSubmitError("");
        setDeliveryError("");

        const checkoutData = {
            basketId,
            customerEmail,
            deliveryCode: activeDeliveryMode,
            comment: checkoutComment,
            requisitionNumber: requisitionNumber,
            customerRef: customerReference,
            useInvoiceAddress: isSameAddressChecked,
            address: isSameAddressChecked
                ? undefined
                : {
                      cityName: alternativeAddress.cityName,
                      name: alternativeAddress.name,
                      streetName: alternativeAddress.streetName,
                      zipCodeId: alternativeAddress.postalCode,
                      stateName: alternativeAddress.stateName,
                      countryId: alternativeAddress.countryId,
                  },
        };

        // Set isSubmitting to true when the order process starts
        setIsSubmitting(true);

        try {
            await sendOrder(checkoutData, currentCulture);
            navigate(receiptPath);
            refetchBasket();
        } catch (error) {
            console.error("Failed to send order:", error);
            setSubmitError(getTranslation("checkout.submitError"));
        } finally {
            // Set isSubmitting back to false after completion or failure
            setIsSubmitting(false);
        }
    };

    const transitions = useTransition(!isSameAddressChecked, {
        from: { maxHeight: 0, opacity: 0 },
        enter: { maxHeight: 500, opacity: 1 },
        leave: { maxHeight: 0, opacity: 0 },
        config: { duration: 300 },
    });

    if (!isLoading && !basketSummary?.lines) {
        return (
            <>
                <EmptyState
                    iconName={IconNames.Register}
                    heading={getTranslation("Checkout.emptyState.heading")}
                    description={getTranslation("checkout.emptyStateDescription")}
                />
            </>
        );
    }

    return (
        <>
            {isLoading ? (
                <Spinner loadingHeading={getTranslation("search.loading-heading")} />
            ) : (
                <div className={S.wrapper}>
                    <div className={S.wrapperMain}>
                        <div className={S.box}>
                            <div className={S.invoiceInfo}>
                                <span className={S.boxName}>
                                    {getTranslation("checkout.InvoiceInfo")}
                                </span>
                                <div className={S.invoiceInfoStatic}>
                                    <div className={S.userAccount}>
                                        <div
                                            className={cn(
                                                S.inputFields,
                                                hidePostalCode && S.hidePostalCode,
                                                showState && S.showState
                                            )}
                                        >
                                            {userAccount?.email && (
                                                <InputField
                                                    className={S.inputField1}
                                                    disabled
                                                    value={userAccount?.email}
                                                    label={getTranslation("userAccount.email")}
                                                />
                                            )}
                                            {userAccount?.vatNumber && (
                                                <InputField
                                                    className={S.inputField2}
                                                    disabled
                                                    value={userAccount?.vatNumber}
                                                    label={getTranslation("userAccount.vatNumber")}
                                                />
                                            )}
                                            {userAccount?.name && (
                                                <InputField
                                                    className={S.inputField3}
                                                    disabled
                                                    value={userAccount?.name}
                                                    label={getTranslation(
                                                        "userAccount.companyName"
                                                    )}
                                                />
                                            )}
                                            {userAccount?.phone && (
                                                <InputField
                                                    className={S.inputField4}
                                                    disabled
                                                    value={userAccount?.phone}
                                                    label={getTranslation("userAccount.phone")}
                                                />
                                            )}
                                            {userAccount?.invoiceAddress?.streetName && (
                                                <InputField
                                                    className={S.inputField5}
                                                    disabled
                                                    value={userAccount?.invoiceAddress?.streetName}
                                                    label={getTranslation("userAccount.streetName")}
                                                />
                                            )}
                                            {!hidePostalCode && (
                                                <InputField
                                                    className={S.inputField6}
                                                    disabled
                                                    value={userAccount?.invoiceAddress?.postalCode}
                                                    label={getTranslation("userAccount.postalCode")}
                                                />
                                            )}
                                            {userAccount?.invoiceAddress?.cityName && (
                                                <InputField
                                                    className={S.inputField7}
                                                    disabled
                                                    value={userAccount?.invoiceAddress?.cityName}
                                                    label={getTranslation("userAccount.city")}
                                                />
                                            )}
                                            {showState && (
                                                <InputField
                                                    className={S.inputField10}
                                                    disabled
                                                    value={userAccount?.invoiceAddress.stateName}
                                                    label={getTranslation("userAccount.state")}
                                                />
                                            )}
                                            {userAccount?.invoiceAddress.countryId && (
                                                <InputField
                                                    className={S.inputField9}
                                                    disabled
                                                    value={userAccount?.invoiceAddress.countryId}
                                                    label={getTranslation("userAccount.country")}
                                                />
                                            )}
                                            <InputField
                                                minLength={1}
                                                maxLength={20}
                                                className={S.inputField8}
                                                value={requisitionNumber}
                                                onChange={handleRequisitionNumberChange}
                                                required
                                                placeholder={getTranslation(
                                                    "checkout.errorRequisitionNumber.placeholder"
                                                )}
                                                error={errors.requisitionNumber}
                                                label={getTranslation("checkout.requisitionNumber")}
                                            />
                                            <InputField
                                                maxLength={60}
                                                className={S.inputField14}
                                                value={customerReference}
                                                onChange={handleCustomerReferenceChange}
                                                placeholder={getTranslation(
                                                    "checkout.customerReference.placeholder"
                                                )}
                                                label={getTranslation("checkout.customerReference")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={S.invoiceInfoAlternative}>
                                    <div className={S.invoiceAddressCheckbox}>
                                        <label>
                                            <input
                                                className={S.checkbox}
                                                type="checkbox"
                                                checked={isSameAddressChecked}
                                                onChange={() =>
                                                    setIsSameAddressChecked(!isSameAddressChecked)
                                                }
                                                aria-checked={isSameAddressChecked}
                                            />
                                            {getTranslation("checkout.deliveryAddressAsInvoice")}
                                        </label>
                                    </div>
                                    {transitions((style, item) =>
                                        item ? (
                                            <animated.div
                                                style={style}
                                                className={S.alternativeAddressFields}
                                            >
                                                <div
                                                    className={cn(
                                                        S.inputFieldsAlt,
                                                        hidePostalCode && S.hidePostalCode,
                                                        showState && S.showState
                                                    )}
                                                >
                                                    <div className={S.firstRow}>
                                                        <InputField
                                                            className={S.inputField}
                                                            value={alternativeAddress.name}
                                                            onChange={
                                                                handleAlternativeAddressChange
                                                            }
                                                            name="name"
                                                            label={getTranslation(
                                                                "userAccount.AltCompanyName"
                                                            )}
                                                            error={errors.name}
                                                        />
                                                        <InputField
                                                            className={S.inputField}
                                                            value={alternativeAddress.streetName}
                                                            onChange={
                                                                handleAlternativeAddressChange
                                                            }
                                                            name="streetName"
                                                            label={getTranslation(
                                                                "userAccount.streetName"
                                                            )}
                                                            error={errors.streetName}
                                                        />
                                                    </div>
                                                    <div className={S.secondRow}>
                                                        {!hidePostalCode && (
                                                            <InputField
                                                                className={S.inputField}
                                                                value={
                                                                    alternativeAddress.postalCode
                                                                }
                                                                onChange={
                                                                    handleAlternativeAddressChange
                                                                }
                                                                name="postalCode"
                                                                label={getTranslation(
                                                                    "userAccount.postalCode"
                                                                )}
                                                                type="text"
                                                                error={errors.postalCode}
                                                            />
                                                        )}
                                                        <InputField
                                                            className={S.inputField}
                                                            value={alternativeAddress.cityName}
                                                            onChange={
                                                                handleAlternativeAddressChange
                                                            }
                                                            name="cityName"
                                                            label={getTranslation(
                                                                "userAccount.city"
                                                            )}
                                                            error={errors.cityName}
                                                        />
                                                        {showState && (
                                                            <InputField
                                                                className={S.inputField}
                                                                value={alternativeAddress.stateName}
                                                                onChange={
                                                                    handleAlternativeAddressChange
                                                                }
                                                                name="stateName"
                                                                label={getTranslation(
                                                                    "userAccount.state"
                                                                )}
                                                                error={errors.stateName}
                                                            />
                                                        )}

                                                        <InputField
                                                            readonly
                                                            className={S.inputField}
                                                            value={
                                                                userAccount?.invoiceAddress
                                                                    .countryId
                                                            }
                                                            name="countryId"
                                                            label={getTranslation(
                                                                "userAccount.country"
                                                            )}
                                                            error={errors.countryId}
                                                        />
                                                    </div>
                                                </div>
                                            </animated.div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${S.box} ${deliveryError ? S.errorBorder : ""}`}>
                            <div className={S.delivery}>
                                <span className={S.boxName}>
                                    {getTranslation("checkout.Delivery")}
                                </span>
                                <div className={S.deliveryList}>
                                    <ShowMore
                                        buttonTextBefore={getTranslation(
                                            "Checkout.buttonTextBefore"
                                        )}
                                        buttonTextAfter={getTranslation("Checkout.buttonTextAfter")}
                                    >
                                        {getDeliveryOptionsList().map(([key, value]) => (
                                            <button
                                                key={key}
                                                className={`${S.deliveryModeItem} ${
                                                    activeDeliveryMode === key ? S.active : ""
                                                }`}
                                                onClick={() => handleDeliveryModeClick(key)}
                                            >
                                                <span className={S.chooseIcon}></span>
                                                <span>{value}</span>
                                                {defaultDeliveryMode?.deliveryCode === key && (
                                                    <p className={S.defaultDelivery}>
                                                        {getTranslation("Checkout.defaultDelivery")}
                                                    </p>
                                                )}
                                                {/* 17 equals to express delivery */}
                                                {key === "17" && (
                                                    <p className={S.expressDelivery}>
                                                        {getTranslation("Checkout.expressDelivery")}
                                                    </p>
                                                )}
                                            </button>
                                        ))}
                                    </ShowMore>
                                </div>
                                {deliveryError && (
                                    <div className={S.errorMessage}>{deliveryError}</div>
                                )}
                            </div>
                        </div>
                        <div className={S.box}>
                            <div className={S.comment}>
                                <span className={S.boxName}>
                                    {getTranslation("checkout.Comments")}
                                </span>
                                <InputField
                                    className={S.inputField1}
                                    value={checkoutComment}
                                    label={getTranslation("checkout.comment")}
                                    onChange={handleCommentChange}
                                    placeholder={getTranslation("checkout.commentPlaceholder")}
                                />
                                {page?.props?.additionalText ? (
                                    <div
                                        className={S.commentInfo}
                                        dangerouslySetInnerHTML={{
                                            __html: page?.props?.additionalText?.markup,
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className={S.box}>
                            <div className={S.approve}>
                                <span className={S.boxName}>
                                    {getTranslation("checkout.Approve")}
                                </span>
                                <div className={S.acceptTradeConditions}>
                                    <label>
                                        <input
                                            className={S.checkbox}
                                            type="checkbox"
                                            checked={isTermsAccepted}
                                            onChange={handleTermsChange}
                                            aria-checked={isTermsAccepted}
                                        />
                                        <span className={S.termWrapper}>
                                            <span className={S.termText}>
                                                {getTranslation("checkout.TermsTexts")}
                                            </span>
                                            <Button
                                                ariaLabel={getTranslation(
                                                    "Checkout.ariaLabel.termsConditions"
                                                )}
                                                onClick={() => {
                                                    window.open(termsConditionsPath, "_blank");
                                                }}
                                                className={S.goToListBtn}
                                                variant="transparent"
                                                size="small"
                                            >
                                                {getTranslation("checkout.Terms")}
                                            </Button>
                                        </span>
                                    </label>
                                </div>
                                <Button
                                    onClick={handleCheckout}
                                    disabled={!isTermsAccepted || isSubmitting}
                                    className={S.checkoutButton}
                                    variant="basket"
                                    type="submit"
                                >
                                    {getTranslation("checkout.CompleteOrder")}
                                </Button>
                                {(submitError || deliveryError) && (
                                    <div className={S.errorMessage}>
                                        <span className={S.fixErrors}>
                                            {getTranslation("checkout.fixErrors")}
                                        </span>
                                        <ul>
                                            {Object.values(errors).map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={S.wrapperSub}>
                        <div className={S.orderSummery}>
                            <span className={S.orderSummeryTitle}>
                                {getTranslation("checkout.OrderSummery")}
                            </span>
                            {basketSummary?.lines.map((line) => (
                                <div key={line.id} className={S.orderSummeryItem}>
                                    <div className={S.orderSummeryItemImage}>
                                        <ImageWithFallback
                                            src={getThumbnailImageUrl(line.images[0]?.url)}
                                            showFallback={line.images?.length === 0}
                                            alt={line.name}
                                            className={S.mainImageContainer}
                                        />
                                    </div>
                                    <div className={S.orderSummeryInfo}>
                                        <span className={S.orderSummeryInfoTitle}>
                                            {line.productId}
                                        </span>
                                        <span className={S.orderSummeryInfoName}>{line.name}</span>
                                        <span className={S.orderSummeryInfoAmount}>
                                            {`${line.quantity} ${line?.data?.Unit}`}
                                        </span>
                                    </div>
                                    <div className={S.orderSummeryPrice}>
                                        <span className={S.orderSummeryPriceItem}>
                                            {`${formatNumber(line.data.price, currentCulture, {
                                                decimals: 2,
                                            })}
                                    ${line.data.currencyCode.toUpperCase()}`}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            <div className={S.orderSummeryTotal}>
                                <span className={S.orderSummeryTotalText}>
                                    {getTranslation("checkout.TotalExDelivery")}
                                </span>
                                <div className={S.orderSummeryTotalValue}>
                                    <span className={S.orderSummeryTotalValueItem}>
                                        {`${formatNumber(
                                            basketSummary?.totalPrice,
                                            currentCulture,
                                            {
                                                decimals: 2,
                                            }
                                        )} ${basketSummary?.currencyCode.toUpperCase()}`}
                                    </span>
                                </div>
                            </div>
                            <div className={S.orderSummeryTotalDeliverInfo}>
                                <span className={S.orderSummeryTotalDeliverInfoName}>
                                    {getTranslation("checkout.DeliveryCost")}
                                </span>
                                <span className={S.orderSummeryTotalDeliverInfoValue}>
                                    {getTranslation("checkout.AddedAfterOrder")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
