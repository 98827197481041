import { apiGet } from "./apiHelper";

export interface DeliveryOptions {
  [key: string]: string;
}

export const fetchDeliveryOptions = async (
  culture: string
): Promise<DeliveryOptions> => {
  const url = `/api/DeliveryOptions?culture=${culture}`;
  const response = await apiGet<DeliveryOptions>(url);
  if (!response) {
    throw new Error("Failed to fetch delivery options");
  }
  return response.data;
};
