import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductPageProps } from "../../../types/ProductTypes.interface";
import { PriceTable } from "@shared/components/Molecules/PriceTable/PriceTable.component";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { Specifications } from "@shared/components/Molecules/Specifications/Specifications.component";
import ImageWithFallback from "@shared/components/Molecules/ImageWithFallback/ImageWithFallback.component";
import Button from "@shared/components/Atoms/Button/Button";
import { IconNames } from "@shared/enums/icons.enums";
import { AddToCart } from "./components/AddToCart/AddToCart.component";
import {
    ProductPriceResponse,
    fetchProductPrice,
    fetchProductPrices,
} from "@shared/helpers/apis/fetchProductPrices";
import { formatNumber } from "@shared/helpers/formatPrice";
import { useTranslations } from "@shared/context/useTranslations";
import { HMF_Text } from "@shared/components/Atoms/Text/Text.component";
import { StockStatus } from "@shared/components/Molecules/StockStatus/StockStatus.component";
import { Breadcrumbs } from "@shared/components/Molecules/Breadcrumbs/Breadcrumbs.component";
import { useFavoriteManagement } from "@shared/hooks/useFavoriteManagement";
import { fetchCategoryProduct, getMainImageUrl } from "@shared/helpers/apis/productHelper";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import ProductList_Item from "../ProductList/components/Item/Item.component";
import { CategoryProduct } from "@shared/types/CategoryTypes.interface";
import { cn } from "@shared/helpers/classNames.helper";
import { Modal } from "@shared/components/Molecules/Modal/Modal.component";
import { FavoriteList } from "@shared/helpers/favoritesHelper";
import S from "./ProductItem.module.scss";
import { ScrollToTopOnMount } from "@shared/components/Molecules/ScrollToTopOnMount/ScrollToTopOnMount.component";

const ProductItem = ({ product }: ProductPageProps) => {
    // Current product states
    const [priceData, setPriceData] = useState<ProductPriceResponse | null>(null);
    const [quantity, setQuantity] = useState(1);
    const [pendingSave, setPendingSave] = useState(false);

    // Related products states
    const [relatedProduct, setRelatedProduct] = useState<Record<string, CategoryProduct | null>>(
        {}
    );
    const [relatedProductPrices, setRelatedProductPrices] = useState<
        Record<string, ProductPriceResponse>
    >({});
    const [relatedProductQuantities, setRelatedProductQuantities] = useState<
        Record<string, number>
    >({});
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentCulture = globalThis?.currentCulture || "en-gb";
    const { getTranslation } = useTranslations();
    const navigate = useNavigate();
    const [listKey, setListKey] = useState(0);

    const {
        isModalOpen,
        setIsModalOpen,
        selectedLists,
        setSelectedLists,
        isProductFavorited,
        handleFavoriteClick,
        handleSaveFavorites,
        getModalTitle,
        getModalDescription,
        favorites,
    } = useFavoriteManagement(product);

    const favoritesPath = getRoutePathByPageType("Favorites") || "/default-favorites-path";

    useEffect(() => {
        const fetchInitialPrice = async () => {
            if (product) {
                const price = await fetchProductPrice(
                    product.id.toString(),
                    quantity,
                    currentCulture
                );
                setPriceData(price);
            }
        };

        if (
            product !== undefined ||
            product!.relatedProductIds !== undefined ||
            product!.relatedProductIds!.length > 0
        ) {
            const fetchRelatedProducts = async () => {
                const relatedProducts: Record<string, CategoryProduct | null> = {};
                await Promise.all(
                    product!.relatedProductIds!.map(async (id) => {
                        const relatedProduct = await fetchCategoryProduct(id, currentCulture);
                        if (relatedProduct.items.length === 0) return;

                        relatedProducts[id] = relatedProduct.items[0];
                    })
                );

                setRelatedProduct(relatedProducts);
            };

            const fetchRelatedProductPrices = async () => {
                const prices = await fetchProductPrices(
                    product!.relatedProductIds!,
                    currentCulture
                );

                setRelatedProductPrices(prices);
                const initialQuantities = product!.relatedProductIds!.reduce(
                    (acc, id) => {
                        acc[id] = 1; // Default quantity to 1
                        return acc;
                    },
                    {} as Record<string, number>
                );
                setRelatedProductQuantities(initialQuantities);
            };

            fetchRelatedProducts();
            fetchRelatedProductPrices();
        }

        fetchInitialPrice();
    }, [product, quantity, currentCulture]);

    const handleQuantityChange = async (newQuantity: number) => {
        setQuantity(newQuantity);
        if (product) {
            const newPrice = await fetchProductPrice(
                product.id.toString(),
                newQuantity,
                currentCulture
            );
            setPriceData(newPrice);
        }
    };

    const handleRelatedProductQuantityChange = async (productId: string, newQuantity: number) => {
        setRelatedProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: newQuantity,
        }));

        const newPrice = await fetchProductPrice(productId, newQuantity, currentCulture);
        setRelatedProductPrices((prevPrices) => ({
            ...prevPrices,
            [productId]: newPrice,
        }));
    };

    useEffect(() => {
        if (pendingSave) {
            handleSaveFavorites(false);
            setTimeout(() => {
                setPendingSave(false);
            }, 50);
        }
        setListKey(listKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingSave, selectedLists]);

    const getDiscountTableRowPercent = (quantity: number, currencyCode: string) => {
        const currencyDiscounts = product?.discounts?.quantityDiscount[currencyCode.toLowerCase()];
        const rowDiscountPercent = currencyDiscounts?.find(
            (discount) => discount.quantityFrom === quantity
        )?.percent;
        return rowDiscountPercent ? rowDiscountPercent : 0;
    };

    const priceTableData = priceData?.discountTable?.quantityDiscount
        ? Object.values(priceData.discountTable.quantityDiscount).map((discount) => ({
              quantity: discount.quantity,
              grossPrice: formatNumber(discount.grossPrice, currentCulture, { decimals: 2 }),
              discount: [discount.percent],
              discountPerUnit: formatNumber(discount.discountPerUnit, currentCulture, {
                  decimals: 2,
              }),
              netPrice: formatNumber(discount.netPrice, currentCulture, { decimals: 2 }),
              percent: getDiscountTableRowPercent(discount.quantity, priceData.currencyCode),
          }))
        : [];

    return (
        <>
            <ScrollToTopOnMount />
            <div className={S.productDetailsContainer}>
                <div className={S.gridColumnFull}>
                    <Breadcrumbs breadcrumbs={product?.breadcrumbs || []} />
                </div>

                {product ? (
                    <>
                        <div className={S.productDetailsMedia}>
                            <div className={S.thumbnailImage}>thumbnail</div>
                            <div className={S.mainImage}>
                                <ImageWithFallback
                                    src={getMainImageUrl(product.images[0]?.url)}
                                    showFallback={product.images?.length === 0}
                                    alt={product.name}
                                    className={S.mainImageContainer}
                                />
                            </div>
                        </div>
                        <div className={S.detailsContainer}>
                            <div className={S.flex}>
                                <div className={S.stockStatus}>
                                    <div className={S.stockStatusBg}></div>
                                    <StockStatus stock={product?.stock} />
                                </div>
                                <h1 className={S.productTitle}>{product?.id}</h1>
                            </div>
                            <div className={S.productDetailsInfo}>
                                <div className={S.subHeadingWrapper}>
                                    <div className={S.subHeadlineInfo}>
                                        <span className={S.headline}>{product?.name}</span>
                                    </div>
                                </div>

                                <div className={S.productDescription}>
                                    <HMF_Text html={product?.shortDescription} />
                                </div>
                                {priceData?.discountTable?.quantityDiscount && (
                                    <PriceTable
                                        priceData={priceTableData}
                                        currentQuantity={quantity}
                                    />
                                )}

                                <div className={S.cartWrapper}>
                                    {priceData?.discountedPrice &&
                                    priceData?.discountedPrice > 0 ? (
                                        <div className={S.productPrices}>
                                            {priceData.discountPerUnit > 0 ? (
                                                <div>
                                                    {priceData?.listPrice && (
                                                        <span className={S.oldPrice}>
                                                            {formatNumber(
                                                                priceData.listPrice,
                                                                currentCulture,
                                                                {
                                                                    decimals: 2,
                                                                }
                                                            )}{" "}
                                                            {priceData?.currencyCode?.toUpperCase()}
                                                        </span>
                                                    )}
                                                    {priceData?.discountPerUnit && (
                                                        <span className={S.discount}>
                                                            -
                                                            {formatNumber(
                                                                priceData.discountPerUnit,
                                                                currentCulture,
                                                                {
                                                                    decimals: 2,
                                                                }
                                                            )}{" "}
                                                            {priceData?.currencyCode?.toUpperCase()}
                                                            <Button
                                                                tooltipContent={
                                                                    <p>
                                                                        {getTranslation(
                                                                            "pdp.price-table.tooltip.discount",
                                                                            {
                                                                                customerDiscount: `${formatNumber(
                                                                                    priceData?.appliedPercentDiscount,
                                                                                    currentCulture
                                                                                )}%`,
                                                                            }
                                                                        )}
                                                                        {"."}
                                                                    </p>
                                                                }
                                                                tooltipId="listDiscount-tooltip"
                                                                showTip
                                                                tooltipPlace="top"
                                                                variant="pill"
                                                                pillSmall
                                                                color="red"
                                                            >
                                                                <span>{`${formatNumber(
                                                                    priceData?.appliedPercentDiscount,
                                                                    currentCulture
                                                                )}%`}</span>
                                                            </Button>
                                                        </span>
                                                    )}
                                                </div>
                                            ) : null}
                                            <span className={S.price}>
                                                {formatNumber(
                                                    priceData?.discountedPrice,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )}{" "}
                                                {priceData?.currencyCode?.toUpperCase()}
                                            </span>
                                        </div>
                                    ) : (
                                        <span className={S.priceContact}>
                                            {getTranslation("general.contactForPrice")}
                                        </span>
                                    )}

                                    {priceData?.discountedPrice &&
                                    priceData?.discountedPrice > 0 ? (
                                        <AddToCart
                                            className={S.addToCart}
                                            product={product}
                                            onQuantityChange={handleQuantityChange}
                                        />
                                    ) : null}
                                    <Button
                                        className={S.addToFavorites}
                                        iconName={
                                            isProductFavorited
                                                ? IconNames.Heartfull
                                                : IconNames.Heart
                                        }
                                        iconPlacement="right"
                                        variant="clear"
                                        onClick={handleFavoriteClick}
                                    >
                                        {getTranslation("general.add-to-favorites")}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {/* Specifications */}
                        {product.specifications.length > 0 ? (
                            <div className={S.gridColumnFull}>
                                <Specifications {...product} />
                            </div>
                        ) : null}

                        {/* Related products */}
                        {product.relatedProductIds!.length > 0 && (
                            <div className={cn(S.relatedProductWrapper, S.gridColumnFull)}>
                                <h2> {getTranslation("pdp.relatedProducts.heading")}</h2>
                                {product.relatedProductIds?.map((id, index) => {
                                    if (relatedProduct[id] === undefined) return;

                                    return (
                                        <div key={index} className={S.relatedProduct}>
                                            <ProductList_Item
                                                key={id}
                                                product={relatedProduct[id]!}
                                                prices={relatedProductPrices[id]}
                                                quantity={relatedProductQuantities[id]}
                                                onQuantityChange={(newQuantity) =>
                                                    handleRelatedProductQuantityChange(
                                                        id,
                                                        newQuantity
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                ) : (
                    <div className={S.emptyState}>
                        <h1>{getTranslation("general.NoProductAvailable")}</h1>

                        <SvgIcon iconName="crane" wrapperStyle={S.emptyStateIcon} />
                    </div>
                )}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    title={getModalTitle()}
                    description={getModalDescription()}
                >
                    {favorites && favorites.length > 0 ? (
                        favorites.map((list: FavoriteList) => (
                            <div key={list.id} className={S.favoriteListItem}>
                                <div className={S.favoriteToggleWrapper}>
                                    <Button
                                        size="fitIcon"
                                        className={S.favoriteToggle}
                                        onClick={() => {
                                            setSelectedLists((prev: Record<string, boolean>) => ({
                                                ...prev,
                                                [list.id]: !prev[list.id],
                                            }));
                                            setPendingSave(true);
                                        }}
                                    >
                                        <SvgIcon
                                            // Force icon re-render via key update due to Safari SVG issue
                                            key={isModalOpen ? `${list.id}${listKey}` : list.id}
                                            wrapperStyle={
                                                S[
                                                    pendingSave
                                                        ? selectedLists[list.id]
                                                            ? "adding"
                                                            : "removing"
                                                        : ""
                                                ]
                                            }
                                            iconName={
                                                selectedLists[list.id]
                                                    ? IconNames.Heartfull
                                                    : IconNames.Heart
                                            }
                                        />
                                    </Button>
                                </div>
                                <div className={S.infoBox}>
                                    <span className={S.favoriteListName}>{list.name}</span>
                                    <span className={S.favoriteListCount}>
                                        {list.items.length}{" "}
                                        {list.items.length > 1
                                            ? getTranslation("Favorites.itemNumbers")
                                            : getTranslation("Favorites.itemNumber")}
                                    </span>
                                </div>

                                <Button
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        navigate(`${favoritesPath}?listId=${list.id}`);
                                    }}
                                    className={S.goToListBtn}
                                    variant="transparent"
                                    size="small"
                                    iconName={IconNames.Backicon}
                                >
                                    {getTranslation("button.toList")}
                                </Button>
                            </div>
                        ))
                    ) : (
                        <div className={S.noFavoritesMessage}>
                            <Button
                                round
                                onClick={() => {
                                    setIsModalOpen(false);
                                    navigate(`${favoritesPath}`);
                                }}
                                iconName={IconNames.Backicon}
                            >
                                {getTranslation("button.toFavorites")}
                            </Button>
                            <Button
                                round
                                variant="transparent"
                                onClick={() => setIsModalOpen(false)}
                            >
                                {getTranslation("button.cancel")}
                            </Button>
                        </div>
                    )}
                    <div className={S.modalFooter}>
                        {favorites && favorites.length > 0 && (
                            <Button onClick={handleSaveFavorites}>
                                {getTranslation("button.save")}
                            </Button>
                        )}
                        <Button variant="transparent" onClick={() => setIsModalOpen(false)}>
                            {getTranslation("button.cancel")}
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ProductItem;
