import React, { useState, KeyboardEvent, useEffect } from "react";
import { Icon } from "../../../../components/Molecules/Icon/Icon.component";
import { IconNames } from "../../../../enums/icons.enums";
import S from "./HeaderSearch.module.scss";
import { cn } from "../../../../helpers/classNames.helper";
import { useTranslations } from "@shared/context/useTranslations";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearch } from "@shared/context/SearchContext";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";

export const HeaderSearch = () => {
    const { query, setQuery, setCategoryId, setProductId, setStockStatus } = useSearch();
    const [inputValue, setInputValue] = useState("");
    const { getTranslation } = useTranslations();
    const navigate = useNavigate();
    const location = useLocation();

    const searchPath = getRoutePathByPageType("Search") || "/search";

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleClearInput = () => {
        setInputValue("");
        setQuery("");
    };

    const goToSearch = () => {
        setQuery(inputValue || "");
        setCategoryId(undefined);
        setProductId(undefined);
        setStockStatus(null);

        if (location.pathname !== searchPath && inputValue) {
            navigate(searchPath);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            goToSearch()
        }
    };

    useEffect(() => {
        setInputValue(query || "")
    }, [query]);

    return (
        <div className={S.headerSearchBox}>
            <input
                type="text"
                className={S.headerSearchInput}
                placeholder={getTranslation("header.search-bar.placeholder")}
                value={inputValue}
                tabIndex={2}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />

            {inputValue === "" ? (
                <div className={cn(S.icon, S.searchIcon)}>
                    <button tabIndex={3} onClick={goToSearch}>
                        <Icon iconName={IconNames.Search} />
                    </button>
                </div>
            ) : (
                <div className={cn(S.icon, S.closeIcon)}>
                    <button onClick={handleClearInput}>
                        <span className={S.clearText}>{getTranslation("header.search-bar.clear-search")}</span>
                        <Icon iconName={IconNames.Close} />
                    </button>
                </div>
            )}
        </div>
    );
};
