import { Order } from "@shared/types/OrderTypes.interface";
import apiClient from "./apiClient"; // Ensure this imports your configured axios instance
import { apiGet } from "./apiHelper";

interface CheckoutData {
    basketId: string;
    customerEmail: string;
    deliveryCode: string | null;
    comment: string;
    requisitionNumber: string;
    customerRef: string;
    useInvoiceAddress: boolean;
    address?: {
        cityName: string;
        streetName: string;
        zipCodeId: string;
    };
}

export const sendOrder = async (checkoutData: CheckoutData, culture: string) => {
    const url = `/api/Orders?culture=${culture}&basketId=${checkoutData.basketId}&deliveryCode=${checkoutData.deliveryCode}&useInvoiceAddress=${checkoutData.useInvoiceAddress}`;

    const body = {
        customerRequisition: checkoutData.requisitionNumber,
        note: checkoutData.comment,
        email: checkoutData.customerEmail,
        address: checkoutData.useInvoiceAddress ? undefined : checkoutData.address,
        customerRef: checkoutData.customerRef,
    };

    const response = await apiClient.post(url, body);
    return response.data;
};

export const fetchOrders = async (year: number): Promise<Order[]> => {
    const url = `/api/Orders?byYear=${year}`;
    const { data } = await apiGet<Order[]>(url);
    return data;
};
