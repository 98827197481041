import { Container } from "@shared/components/Atoms/Container/Container.component";
import { useBasket } from "@shared/context/BasketContext";
import { useTranslations } from "@shared/context/useTranslations";
import ImageWithFallback from "@shared/components/Molecules/ImageWithFallback/ImageWithFallback.component";
import Button from "@shared/components/Atoms/Button/Button";
import QuantityPicker from "@shared/components/Molecules/QuantityPicker/QuantityPicker.component";
import { formatNumber } from "@shared/helpers/formatPrice";
import { Link, useNavigate } from "react-router-dom";
import { StockStatus } from "@shared/components/Molecules/StockStatus/StockStatus.component";
import { IconNames } from "@shared/enums/icons.enums";
import { StockStatusAttention } from "@shared/components/Molecules/StockStatus/StockStatusAttention";
import { getRoutePathByPageType } from "@shared/helpers/findPathForSystemSite";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import { cn } from "@shared/helpers/classNames.helper";
import S from "./Basket.module.scss";
import AddItemNumber from "@shared/components/Molecules/AddItemNumber/AddItemNumber.component";
import { useToast } from "@shared/context/ToastContext";
import { getThumbnailImageUrl } from "@shared/helpers/apis/productHelper";

export const Basket = () => {
    const {
        basket,
        isLoading,
        addToBasket,
        updateBasketItemQuantity,
        removeBasketItem,
        productPrices,
        basketPrices,
    } = useBasket();
    const { getTranslation } = useTranslations();
    const { showToast } = useToast();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentCulture = globalThis?.currentCulture || "en-gb";

    const navigate = useNavigate();

    // Path for quick-order
    const quickOrderPath = getRoutePathByPageType("Quick order") || "/quick-order";
    const checkoutPath = getRoutePathByPageType("Checkout") || "/checkout";

    if (!basket || isLoading) {
        return <div>Loading...</div>;
    }

    const handleQuantityChange = (lineId: string, productId: string, quantity: number) => {
        updateBasketItemQuantity(lineId, productId, quantity);
    };

    const handleRemoveItem = (lineId: string) => {
        removeBasketItem(lineId);
    };

    const handleProductFound = async (productId: string) => {
        await addToBasket({
            productId: productId,
            quantity: 1,
            data: { url: "" },
        });
        showToast(
            <>
                {getTranslation("general.toastMessage.item")} <strong>{productId}</strong>{" "}
                {getTranslation("general.toastMessage.addedTo")}{" "}
                {getTranslation("general.toastMessage.yourBasket")}
            </>
        );
    };

    return (
        <Container>
            <div className={S.basketGrid}>
                <div className={S.basketHeading}>
                    <h1>{getTranslation("basket.headline")}</h1>
                    <Link className={S.goBack} to="/">
                        <Button
                            className={S.goBackBtn}
                            variant="transparent"
                            size="small"
                            round
                            iconName={IconNames.Backicon}
                        >
                            {getTranslation("button.return-to-shop")}
                        </Button>
                    </Link>
                    <div className={S.btns}>
                        <Link to={quickOrderPath}>
                            <Button
                                variant="clear"
                                size="small"
                                className={S.basketHeadingBtn}
                                iconName={IconNames.Quickordertable}
                                round
                            >
                                {getTranslation("basket.quick-order")}
                            </Button>
                        </Link>
                        <AddItemNumber onSuccess={handleProductFound} />
                    </div>
                </div>

                <div className={S.basketWrapper}>
                    {basket?.lines?.length === 0 || !basket?.lines ? (
                        <EmptyState
                            iconName={IconNames.Basket}
                            heading={getTranslation("general.emptyState.Basket.Heading")}
                            description={getTranslation("general.emptyState.Basket.Description")}
                        />
                    ) : (
                        <>
                            <div className={S.basketItems}>
                                {basket?.lines?.map((item) => {
                                    const isOutOfStock = item.stock.local === 0 && item.stock.remote === 0;
                                    const prices = productPrices[item.productId];
                                    return (
                                        <div key={item.id}>
                                            <div
                                                className={`${S.basketItem} ${isOutOfStock ? S.isOutOfStockBorder : null}`}
                                            >
                                                <Link
                                                    to={item?.url}
                                                    className={S.productImgWrapper}
                                                >
                                                    <div className={S.imageWrapper}>
                                                        <ImageWithFallback
                                                            src={getThumbnailImageUrl(
                                                                item?.images[0]?.url
                                                            )}
                                                            showFallback={item.images?.length === 0}
                                                            alt={item?.name}
                                                            className={S.productImg}
                                                        />
                                                    </div>
                                                </Link>
                                                <div className={S.stockStatus}>
                                                    <div className={S.stockStatusBg}></div>
                                                    <StockStatus stock={item?.stock} />
                                                </div>
                                                <Link to={item?.url} className={S.itemDescription}>
                                                    <div className={S.itemDetails}>
                                                        <h2>{item.productId}</h2>
                                                        <span className={S.basketName}>
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                </Link>
                                                <div className={S.productPrices}>
                                                    {prices?.discountPerUnit > 0 ? (
                                                        <div>
                                                            {prices?.listPrice && (
                                                                <span className={S.oldPrice}>
                                                                    {formatNumber(
                                                                        prices.listPrice,
                                                                        currentCulture,
                                                                        {
                                                                            decimals: 2,
                                                                        }
                                                                    )}{" "}
                                                                    {prices.currencyCode.toUpperCase()}
                                                                </span>
                                                            )}
                                                            {prices?.discountPerUnit && (
                                                                <span className={S.discount}>
                                                                    -
                                                                    {formatNumber(
                                                                        prices.discountPerUnit,
                                                                        currentCulture,
                                                                        {
                                                                            decimals: 2,
                                                                        }
                                                                    )}{" "}
                                                                    {prices.currencyCode.toUpperCase()}
                                                                </span>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    <span className={S.price}>
                                                        {formatNumber(
                                                            prices?.discountedPrice,
                                                            currentCulture,
                                                            {
                                                                decimals: 2,
                                                            }
                                                        )}{" "}
                                                        {prices?.currencyCode?.toUpperCase()}
                                                    </span>
                                                </div>

                                                <div className={S.itemQuantity}>
                                                    <QuantityPicker
                                                        quantity={item.quantity}
                                                        setQuantity={(quantity) =>
                                                            handleQuantityChange(
                                                                item.id,
                                                                item.productId,
                                                                quantity
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <Button
                                                    onClick={() => handleRemoveItem(item.id)}
                                                    variant="clear"
                                                    size="small"
                                                    className={S.removeItemBtn}
                                                    iconName={IconNames.Trashcan}
                                                >
                                                    <span>
                                                        {getTranslation("button.remove-item")}
                                                    </span>
                                                </Button>
                                            </div>
                                            {isOutOfStock ? <StockStatusAttention /> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={S.basketWrapperSummeryItems}>
                                <div className={S.basketWrapperSummeryBox}>
                                    <span className={S.summeryTitle}>
                                        {getTranslation("basket.order-content.heading")}
                                    </span>
                                    <div>
                                        <div className={S.summeryItem}>
                                            <span className={S.summeryItemText}>
                                                {getTranslation("basket.order-content.subtotal")}
                                            </span>
                                            <span className={S.summeryItemValue}>
                                                {formatNumber(
                                                    basketPrices?.subTotal,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )}{" "}
                                                {basketPrices?.items[0]?.currencyCode?.toUpperCase()}
                                            </span>
                                        </div>
                                        {basketPrices?.totalDiscount && (
                                            <div className={S.summeryItem}>
                                                <span className={S.summeryItemText}>
                                                    {getTranslation("general.discount")}
                                                </span>
                                                <span className={S.summeryItemValue}>
                                                    -
                                                    {formatNumber(
                                                        basketPrices?.totalDiscount,
                                                        currentCulture,
                                                        {
                                                            decimals: 2,
                                                        }
                                                    )}{" "}
                                                    {basketPrices?.items[0]?.currencyCode?.toUpperCase()}
                                                </span>
                                            </div>
                                        )}
                                        <div className={cn(S.summeryItem, S.summeryItemTotal)}>
                                            <span className={S.summeryItemText}>
                                                {getTranslation("general.total")}
                                            </span>
                                            <span className={S.summeryItemValue}>
                                                {formatNumber(
                                                    basketPrices?.totalPriceWithoutVat,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )}{" "}
                                                {basketPrices?.items[0]?.currencyCode?.toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className={S.checkoutBtn}
                                    iconName={IconNames.Register}
                                    type="submit"
                                    variant="basket"
                                    iconPlacement="right"
                                    onClick={() => {
                                        navigate(checkoutPath);
                                    }}
                                >
                                    {getTranslation("button.checkout")}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default Basket;
