// packages/shared/src/config.ts
const isLocalhost = window.location.host === "localhost:5173";

// Access the app type
const appType = import.meta.env.VITE_APP_TYPE;

// Access environment variables via `import.meta.env`
const apiUrlLocalB2B = import.meta.env.VITE_API_URL_LOCAL_B2B || "";
const apiUrlOnlineB2B = import.meta.env.VITE_API_URL_ONLINE_B2B || "/";
const apiUrlLocalB2C = import.meta.env.VITE_API_URL_LOCAL_B2C || "";
const apiUrlOnlineB2C = import.meta.env.VITE_API_URL_ONLINE_B2C || "/";

// Determine the correct base API URL based on the app type
let BASE_API_URL = "";

if (appType === "b2b") {
    BASE_API_URL = isLocalhost ? apiUrlLocalB2B : apiUrlOnlineB2B;
} else if (appType === "b2c") {
    BASE_API_URL = isLocalhost ? apiUrlLocalB2C : apiUrlOnlineB2C;
} else {
    console.error("Unknown app type detected");
    // Provide a fallback URL if necessary
    BASE_API_URL = "/";
}

export { BASE_API_URL, isLocalhost };
