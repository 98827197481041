import { basketApiGet } from "./apis/apiCheckout";

export interface BasketItem {
  id: string;
  productId: string;
  quantity: number;

  name: string;
  images: { url: string }[];
  data: { price: number; Unit: string; currencyCode: string };
}

export interface BasketSummary {
  id: string;
  customerNumber: string;
  lines: BasketItem[];
  totalPrice: number;
  currencyCode: string;
}

export const fetchBasketSummary = async (
  customerNumber: string,
  culture: string
): Promise<BasketSummary> => {
  const url = `/api/Basket/customer/${customerNumber}/basketSummary?culture=${culture}`;
  const { data } = await basketApiGet<BasketSummary>(url);
  return data;
};
