import React from "react";
import S from "./InputField.module.scss";
import { cn } from "@shared/helpers/classNames.helper";

interface InputFieldProps {
    id?: string;
    name?: string;
    label?: string;
    type?: "text" | "password" | "submit" | string;
    value?: string;
    placeholder?: string;
    defaultValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ariaLabel?: string;
    tabIndex?: number;
    required?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    error?: string;
    minLength?: number;
    maxLength?: number;
}

const InputField: React.FC<InputFieldProps> = ({
    id,
    name,
    label,
    type = "text",
    value,
    placeholder,
    defaultValue,
    onChange,
    ariaLabel,
    tabIndex,
    required,
    disabled,
    readonly,
    className,
    error,
    minLength,
    maxLength,
}) => {
    const inputFieldWrapper = cn(S.inputFieldWrapper, className, {
        [S.disabledInput]: disabled,
        [S.readonlyInput]: readonly,
        [S.errorInput]: error,
    });

    return (
        <div className={inputFieldWrapper}>
            {label ? <label htmlFor={name}>{label}</label> : null}
            <input
                id={id}
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                aria-label={ariaLabel}
                tabIndex={tabIndex}
                required={required}
                disabled={disabled}
                readOnly={readonly}
                minLength={minLength}
                maxLength={maxLength}
            />
            {error && <span className={S.errorMessage}>{error}</span>}
        </div>
    );
};

export default InputField;
