import { useGenericMutation } from "./useGenericApi";
import { login, LoginResponseType } from "../helpers/apis/loginHelper";
import { useAuth } from "../context/AuthContext";

export const useLogin = () => {
    const { login: authLogin } = useAuth();

    return useGenericMutation<LoginResponseType, Error, { userName: string; password: string }>(
        ({ userName, password }) => login(userName, password),
        {
            onSuccess: (res) => {
                if (res.success) {
                    console.log("Login successful");
                    authLogin(); // Call login function without token
                } else {
                    console.error("Login unsuccessful", res);
                }
            },
            onError: (error) => {
                console.error("Login error:", error.message);
            },
        }
    );
};
