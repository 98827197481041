// src/App.tsx
import { Routes, Route, Outlet } from "react-router-dom";
import { Header } from "../../shared/src/layout/components/Header/Header.component.";
import { Footer } from "../../shared/src/layout/components/Footer/Footer.component";
import { DynamicPage } from "@shared/components/Pages/DynamicPage/DynamicPage.component";
import { Login } from "../../shared/src/components/Pages/Login/Login.component";
import { ErrorPage } from "@shared/components/Pages/ErrorPage/ErrorPage.component";
import { ToastProvider } from "@shared/context/ToastContext";
import { NotificationBar } from "@shared/components/Organisms/NotificationBar/NotificationBar.component";
import { ModalProvider } from "@shared/context/ModalContext";
import S from "./App.module.scss";

const Layout = () => {
    return (
        <div className={S.wrapper}>
            {<Header />}
            <div className={S.content}>
                <Outlet />
            </div>
            {<Footer />}
            <NotificationBar />
        </div>
    );
};

const App = () => {
    return (
        <ToastProvider>
            <ModalProvider>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<DynamicPage />} />
                        <Route path="*" element={<DynamicPage />} />
                        <Route path="/error" element={<ErrorPage />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </ModalProvider>
        </ToastProvider>
    );
};

export default App;
