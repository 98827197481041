import React, { useEffect, useRef, useState } from "react";

export const useDynamicSvgImport = (iconName: string) => {
    const importedIconRef = useRef<React.FC<React.SVGProps<SVGElement>>>();
    // const importedIconRef = useRef<React.JSXElementConstructor<any> | null>(null);
    // const ref = useRef<React.JSXElementConstructor<any> | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>();

    useEffect(() => {
        setLoading(true);

        // dynamically import the mentioned svg icon name in props
        const importSvgIcon = async (): Promise<void> => {
            // please make sure all your svg icons are placed in the same directory
            // if we want that part to be configurable then instead of iconName we will send iconPath as prop
            try {
                if (!iconName) {
                    return
                }

                const { default: namedImport } = await import(
                    `../assets/icons/${iconName?.toLowerCase()}.svg?react`
                );
                importedIconRef.current = namedImport;
                // console.log(`Successfully imported icon: ${iconName}`);
            } catch (err) {
                setError(err);
                console.error(err);
                console.error(`Error importing icon: ${iconName}`, err);
            } finally {
                setLoading(false);
            }
        };

        importSvgIcon();
    }, [iconName]);

    return { error, loading, SvgIcon: importedIconRef.current };
};
