import React from "react";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import S from "./EmptyState.module.scss";

interface EmptyStateProps {
    heading?: string;
    description?: string;
    iconName?: IconNames | string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ heading, description, iconName }) => {
    return (
        <div className={S.emptyStateWrapper}>
            {iconName && <SvgIcon iconName={iconName} />}
            <div className={S.emptyStateText}>
                {heading && <h2>{heading}</h2>}
                {description && <p>{description}</p>}
            </div>
        </div>
    );
};

export default EmptyState;
