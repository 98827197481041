import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";

import {
    fetchFavorites,
    addProductToFavorites,
    removeProductFromFavorites,
    createFavoritesList,
    renameFavoritesList,
    deleteFavoritesList,
    FavoriteList,
} from "@shared/helpers/favoritesHelper";

interface AddProductToFavoritesVariables {
    productId: string;
    listIds: string[];
}

interface RenameFavoritesListVariables {
    listId: string;
    name: string;
}

interface FavoritesContextProps {
    favorites: FavoriteList[] | null;
    refetchFavorites: () => void;
    addProductToFavorites: (productId: string, listIds: string[]) => Promise<void>;
    removeProductFromFavorites: (productId: string, listIds: string[]) => Promise<void>;
    createFavoritesList: (name: string) => Promise<string>;
    renameFavoritesList: (listId: string, name: string) => Promise<void>;
    deleteFavoritesList: (listId: string, name: string) => Promise<void>;
}

const FavoritesContext = createContext<FavoritesContextProps | undefined>(undefined);

export const FavoritesProvider = ({ children }: { children: ReactNode }) => {
    const [favorites, setFavorites] = useState<FavoriteList[] | null>(null);

    // @ts-expect-error
    const customerNumber = globalThis?.customer?.customerNumber;    
   
    const { data: fetchedFavorites, refetch: refetchFavorites } = useQuery<FavoriteList[], Error>({
        queryKey: ["favorites"],
        queryFn: fetchFavorites,
        enabled: !!customerNumber, // Skip fetching favorites on the login page
    });

    useEffect(() => {
        if (fetchedFavorites) {
            setFavorites(fetchedFavorites);
        }
    }, [fetchedFavorites]);

    const addProductToFavoritesMutation = useMutation<void, Error, AddProductToFavoritesVariables>({
        mutationFn: (variables: AddProductToFavoritesVariables) => addProductToFavorites(variables),
        onSuccess: () => {
            refetchFavorites();
        },
    });

    const removeProductFromFavoritesMutation = useMutation<
        void,
        Error,
        AddProductToFavoritesVariables
    >({
        mutationFn: (variables: AddProductToFavoritesVariables) =>
            removeProductFromFavorites(variables),
        onSuccess: () => {
            refetchFavorites();
        },
    });

    const createFavoritesListMutation = useMutation<string, Error, string>({
        mutationFn: (name: string) => createFavoritesList(name),
        onSuccess: () => {
            refetchFavorites();
        },
    });

    const renameFavoritesListMutation = useMutation<void, Error, RenameFavoritesListVariables>({
        mutationFn: (variables: RenameFavoritesListVariables) => renameFavoritesList(variables),
        onSuccess: () => {
            refetchFavorites();
        },
    });

    const deleteFavoritesListMutation = useMutation<void, Error, string>({
        mutationFn: (listId: string) => deleteFavoritesList(listId),
        onSuccess: () => {
            refetchFavorites();
        },
    });

    return (
        <FavoritesContext.Provider
            value={{
                favorites,
                refetchFavorites,
                addProductToFavorites: (productId: string, listIds: string[]) =>
                    addProductToFavoritesMutation.mutateAsync({ productId, listIds }),
                removeProductFromFavorites: (productId: string, listIds: string[]) =>
                    removeProductFromFavoritesMutation.mutateAsync({ productId, listIds }),
                createFavoritesList: (name: string) =>
                    createFavoritesListMutation.mutateAsync(name),
                renameFavoritesList: (listId: string, name: string) =>
                    renameFavoritesListMutation.mutateAsync({ listId, name }),
                deleteFavoritesList: (listId: string) =>
                    deleteFavoritesListMutation.mutateAsync(listId),
            }}
        >
            {children}
        </FavoritesContext.Provider>
    );
};

export const useFavorites = () => {
    const context = useContext(FavoritesContext);
    if (!context) {
        throw new Error("useFavorites must be used within a FavoritesProvider");
    }
    return context;
};
