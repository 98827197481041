import React from "react";
import { ModuleItem } from "../../../../components/PageContent/ContentPage";
import { DynamicBlock } from "../../../../components/Blocks/DynamicBlock/DynamicBlock.component";
import S from "./FooterColumn.module.scss";

interface FooterColumnProps {
    title?: string;
    items?: ModuleItem[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, items }) => (
    <div className={S.column}>
        <h3>{title}</h3>
        {items && items.map((item: ModuleItem, index: number) => (
            <DynamicBlock key={index} {...item} />
        ))}
    </div>
);

export default FooterColumn;