import React, { useMemo } from "react";
import S from "./Pagination.module.scss";
import { cn } from "@shared/helpers/classNames.helper";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import { useTranslations } from "@shared/context/useTranslations";

interface PaginationProps {
    pageSize?: number;
    pageNumber: number;
    totalPages: number;
    totalCount: number;
    handlePagination: (pageNumber: number) => void;
    handlePageSizeChange: (size: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    pageSize,
    pageNumber,
    totalPages,
    totalCount,
    handlePagination,
    handlePageSizeChange,
}) => {
    const { getTranslation } = useTranslations();

    // Default page size from script-area
    const defaultPageSize = (window as any).defaultPagingSize || 50;
    const ellipsis = () => <span className={S.ellipsis}>...</span>;

    const pageNumberBtn = (page: number, isActive = false) => (
        // Number buttons
        <button
            onClick={() => handlePagination(page)}
            className={cn(S.numberBtn, { [S.active]: isActive })}
        >
            {page}
        </button>
    );

    const pageSizeOptions = useMemo(() => {
        const pageSizeOptions = [30, 50, 75, 100];
        if (!pageSizeOptions.includes(defaultPageSize)) {
            pageSizeOptions.push(defaultPageSize);
            return pageSizeOptions.sort((n1,n2) => n1 - n2);
        }

        return pageSizeOptions;
        }, [defaultPageSize])

    return (
        <section className={S.paginationWrapper}>
            {totalPages > 1 && (
                <div className={S.pageNumberBtns}>
                    {/* Arrow button left */}
                    <button
                        onClick={() => pageNumber !== 1 && handlePagination(pageNumber - 1)}
                        className={cn(
                            [S.arrow, S.arrowLeft, pageNumber === 1 && S.disabled].join(" ")
                        )}
                    >
                        <SvgIcon iconName={IconNames.Smallarrowright} />
                    </button>

                    {pageNumberBtn(1, pageNumber === 1)}
                    {pageNumber > 3 && ellipsis()}
                    {pageNumber > 2 && pageNumberBtn(pageNumber - 1)}
                    {pageNumber !== 1 &&
                        pageNumber !== totalPages &&
                        pageNumberBtn(pageNumber, true)}
                    {pageNumber < totalPages - 1 && pageNumberBtn(pageNumber + 1)}
                    {pageNumber === 1 && totalPages > 3 && pageNumberBtn(pageNumber + 2)}
                    {pageNumber < totalPages - 2 && ellipsis()}
                    {pageNumberBtn(totalPages, pageNumber === totalPages)}

                    {/* Arrow button right */}
                    <button
                        onClick={() =>
                            pageNumber !== totalPages && handlePagination(pageNumber + 1)
                        }
                        className={[
                            S.arrow,
                            S.arrowRight,
                            pageNumber === totalPages && S.disabled,
                        ].join(" ")}
                    >
                        <SvgIcon iconName={IconNames.Smallarrowright} />
                    </button>
                </div>
            )}
            {totalCount > 30 && (
                <div className={S.showNumberOfPages}>
                    <label htmlFor="pageSize">{getTranslation("general.pageSize")}</label>
                    <div className={S.select}>
                        <select
                            id="pageSize"
                            value={pageSize}
                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                        >
                            {pageSizeOptions.map((sizeOption, index) => (
                                <option value={sizeOption} key={index}>{sizeOption}</option>    
                            ))}
                        </select>
                        <div className={cn(S.icon, S.stockIcon)}>
                            <button className={S.selectBtn} tabIndex={3}>
                                <SvgIcon iconName={IconNames.Chevron} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Pagination;
