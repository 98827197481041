import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { Container } from "@shared/components/Atoms/Container/Container.component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from "@shared/assets/icons/hmf-logo.svg?react";
import { HeaderIcon } from "@shared/components/Molecules/HeaderIcon/HeaderIcon.component";
import { IconNames } from "@shared/enums/icons.enums";
import { HeaderSearch } from "./HeaderSearch/HeaderSearch.component";
import { HeaderDropdown } from "../../../components/Molecules/HeaderDropdown/HeaderDropdown.component";
import { usePage } from "../../../context/PageContext";
import { Flag } from "@shared/components/Molecules/flags/flag.component";
import useCurrentCulture from "../../../hooks/useCurrentCulture";
import S from "./Header.module.scss";
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";
import { useTranslations } from "../../../context/useTranslations";
import React from "react";

export const Header: React.FC = () => {
    const { logout } = useAuth();
    const { header } = usePage();
    const { isSidebarOpen, setIsSidebarOpen, page } = usePage();
    const { currentFlag, languageData } = useCurrentCulture();
    const { getTranslation } = useTranslations();

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={S.header}>
            <Container noTopPadding noBottomPadding>
                <header>
                    <div className={S.headerInner}>
                        {page?.type === "frontendPage" ? null : (
                            <div
                                role="button"
                                className={S.mobileMenuIcon}
                                onClick={handleSidebarToggle}
                            >
                                <HeaderIcon iconName={IconNames.Menu} text="Menu" />
                            </div>
                        )}

                        {/* TODO: add translation to aria-label  */}
                        <Link to="/" className={S.logo} tabIndex={1} aria-label="Gå til forsiden">
                            <Logo />
                        </Link>

                        <ErrorBoundary>
                            <div className={S.headerIcons}>
                                {header && header?.headerIcons
                                    ? header?.headerIcons?.map((page, index) => {
                                          const icon = page?.properties?.pageType
                                              ?.toLowerCase()
                                              .replace(" ", "");
                                          return (
                                              <div
                                                  className={S.headerIconFlex}
                                                  key={page.route.path}
                                              >
                                                  <Link
                                                      tabIndex={4}
                                                      to={page.route.path}
                                                      className={S.iconLink}
                                                      role="button"
                                                  >
                                                      <HeaderIcon
                                                          iconName={icon}
                                                          text={page.name}
                                                      />
                                                  </Link>
                                                  {index < header.headerIcons.length - 1 && (
                                                      <div className={S.separatorMobile}></div>
                                                  )}
                                              </div>
                                          );
                                      })
                                    : null}

                                <div className={S.profileLanguage}>
                                    <div className={S.separator}></div>

                                    {header && header?.profilePages ? (
                                        <HeaderDropdown
                                            title={getTranslation("header.profile")}
                                            tabIndex={5}
                                            iconName={IconNames.Profile}
                                        >
                                            {header.profilePages.map((page, index) => (
                                                <Link
                                                    role="button"
                                                    tabIndex={6}
                                                    key={index}
                                                    to={page.route.path}
                                                    className={S.dropdownItem}
                                                    aria-label={page.name}
                                                >
                                                    {page.name}
                                                </Link>
                                            ))}
                                            <button
                                                className={S.dropdownItem}
                                                onClick={logout}
                                                tabIndex={7}
                                            >
                                                {getTranslation("general.log-out")}
                                            </button>
                                        </HeaderDropdown>
                                    ) : null}

                                    {languageData && languageData?.length ? (
                                        <HeaderDropdown
                                            tabIndex={8}
                                            title={getTranslation("header.language-picker")}
                                            items={languageData}
                                            subtitle={<Flag name={currentFlag} />}
                                        >
                                            {languageData.map((lang, index) => (
                                                <a
                                                    role="button"
                                                    tabIndex={9}
                                                    key={index}
                                                    className={S.dropdownItem}
                                                    aria-label={lang.ariaLabel}
                                                    href={lang.link}
                                                >
                                                    <Flag name={lang.icon} />
                                                    {lang.text}
                                                </a>
                                            ))}
                                        </HeaderDropdown>
                                    ) : null}
                                </div>
                            </div>
                            <div className={S.headerSearch}>
                                <HeaderSearch />
                            </div>
                        </ErrorBoundary>
                    </div>
                </header>
            </Container>
        </div>
    );
};
