import { useEffect, useState } from "react";
import S from "./NotificationBar.module.scss";
import { Icon } from "@shared/components/Molecules/Icon/Icon.component";
import { IconNames } from "@shared/enums/icons.enums";
import { cn } from "@shared/helpers/classNames.helper";

export interface NotificationProperties {
    notificationActive: boolean;
    notificationHeadline: string;
    notificationText: {
        markup: string;
        blocks: any[];
    };
    notificationStatus: string;
}

export interface NotificationContent {
    contentType: string;
    id: string;
    properties: NotificationProperties;
    settings: any | null;
}

export interface NotificationData {
    notificationContent: {
        items: Array<{
            content: NotificationContent;
            settings: any | null;
        }>;
    };
}
// @ts-expect-error
const notificationData: NotificationData = globalThis.notification;

export const NotificationBar = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [activeNotification, setActiveNotification] = useState<NotificationProperties | null>(
        null
    );

    useEffect(() => {
        const notificationDismissed = sessionStorage.getItem("notificationDismissed");
        if (!notificationDismissed && notificationData?.notificationContent?.items) {
            const activeNotif = notificationData.notificationContent.items.find(
                (item) => item.content.properties.notificationActive
            );
            if (activeNotif) {
                setActiveNotification(activeNotif.content.properties);
                setIsVisible(true);
            }
        }
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        sessionStorage.setItem("notificationDismissed", "true");
    };

    if (!isVisible || !activeNotification) return null;

    const { notificationHeadline, notificationText, notificationStatus } = activeNotification;

    const getIconWrapperClass = () => {
        switch (notificationStatus) {
            case "Green":
                return S.iconWrapperGreen;
            case "Yellow":
                return S.iconWrapperYellow;
            case "Red":
                return S.iconWrapperRed;
            default:
                return "";
        }
    };

    return (
        <div className={S.wrapperNoti}>
            <div className={S.notificationWrapper}>
                <div className={S.notificationContent}>
                    <div className={cn(S.iconWrapper, getIconWrapperClass())}>
                        <Icon iconName={IconNames.Warning} />
                    </div>
                    <div className={S.notiContent}>
                        <span className={S.notiHeadline}>{notificationHeadline}</span>
                        <div
                            className={S.notiText}
                            dangerouslySetInnerHTML={{ __html: notificationText.markup }}
                        ></div>
                        <button onClick={handleDismiss} className={S.closeBtn}>
                            <Icon iconName={IconNames.Close} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
