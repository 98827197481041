// src/context/ToastContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import Toast from "@shared/components/Molecules/Toast/Toast.component";
import { IconNames } from "@shared/enums/icons.enums";
interface ToastProviderProps {
    children: ReactNode;
}
interface ToastContextProps {
    showToast: (message: React.ReactNode, iconName?: IconNames | string) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toastMessage, setToastMessage] = useState<React.ReactNode | null>(null);
    const [iconName, setIconName] = useState<IconNames | string>("");

    const showToast = (message: React.ReactNode, iconName: IconNames | string = "") => {
        setToastMessage(message);
        setTimeout(() => setToastMessage(null), 8000); // Automatically hide toast after 8 seconds
        setIconName(iconName);
    };

    const hideToast = () => {
        setToastMessage(null);
        setIconName("");
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toastMessage && (
                <Toast message={toastMessage} onClose={hideToast} iconName={iconName} />
            )}
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};
