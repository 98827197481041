// src/components/Sidebar/Sidebar.tsx
import React, { useEffect } from "react";
import { HeaderDropdown } from "../HeaderDropdown/HeaderDropdown.component";
import { IconNames } from "../../../enums/icons.enums";
import { Icon } from "../Icon/Icon.component";
import { SidebarProps } from "./SidebarProps";
import { Navigation } from "@shared/components/Organisms/Navigation/Navigation.component";
import { useAuth } from "@shared/context/AuthContext";
import { usePage } from "@shared/context/PageContext";
import { Flag } from "../flags/flag.component";
import { Link } from "react-router-dom";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";
import S from "../Sidebar/Sidebar.module.scss";
import { useTranslations } from "../../../context/useTranslations";
import { useSidebar } from "@shared/context/SidebarContext";

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
    const { logout } = useAuth();
    const { header } = usePage();
    const { currentFlag, languageData } = useCurrentCulture();
    const { getTranslation } = useTranslations();
    const { openPaths } = useSidebar();

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add(S.noScroll);
        } else {
            document.body.classList.remove(S.noScroll);
        }
        return () => document.body.classList.remove(S.noScroll);
    }, [isOpen]);

    return (
        <>
            <ErrorBoundary>
                <div className={`${S.sidebarMenuBg} ${isOpen ? S.sidebarMenuBgOpen : ""}`}></div>
                <aside className={`${S.sidebarMenu} ${isOpen ? S.open : null}`}>
                    <div className={S.sidebarHeader}>
                        <div role="button" className={S.close} onClick={onClose}>
                            <Icon iconName={IconNames.Close} />
                            <span>{getTranslation("general.close")}</span>
                        </div>
                        <div className={S.icons}>
                            {header && header?.profilePages ? (
                                <HeaderDropdown
                                    title={getTranslation("header.profile")}
                                    tabIndex={5}
                                    iconName={IconNames.Profile}
                                    onClose={onClose}
                                >
                                    {header.profilePages.map((page, index) => (
                                        <Link
                                            key={index}
                                            to={page.route.path}
                                            className={S.dropdownItem}
                                            aria-label={page.name}
                                        >
                                            {page.name}
                                        </Link>
                                    ))}
                                    <button className={S.dropdownItem} onClick={logout}>
                                        {getTranslation("general.log-out")}
                                    </button>
                                </HeaderDropdown>
                            ) : null}

                            {languageData && languageData?.length ? (
                                <HeaderDropdown
                                    title={getTranslation("header.language-picker")}
                                    items={languageData}
                                    subtitle={<Flag name={currentFlag} />}
                                    onClose={onClose}
                                >
                                    {languageData.map((lang, index) => (
                                        <a
                                            key={index}
                                            className={S.dropdownItem}
                                            aria-label={lang.ariaLabel}
                                            href={lang.link}
                                        >
                                            <Flag name={lang.icon} />
                                            {lang.text}
                                        </a>
                                    ))}
                                </HeaderDropdown>
                            ) : null}
                        </div>
                    </div>
                    <Navigation openPaths={openPaths} isOpen={isOpen} onClose={onClose} />
                </aside>
            </ErrorBoundary>
        </>
    );
};
