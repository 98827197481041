import {
    useQuery,
    useMutation,
    QueryKey,
    UseQueryOptions,
    UseMutationOptions,
    UseQueryResult,
    UseMutationResult,
} from "@tanstack/react-query";

// Define the generic options for the query hook
interface UseGenericApiOptions<T, E = unknown> extends UseQueryOptions<T, E> {
    queryKey: QueryKey;
    fetchFunction: () => Promise<T>;
    enabled?: boolean;
    onSuccess?: (data: T) => void;
    onError?: (error: E) => void;
}

// Create a generic API query hook that correctly uses the error type
export const useGenericApi = <T, E = unknown>(
    queryKey: QueryKey,
    fetchFunction: () => Promise<T>,
    options?: Partial<UseGenericApiOptions<T, E>>
): UseQueryResult<T, E> => {
    return useQuery<T, E>({
        queryKey,
        queryFn: fetchFunction,
        enabled: options?.enabled ?? true,
        onSuccess: options?.onSuccess,
        onError: options?.onError,
        ...options, // Spread additional options
    });
};

// Define the mutation options interface for consistency
interface GenericMutationOptions<T, E = unknown, V = unknown> extends UseMutationOptions<T, E, V> {
    onMutate?: (variables: V) => Promise<void> | void;
    onSuccess?: (data: T) => void;
    onError?: (error: E) => void;
    onSettled?: (data: T | undefined, error: E | null) => void;
}

// Create a generic mutation hook that uses a consistent error type
export const useGenericMutation = <T, E = unknown, V = unknown>(
    mutationFn: (variables: V) => Promise<T>,
    options?: GenericMutationOptions<T, E, V>
): UseMutationResult<T, E, V> => {
    return useMutation<T, E, V>({
        mutationFn,
        onMutate: options?.onMutate,
        onSuccess: options?.onSuccess,
        onError: options?.onError,
        onSettled: options?.onSettled,
        ...options, // Spread additional options
    });
};
