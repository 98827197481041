// src/hooks/useValidateOrderLines.ts
import { useGenericMutation } from "./useGenericApi";
import { validateOrderLines } from "@shared/helpers/validateOrderLines";

interface ValidateOrderLineResponse {
    isValid: boolean;
    productId: string;
    productNumberError: string;
}

export const useValidateOrderLines = (options?: {
    onMutate?: (variables: string[]) => Promise<void> | void;
    onSuccess?: (data: ValidateOrderLineResponse[]) => void;
    onError?: (error: unknown) => void;
    onSettled?: (data: ValidateOrderLineResponse[] | undefined, error: unknown | null) => void;
}) => {
    return useGenericMutation<ValidateOrderLineResponse[], unknown, string[]>(
        (itemNumbers) => validateOrderLines(itemNumbers),
        {
            onMutate: options?.onMutate,
            onSuccess: options?.onSuccess,
            onError: options?.onError,
            onSettled: options?.onSettled,
        }
    );
};
