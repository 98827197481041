import { User } from "@shared/types/UserAccountTypes.interface";
import { apiGet } from "./apiHelper";

export const fetchUserAccount = async (): Promise<User> => {
  const url = `/api/customer/GetCustomer`;
  const response = await apiGet<User>(url);
  if (!response) {
    throw new Error("Failed to fetch user account");
  }
  return response.data;
};
