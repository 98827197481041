// src/hooks/useBlockDataMapping.ts
import { useMemo } from "react";
import { CardsModule } from "../types/ContentTypes";

export const useBlockDataMapping = (contentType: string, data: any, settings: any) => {
    return useMemo(() => {
        switch (contentType) {
            case "blockHero":
                return {
                    headline: data.properties.headline,
                    text: data.properties.text,
                    image: data.properties.image,
                    button: data.properties.button,
                };
            case "cards": {
                const cardsData: CardsModule = data;
                return {
                    headline: cardsData.properties.headline,
                    cards: cardsData.properties.cardsList.items,
                    settings: settings,
                };
            }
            case "textImage":
                return {
                    headline: data.properties.headline,
                    description: data.properties.description,
                    image: data.properties.image,
                };
            case "footerColumn":
                return {
                    title: data.properties.title,
                    items: data.properties.items.items,
                };
            case "footerAddress":
                return {
                    title: data.properties.title,
                    text: data.properties.text,
                };
            case "textArea":
                return {
                    title: data.properties.title,
                    text: data.properties.text,
                };
            case "footerLink":
                return {
                    link: data.properties.link,
                };
            case "soMe":
                return {
                    socials: data.properties.socials,
                };

            // Add other mappings as needed
            default:
                return {};
        }
    }, [contentType, data]);
};
