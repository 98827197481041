// src/helpers/apis/searchHelper.ts

import { SearchApiRequest, SearchApiResponse } from "@shared/types/SearchTypes.interface";
import { apiPost } from "./apiHelper";
import { CategoryProductList } from "@shared/types/CategoryTypes.interface";

export const searchProducts = async (
    request: SearchApiRequest,
    signal?: AbortSignal
): Promise<SearchApiResponse> => {
    const searchUrl = `/api/product/GetProducts?culture=${request.culture}&pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`;
    const { productIdOrName, productId, categoryId, inStock } = request;

    const response = await apiPost<
        SearchApiResponse,
        { filter: Omit<SearchApiRequest, "culture" | "pageNumber" | "pageSize"> }
    >(
        searchUrl,
        {
            filter: {
                productIdOrName,
                productId,
                categoryId,
                inStock,
            },
        },
        { signal }
    );

    return response;
};

export const mapToCategoryProductList = (apiResponse: SearchApiResponse): CategoryProductList => {
    return {
        ...apiResponse,
        skipSize: (apiResponse.pageNumber - 1) * apiResponse.pageSize,
    };
};
