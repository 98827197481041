import { createContext, useContext, useEffect, ReactNode, useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    Basket,
    BasketItem,
    addToBasket as apiAddToBasket,
    addMultipleToBasket as apiAddMultipleToBasket,
    updateBasketItemQuantity as apiUpdateBasketItemQuantity,
    removeBasketItem as apiRemoveBasketItem,
    fetchBasket,
} from "@shared/helpers/apis/basketHelper";
import {
    fetchBasketPrices,
    ProductPriceResponse,
    BasketPriceResponse,
} from "@shared/helpers/apis/fetchProductPrices";

interface BasketContextProps {
    isLoading: boolean;
    basket: Basket | null;
    totalQuantity: number;
    refetchBasket: () => void;
    // eslint-disable-next-line no-unused-vars
    addToBasket: (product: {
        productId: string;
        quantity: number;
        data: { url: string };
    }) => Promise<void>;
    // eslint-disable-next-line no-unused-vars
    addMultipleToBasket: (products: { productId: string; quantity: number }[]) => Promise<void>;
    // eslint-disable-next-line no-unused-vars
    updateBasketItemQuantity: (
        lineId: string,
        productId: string,
        quantity: number
    ) => Promise<void>;
    // eslint-disable-next-line no-unused-vars
    removeBasketItem: (lineId: string) => Promise<void>;
    productPrices: Record<string, ProductPriceResponse>;
    basketPrices: BasketPriceResponse | null;
}

const BasketContext = createContext<BasketContextProps | undefined>(undefined);

export const BasketProvider = ({ children }: { children: ReactNode }) => {
    const queryClient = useQueryClient();

    // @ts-expect-error
    const customerNumber = globalThis?.customer?.customerNumber;

    // @ts-expect-error
    const currentCulture = globalThis?.currentCulture;

    const [productPrices, setProductPrices] = useState<Record<string, ProductPriceResponse>>({});
    const [basketPrices, setBasketPrices] = useState<BasketPriceResponse | null>(null);
    const [updatedQuantity, setUpdatedQuantity] = useState(false);

    const { data: basket, refetch: refetchBasket, isLoading } = useQuery({
        queryKey: ["basket", customerNumber],
        queryFn: () => fetchBasket(customerNumber, currentCulture),
        enabled: !!customerNumber,
    });

    const isLoginPage = /^\/login(\?.*)?$/.test(
        window.location.pathname.toLowerCase() + window.location.search.toLowerCase()
    );

    useEffect(() => {
        const fetchPrices = async () => {
            if (isLoginPage) {
                return; // Skip fetching prices on the login page
            }
            if (basket && basket.lines && basket.lines.length > 0) {
                const productQuantities = basket.lines.map((item) => ({
                    productId: item.productId,
                    quantity: item.quantity,
                }));
                const prices = await fetchBasketPrices(productQuantities, currentCulture);
                const pricesMap = prices.items.reduce(
                    (acc, item) => {
                        // @ts-expect-error
                        acc[item.productId] = item;
                        return acc;
                    },
                    {} as Record<string, ProductPriceResponse>
                );
                setProductPrices(pricesMap);
                setBasketPrices(prices);
            } else {
                // Handle empty basket
                setProductPrices({});
                setBasketPrices({
                    subTotal: 0,
                    totalDiscount: 0,
                    vat: 0,
                    totalPriceWithVat: 0,
                    totalPriceWithoutVat: 0,
                    items: [],
                });
            }
        };

        fetchPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket, currentCulture, updatedQuantity]);

    const addToBasket = async (product: {
        productId: string;
        quantity: number;
        data: { url: string };
    }) => {
        if (customerNumber) {
            await apiAddToBasket(customerNumber, product);
            refetchBasket();
        }
    };

    const addMultipleToBasket = async (products: { productId: string; quantity: number }[]) => {
        if (customerNumber) {
            await apiAddMultipleToBasket(customerNumber, products);
            refetchBasket();
        }
    };

    const updateBasketItemQuantityMutation = useMutation<
        BasketItem,
        Error,
        { lineId: string; productId: string; quantity: number }
    >({
        // @ts-expect-error
        mutationFn: async ({ lineId, quantity }) => {
            if (basket) {
                return apiUpdateBasketItemQuantity(customerNumber, lineId, basket.id, quantity);
            }
            return Promise.reject(new Error("Basket is not available"));
        },
        onSuccess: (_updatedItem, variables) => {
            queryClient.setQueryData<Basket | undefined>(["basket", customerNumber], (oldData) => {
                if (!oldData) return oldData;
                const updatedLines = oldData.lines.map((line) =>
                    line.id === variables.lineId ? { ...line, quantity: variables.quantity } : line
                );
                return { ...oldData, lines: updatedLines };
            });
            setUpdatedQuantity(true);
        },
    });

    const removeBasketItemMutation = useMutation<BasketItem, Error, string>({
        // @ts-expect-error
        mutationFn: (lineId) => {
            return apiRemoveBasketItem(customerNumber, lineId).then((res) => res.data);
        },
        onSuccess: (_data, lineId) => {
            queryClient.setQueryData<Basket | undefined>(["basket", customerNumber], (oldData) => {
                if (!oldData) return oldData;
                const updatedLines = oldData.lines.filter((line) => line.id !== lineId);
                return { ...oldData, lines: updatedLines };
            });
        },
    });

    const updateBasketItemQuantity = async (
        lineId: string,
        productId: string,
        quantity: number
    ) => {
        await updateBasketItemQuantityMutation.mutateAsync({ lineId, productId, quantity });
    };

    const removeBasketItem = async (lineId: string) => {
        await removeBasketItemMutation.mutateAsync(lineId);
    };

    useEffect(() => {
        if (customerNumber) {
            refetchBasket();
        }
    }, [customerNumber, refetchBasket]);

    const totalQuantity = useMemo(() => {
        return basket?.lines?.reduce((acc, item) => acc + item.quantity, 0) || 0;
    }, [basket]);

    return (
        <BasketContext.Provider
            value={{
                isLoading,
                basket: basket || null,
                totalQuantity,
                refetchBasket,
                addToBasket,
                addMultipleToBasket,
                updateBasketItemQuantity,
                removeBasketItem,
                productPrices,
                basketPrices,
            }}
        >
            {children}
        </BasketContext.Provider>
    );
};

export const useBasket = () => {
    const context = useContext(BasketContext);
    if (!context) {
        throw new Error("useBasket must be used within a BasketProvider");
    }
    return context;
};
