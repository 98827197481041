import {
    favoritesApiGet,
    favoritesApiPost,
    favoritesApiPatch,
    favoritesApiDelete,
} from "../helpers/apis/apiFavorites";
import { CategoryProduct } from "@shared/types/CategoryTypes.interface";

export interface FavoriteItem extends CategoryProduct {
    favorites: Record<string, { listId: string; listName: string; isFavorite: boolean }>;
}

export interface FavoriteList {
    id: string;
    name: string;
    items: FavoriteItem[];
}

export interface FetchFavoritesResponse {
    customerNumber: string;
    favoritesLists: FavoriteList[];
}

export const fetchFavorites = async (): Promise<FavoriteList[]> => {
    const url = `/api/favorites`;
    const { data } = await favoritesApiGet<FetchFavoritesResponse>(url);
    return data.favoritesLists;
};

export const addProductToFavorites = async ({
    productId,
    listIds,
}: {
    productId: string;
    listIds: string[];
}): Promise<void> => {
    const url = `/api/favorites/addmultipleproducts?productId=${productId}`;
    await favoritesApiPost<void, string[]>(url, listIds);
};

export const removeProductFromFavorites = async ({
    productId,
    listIds,
}: {
    productId: string;
    listIds: string[];
}): Promise<void> => {
    const url = `/api/favorites/removemultipleproducts?productId=${productId}`;
    await favoritesApiDelete<void, string[]>(url, listIds);
};

export const createFavoritesList = async (name: string): Promise<string> => {
    const url = `/api/favorites/createlist?name=${name}`;
    const { data } = await favoritesApiPost<{ data: string }>(url);
    return data.data;
};

export const renameFavoritesList = async ({
    listId,
    name,
}: {
    listId: string;
    name: string;
}): Promise<void> => {
    const url = `/api/favorites/renamelist?listId=${listId}&name=${name}`;
    await favoritesApiPatch<void>(url, {});
};

export const deleteFavoritesList = async (listId: string): Promise<void> => {
    const url = `/api/favorites/deletelist?listId=${listId}`;
    await favoritesApiDelete<void>(url);
};
