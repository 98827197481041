import React, { useState, KeyboardEvent, useEffect } from "react";
import { useTranslations } from "@shared/context/useTranslations";
import { useSearch } from "@shared/context/SearchContext";
import { cn } from "@shared/helpers/classNames.helper";
import { IconNames } from "@shared/enums/icons.enums";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import Button from "@shared/components/Atoms/Button/Button";
import S from "./Filter.module.scss";

export const ProductList_Filter = () => {
    const { getTranslation } = useTranslations();
    const { stockStatus, setStockStatus, productId, setProductId } = useSearch();
    const [filterProductId, setFilterProductId] = useState(productId?.toString() || "");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterProductId((prev) => {
            if (prev && !e.target.value) {
                setProductId(undefined);
            }
            return e.target.value;
        });
    };

    useEffect(() => {
        // Make sure to reset if product is updated from outside (user navigates to different category)
        setFilterProductId(productId?.toString() || "");
    }, [productId]);

    const handleStockChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const stockStatus = e.target.value === "" ? null : e.target.value === "1" ? true : false;
        setStockStatus(stockStatus);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            triggerSearch();
        }
    };

    const resetSearch = () => {
        setFilterProductId("");
        setStockStatus(null);
        setProductId(undefined);
    };

    const triggerSearch = () => {
        const regex = new RegExp(/^[0-9]+$/);
        const productParam = regex.test(filterProductId) ? filterProductId : undefined;
        setProductId(productParam);
    };

    return (
        <div className={S.filterWrapper}>
            <h4 className={S.headline}>{getTranslation("search.filter.heading")}</h4>
            <div className={S.filterItems}>
                <label className={S.inputLabel} htmlFor="search-filter-input">
                    {getTranslation("search.filter.label-product-id")}
                </label>
                <div className={S.filterSearchBox}>
                    <input
                        type="search"
                        className={S.filterSearchInput}
                        placeholder={getTranslation("search.filter.placeholder")}
                        value={filterProductId}
                        tabIndex={2}
                        id="search-filter-input"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <div className={cn(S.icon, S.searchIcon)}>
                        <button tabIndex={3} onClick={triggerSearch}>
                            <SvgIcon iconName={IconNames.Search} />
                        </button>
                    </div>
                </div>

                <label className={S.inputLabel} htmlFor="stock-select">
                    {getTranslation("search.filter.label-stock-status")}
                </label>
                <div className={S.filterStockBox}>
                    <select
                        name="stock-select"
                        id="stock-select"
                        onChange={handleStockChange}
                        value={stockStatus === null ? "" : stockStatus ? "1" : "0"}
                    >
                        <option value="">{getTranslation("search.filter.stock-option-all")}</option>
                        <option value="1">
                            {getTranslation("search.filter.stock-option-in-stock")}
                        </option>
                        <option value="0">
                            {getTranslation("search.filter.stock-option-out-of-stock")}
                        </option>
                    </select>
                    <div className={cn(S.icon, S.stockIcon)}>
                        <button tabIndex={3} onClick={triggerSearch}>
                            <SvgIcon iconName={IconNames.Chevron} />
                        </button>
                    </div>
                </div>

                <Button size="small" round variant="transparent" onClick={resetSearch}>
                    {getTranslation("search.filter.reset")}
                </Button>
            </div>
        </div>
    );
};
