// src/helpers/loginHelper.ts
import axios, { AxiosError } from "axios";
import apiClient from "./apiClient";
export interface LoginResponse {
    accessToken: string;
    refreshToken: string;
}

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
    return axios.isAxiosError(error);
}

export type LoginResponseType = {
    success: boolean;
    errorKey: string;
};

export const login = async (userName: string, password: string): Promise<LoginResponseType> => {
    const body = { userName, password };
    try {
        const response = await apiClient.post<
            LoginResponse | null,
            { userName: string; password: string }
        >("/api/user/authenticate", body);
        
        return {
            success: response !== null || true,
            errorKey: ""
        }
    } catch (error) {
        // throw error;
        if (isAxiosError<LoginResponseType>(error)) {
            return {
                success: false,
                errorKey: error.response?.data.errorKey || ""
            };
        }

        return {
            success: false,
            errorKey: ""
        };
    }
};
