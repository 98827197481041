// src/context/PageContext.tsx

import {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    PropsWithChildren,
} from "react";
import { useLocation } from "react-router-dom";
import { Content, SiteHeader, SiteNavigation, SiteSettings } from "../types/ContentTypes";
import { useContent } from "../hooks/useContent";

interface PageContextProps {
    page?: Content | null;
    settings: SiteSettings | null;
    isLoading?: boolean;
    error?: Error | null;
    fetchPage: (url: string) => void;
    setPage: (content: Content) => void;
    isSidebarOpen: boolean | null;
    setIsSidebarOpen: (isOpen: boolean) => void;
    statusCode?: number; // Add statusCode to track HTTP status codes
    navigation?: SiteNavigation | null; // Add navigation property
    header?: SiteHeader | null; // Add header property
}

const PageContext = createContext<PageContextProps>({
    page: null,
    settings: null,
    isLoading: false,
    error: null,
    fetchPage: () => {},
    setPage: () => {},
    isSidebarOpen: null,
    setIsSidebarOpen: () => {},
    statusCode: undefined, // Initialize statusCode
    navigation: null,
    header: null,
});

export const PageProvider = (props: PropsWithChildren<{ settings: SiteSettings | null }>) => {
    const [page, setPageState] = useState<Content | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [statusCode, setStatusCode] = useState<number | undefined>(undefined); // Track status code
    const location = useLocation();
    const [settings] = useState<SiteSettings | null>(props.settings);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [navigation] = useState<SiteNavigation | null>({
        // @ts-expect-error
        items: globalThis?.navigation?.structure,
    });

    // {/* @ts-ignore */}
    // const headerObject: SiteHeader = globalThis?.header;
    // headerObject?.profilePages?.push({
    //     id: 'logout',
    //     contentType: 'logout',
    //     name: 'logout',

    // })
    // console.log(headerObject)

    // @ts-expect-error
    const [header] = useState<SiteHeader | null>(globalThis?.header);

    const {
        data,
        isLoading: contentLoading,
        error: contentError,
        refetch,
    } = useContent(location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchPage = useCallback(() => {}, [refetch]);

    const setPage = useCallback((content: Content) => {
        setPageState(content);
    }, []);

    useEffect(() => {
        if (data) {
            // @ts-expect-error
            setPageState(data.data as Content);
            setStatusCode(data.statusCode);
        }
        setIsLoading(contentLoading);
        setError(contentError as Error);
    }, [data, contentLoading, contentError]);

    return (
        <PageContext.Provider
            value={{
                page,
                settings,
                isLoading,
                error,
                fetchPage,
                setPage,
                isSidebarOpen,
                setIsSidebarOpen,
                statusCode,
                navigation,
                header,
            }}
        >
            {props.children}
        </PageContext.Provider>
    );
};

export const usePage = () => useContext(PageContext);
