// src/components/Blocks/DynamicBlock/DynamicBlock.tsx
import React, { Fragment, Suspense } from "react";
import { useBlockDataMapping } from "../../../hooks/useBlockDataMapping";
import S from "./DynamicBlock.module.scss";

interface DynamicBlockProps {
    content: {
        contentType: string;
        properties: any;
    };
    settings?: {
        contentType: string;
        id: string;
        properties: {
            numberOfColumns?: number;
        };
    };
}

const BLOCK_TYPES: { [key: string]: any } = {
    blockHero: React.lazy(() => import("../../Templates/Hero/Hero.component")),
    cards: React.lazy(() => import("../../Templates/Cards/Cards.component")),
    textImage: React.lazy(() => import("../../Templates/TextImage/TextImage.component")),
    footerAddress: React.lazy(
        () => import("../../Templates/Footer/FooterAddress/FooterAddress.component")
    ),
    footerLink: React.lazy(() => import("../../Templates/Footer/FooterLink/FooterLink.component")),
    textArea: React.lazy(() => import("../../Templates/CustomText/CustomText.component")),

    soMe: React.lazy(() => import("../../Templates/SoMe/SoMe.component")),
    // Add other block mappings here
};

export const DynamicBlock: React.FC<DynamicBlockProps> = ({ content, settings }) => {
    // console.log("DynamicBlock content:", content); // Add this line for debugging
    const { contentType } = content;
    const BlockComponent = BLOCK_TYPES[contentType];

    const mappedData = useBlockDataMapping(contentType, content, settings);
    // console.log("Mapped data:", mappedData); // Add this line for debugging

    return (
        <Fragment>
            {BlockComponent ? (
                //TODO: Add translation????
                <Suspense fallback={<div>Loading...</div>}>
                    <div className={S.content}>
                        <BlockComponent {...mappedData} />
                    </div>
                </Suspense>
            ) : null}
        </Fragment>
    );
};
