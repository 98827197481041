import { ProductList_Filter } from "./components/Filter/Filter.component";
import { ProductList_List } from "./components/List/List.component";
import { CategoryPageProps } from "../../../types/CategoryTypes.interface";
import { useTranslations } from "@shared/context/useTranslations";
import { Breadcrumbs } from "@shared/components/Molecules/Breadcrumbs/Breadcrumbs.component";
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";
import { useSearch } from "@shared/context/SearchContext";
import { useEffect } from "react";
import S from "./ProductList.module.scss";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import Pagination from "./components/Pagination/Pagination.component";
import { Spinner } from "@shared/components/Atoms/Spinner/Spinner.component";

const ProductList = ({ category, textTop, textBottom }: CategoryPageProps) => {
    const { getTranslation } = useTranslations();
    const {
        isLoading,
        setCategoryId,
        setProductId,
        setStockStatus,
        setQuery,
        products,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
    } = useSearch();

    const handlePages = (updatePage: number) => {
        setPageNumber(updatePage);
    };

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setPageNumber(1); //reset to page 1 when page size changes
    };

    useEffect(() => {
        if (category) {
            setCategoryId(category?.id);
            setQuery("");
            setProductId(undefined);
            setStockStatus(null);
            setPageNumber(1);
        }
    }, [category, setCategoryId, setPageNumber, setProductId, setQuery, setStockStatus]);

    return (
        <ErrorBoundary>
            <Breadcrumbs breadcrumbs={category?.breadcrumbs || []} />

            <div className={S.productListHeader}>
                <div className={S.header}>
                    <h1>{category?.name}</h1>
                    <div className={S.subInfo}>{textTop}</div>
                </div>
            </div>

            {isLoading ? (
                <div className={S.loadingBar}>
                    <Spinner />
                    <h4>{getTranslation("search.loading-heading")}</h4>
                </div>
            ) : (
                <div className={S.productListWrapper}>
                    <ProductList_Filter />
                    {products ? (
                        <div className={S.productList}>
                            <ProductList_List
                                products={products || []}
                                path={category?.url?.urlPath || ""}
                            />
                            <div className={S.pagination}>
                                <Pagination
                                    pageSize={pageSize}
                                    pageNumber={pageNumber}
                                    totalPages={products.totalPages!}
                                    handlePagination={handlePages}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={products.totalCount!}
                                />
                            </div>
                        </div>
                    ) : (
                        <EmptyState heading={getTranslation("pdp.headings.no-product-available")} />
                    )}
                </div>
            )}

            {textBottom && <div className={S.textBottom}>{textBottom}</div>}
        </ErrorBoundary>
    );
};

export default ProductList;
